import React, { ReactElement, useEffect, useState } from 'react'

import {
  faCheck,
  faExclamationTriangle,
  faEyeSlash,
  faInfoCircle,
  faPlus
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import round from 'lodash/round'
import { useTranslation } from 'react-i18next'
import sanitizeHtml from 'sanitize-html-react'
import styled from 'styled-components'

import { STEP } from '../controller'
import { Button } from '../fragments/button'
import { Batch } from '../lib/batch'
import { IRule, RULE_STATUS } from '../lib/recipe'
import { naturalJoin } from '../utils/functions'

export const ConfirmRule = styled.div``
export const AutoMatchRule = styled(ConfirmRule)``

type ConfirmMapProps = {
  rule: IRule
  batch: Batch
  onChange(): void
}

export const ConfirmMap: React.FC<ConfirmMapProps> = ({
  rule,
  batch,
  onChange
}: ConfirmMapProps) => {
  const { t } = useTranslation()

  const { recipe, view, settings } = batch
  const parser = batch.getParser()
  const { sourceIndex, status, targetKey, optionsMap } = rule

  const defaultName = recipe.readableSourceName(rule)
  const field = recipe.getFieldSettings(sourceIndex)
  const label = (field && field.label) || defaultName
  const description = field && field.description

  const report: ReactElement[] = []
  const duplicates = recipe.getDuplicates(rule)
  const hasDupes = duplicates.length > 0
  const [fillLevel] = parser.getFillLevel(sourceIndex)

  const [invalidPerc, setInvalidPerc] = useState<number>()

  useEffect(() => {
    setInvalidPerc(targetKey ? view.getValidationSummary(rule) : undefined)
  }, [targetKey, optionsMap])

  if (field) {
    report.push(
      <AutoMatchRule data-testid='confirm-map--automatch'>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faCheck}
          className='successColor'
          style={{ marginRight: '6px' }}
        />
        {t('autoMatchedField1')}
        <span className='suggested-fieldname primaryTextColor'>{label}</span>
        {t('autoMatchedField2')}
        {description ? (
          <div
            className='field-description secondaryTextColor'
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(description) }}
          />
        ) : null}
      </AutoMatchRule>
    )
  } else {
    const rec = recipe.getRecommendation(sourceIndex)
    const recField = rec.targetKey && recipe.getField(rec.targetKey)
    report.push(
      <ConfirmRule data-testid='confirm-map--notmatched'>
        <FontAwesomeIcon fixedWidth={true} {...typeClasses.warn} style={{ marginRight: '6px' }} />
        {settings.autoMatch === false
          ? t('notMatched')
          : !recField
          ? t('unableToMatch')
          : t('noMatchSelected', {
              suggestedMatch: recField.label
            })}
      </ConfirmRule>
    )
  }

  report.push(
    <ConfirmRule>
      <FontAwesomeIcon fixedWidth={true} {...typeClasses.info} style={{ marginRight: '6px' }} />
      {t('rowsHaveValue', { rows: `${round(fillLevel * 100, 1)}%` })}
    </ConfirmRule>
  )

  if (typeof invalidPerc === 'number') {
    report.push(
      invalidPerc === 0 ? (
        <ReportItem data-testid='confirm-map--pass' text={t('validationPass')} type='success' />
      ) : (
        <ReportItem
          data-testid='confirm-map--fail'
          text={t('rowsFail', { failed: round(invalidPerc * 100, 1) })}
          type='warn'
        />
      )
    )
  }

  if (status === RULE_STATUS.PENDING) {
    if (hasDupes) {
      const priorMatch = naturalJoin(recipe.readableSourceNames(duplicates), false, [
        t('priorMatchSingular'),
        t('priorMatchPlural')
      ])
      const err = t('alreadyMatched', { priorMatch, label })
      report.push(<ReportItem data-testid='confirm-map--dupes' text={err} type='warn' />)
    }

    return (
      <aside className={`column-matched ${hasDupes ? 'duplicate' : ''}`}>
        <ul>
          {report.map((r, i) => (
            <li key={`rep-${sourceIndex}-${i}`}>{r}</li>
          ))}
        </ul>
        <div className='confirm-box' data-testid='confirm-map--box'>
          {targetKey && (
            <Button
              id={'confirmed-' + sourceIndex}
              classes={[
                hasDupes ? 'gray column-match-confirm-with-dupes' : 'success column-match-confirm'
              ]}
              tabIndex={(sourceIndex + 1) * 3 + 1}
              title={t('buttons.confirmMapping')}
              onClick={() => {
                if (!hasDupes) {
                  recipe.modifyStatus(sourceIndex, RULE_STATUS.ACCEPTED)
                  onChange()
                }
              }}
            />
          )}
          <Button
            id={'ignored-' + sourceIndex}
            classes={['invert column-match-ignore']}
            tabIndex={(sourceIndex + 1) * 3 + 2}
            title={t('buttons.ignore')}
            onClick={() => {
              recipe.modifyStatus(sourceIndex, RULE_STATUS.IGNORED)
              onChange()
            }}
          />
          {!targetKey && batch.settings.allowCustom && (
            <Button
              id={'custom-' + sourceIndex}
              classes={['invert', 'column-match-include']}
              tabIndex={(sourceIndex + 1) * 3 + 3}
              onClick={async () => {
                if (!recipe.hasField(label)) {
                  recipe.addCustomField(label)
                }
                await recipe.modifyRule(rule.sourceIndex, label, (newRule) =>
                  batch.verifyStepChange(STEP.MATCH_FIELD, newRule)
                )
                onChange()
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              {t('buttons.customField')}
            </Button>
          )}
        </div>
      </aside>
    )
  } else {
    return (
      <aside className={status === RULE_STATUS.ACCEPTED ? 'column-confirmed' : 'column-ignored'}>
        <ul>
          {status === RULE_STATUS.ACCEPTED ? (
            <li>
              <FontAwesomeIcon
                fixedWidth={true}
                {...typeClasses.success}
                style={{ marginRight: '6px' }}
              />
              {t('confirmedMapping')}
            </li>
          ) : (
            <li>
              <FontAwesomeIcon fixedWidth={true} icon={faEyeSlash} style={{ marginRight: '6px' }} />
              {t('ignored')}
            </li>
          )}
        </ul>
        {rule.editable !== false ? (
          <Button
            id={'matched-' + sourceIndex}
            classes={['secondary', 'column-match-edit']}
            tabIndex={(sourceIndex + 1) * 3 + 1}
            title={t('buttons.edit')}
            onClick={() => {
              recipe.modifyStatus(sourceIndex, RULE_STATUS.PENDING)
              onChange()
            }}
          />
        ) : (
          <p>{t('errors.fieldCannotBeModified')}</p>
        )}
      </aside>
    )
  }
}
ConfirmMap.displayName = 'ConfirmMap'

const ReportItem: React.FC<{
  type: 'warn' | 'success' | 'info'
  text: string
  'data-testid': string
}> = ({ type, text, 'data-testid': dataTestid }) => (
  <ConfirmRule data-testid={dataTestid}>
    <FontAwesomeIcon fixedWidth={true} {...typeClasses[type]} style={{ marginRight: '6px' }} />
    {text}
  </ConfirmRule>
)
ReportItem.displayName = 'ReportItem'

const typeClasses = {
  info: { icon: faInfoCircle, className: '' },
  warn: { icon: faExclamationTriangle, className: 'warningColor' },
  success: { icon: faCheck, className: 'successColor' }
}
