import React, { useContext, useState } from 'react'

import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { ColBody } from '../components/col.body'
import { BatchContext, IBatchContextSet } from '../context/batch.context'
import { STAGE } from '../controller'
import { Button, SecondaryButton } from '../fragments/button'
import { Footer, TopBar } from '../fragments/control.bar'
import { useErrorModal } from '../hooks/useErrorModal'
import { IRule } from '../lib/recipe'
import { StyledMatchWrapper } from '../theme'

const StyledTopBar = styled.div<{ hasPadding?: boolean }>`
  ${({ hasPadding }) =>
    hasPadding &&
    css`
      .top {
        padding-bottom: 24px;
      }
    `}

  p {
    margin: 0 40px 16px;
  }
`

type MatchViewProps = {
  onComplete: () => void
  onCancel: () => void
}

export const MatchView: React.FC<MatchViewProps> = ({ onCancel, onComplete }) => {
  const { batch } = useContext(BatchContext) as IBatchContextSet
  const [rules, setRules] = useState<IRule[]>(batch.recipe.getRealRules())
  const [showErrorModal] = useErrorModal()

  const { t } = useTranslation()

  const recipe = batch.recipe

  const handleReview = async () => {
    const duplicateColumns = recipe.getDuplicates()
    if (duplicateColumns.length) {
      showErrorModal(
        t('errors.duplicateColumns', {
          fields: recipe.readableSourceNames(duplicateColumns).join(', ')
        })
      )
      return
    }

    const requiredKeysUnmatched = recipe.getMissingRequiredFields()
    if (requiredKeysUnmatched.length) {
      showErrorModal(
        t('errors.missingRequiredFields', {
          fields: requiredKeysUnmatched.map((x) => x.label || x.key).join(', ')
        })
      )
      return
    }

    await recipe.markReviewed()
    onComplete()
  }

  return (
    <StyledMatchWrapper className='column-match-stage'>
      <StyledTopBar hasPadding={batch.parser?.loaded === false}>
        <TopBar stage={STAGE.MATCH} />
        {batch.parser?.loaded === false && <p>{t('validationWarning')}</p>}
      </StyledTopBar>
      <div className='column-edit-table'>
        {rules.map((rule) => (
          <ColBody
            key={`col-${rule.sourceIndex}`}
            rule={rule}
            batch={batch}
            onChange={() => {
              setRules(recipe.getRealRules())
            }}
          />
        ))}
      </div>
      <Footer>
        <SecondaryButton title={t('buttons.back')} onClick={() => onCancel()} />
        <Button title={t('buttons.review')} onClick={handleReview} />
      </Footer>
    </StyledMatchWrapper>
  )
}

MatchView.displayName = 'MatchView'
