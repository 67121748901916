import React, { useRef, useState } from 'react'

import { faCheck, faExclamationTriangle, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { Button } from '../fragments/button'
import { useSelectStyles } from '../hooks/useSelectStyles'
import { Batch } from '../lib/batch'
import { CountTuple } from '../lib/file.parser'
import { IRule } from '../lib/recipe'
import { ScalarDictionary } from '../types/general.interface'
import { IFieldOptionDictionary, IFieldSelect } from '../types/settings.interface'
import { OverflowData } from './OverflowData'

const ADD_CUSTOM_OPTION_IDENTIFIER = '__internal_add_custom_option__'

export interface IMatchOptionsRow {
  batch: Batch
  sourceValue: CountTuple
  rule: IRule
  field: IFieldSelect
  onChange(): void
}

export const MatchOptionsRow: React.FC<IMatchOptionsRow> = ({
  batch,
  sourceValue,
  onChange,
  field: { allowCustomOptions = false, options },
  rule: { sourceIndex, optionsMap = {} }
}: IMatchOptionsRow) => {
  // tslint:disable-next-line: no-any
  const selectRef = useRef<any>(null)
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [v] = sourceValue

  const displayOptions = [...options]
    .concat(
      allowCustomOptions
        ? [
            {
              label: t('buttons.customOption'),
              value: ADD_CUSTOM_OPTION_IDENTIFIER
            }
          ]
        : []
    )
    .map((o) => (o.label ? o : { ...o, label: o.value }))

  const { cellRef, styles } = useSelectStyles({
    themePath: 'columnMatch.table.th',
    customFieldIdentifier: allowCustomOptions ? ADD_CUSTOM_OPTION_IDENTIFIER : undefined
  })

  return (
    <tr data-testid='match-options-row'>
      <td style={{ display: 'flex' }}>
        {v in (optionsMap as ScalarDictionary) ? (
          <FontAwesomeIcon
            icon={faCheck}
            className='successColor'
            fixedWidth={true}
            style={{ alignSelf: 'center', display: 'flex', marginRight: '6px' }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className='warningColor'
            fixedWidth={true}
            style={{ alignSelf: 'center', display: 'flex', marginRight: '6px' }}
          />
        )}
        <OverflowData dataFor={v} dataTip={v} isSpan>
          <span>{v}</span>
        </OverflowData>
      </td>
      <td ref={cellRef} style={{ zIndex: isOpen ? 50 : 0 }}>
        <Select
          options={displayOptions}
          isClearable={true}
          onMenuOpen={() => setIsOpen(true)}
          onMenuClose={() => setIsOpen(false)}
          styles={styles}
          ref={selectRef}
          placeholder={t('chooseOne')}
          formatOptionLabel={({ value, label }) => {
            if (value === ADD_CUSTOM_OPTION_IDENTIFIER && allowCustomOptions) {
              return (
                <Button
                  classes={['invert', 'column-match-include-option-dropdown']}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()

                    batch.recipe.addCustomOptionsMatch(sourceIndex, v)

                    if (selectRef.current) {
                      selectRef.current.onMenuClose()
                    }

                    onChange()
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  {label}
                </Button>
              )
            }

            return label
          }}
          onChange={(selectValue) => {
            /* istanbul ignore next */
            if (selectValue && 'length' in selectValue) {
              return
            }
            batch.recipe.changeOptionsMatch(
              sourceIndex,
              v,
              selectValue !== null && selectValue !== undefined
                ? (selectValue as IFieldOptionDictionary).value
                : undefined
            )
            onChange()
          }}
          value={displayOptions.find((o) => o.value === optionsMap?.[v])}
        />
      </td>
    </tr>
  )
}

MatchOptionsRow.displayName = 'MatchOptionsRow'
