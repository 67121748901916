import React, { Suspense, useRef } from 'react'

import { useTranslation } from 'react-i18next'

import { DataTable } from '../components/data.table'
import { STAGE } from '../controller'
import { Button, SecondaryButton } from '../fragments/button'
import { Footer, TopBar } from '../fragments/control.bar'
import { FilterBar } from '../fragments/filter.bar'
import { Wrapper } from '../fragments/layout'
import { Spinner } from '../fragments/spinner'
import { Batch } from '../lib/batch'
import { VIEW_FILTER } from '../lib/view'
import { ManualInput } from '../views/input.view'

const fallback = () => false

function ReviewViewDemo({ batch }: { batch: Batch }) {
  const { t } = useTranslation()
  const tableViewportRef = useRef<HTMLDivElement>(null)

  return (
    <Wrapper>
      <TopBar stage={STAGE.AUDIT} />
      <FilterBar encoding='UTF-8' filters={[VIEW_FILTER.INVALID]} toggleFilter={fallback} />
      <div ref={tableViewportRef} />
      <ManualInput className='manual-input-table'>
        <Suspense fallback={<Spinner />}>
          <DataTable view={batch.view} viewPort={tableViewportRef} forceHeight={200} />
        </Suspense>
      </ManualInput>
      <Footer>
        <SecondaryButton title={t('buttons.back')} onClick={fallback} />
        <Button title={t('buttons.continue')} onClick={fallback} />
      </Footer>
    </Wrapper>
  )
}

export default ReviewViewDemo
