import { getAsyncGraphClient } from '../lib/graph.client'
import { PUBLIC_SET_LEGACY_SETTINGS } from '../queries/PUBLIC_SET_LEGACY_SETTINGS'
import {
  PublicSetLegacySettings,
  PublicSetLegacySettingsVariables
} from '../queries/types/PublicSetLegacySettings'

export const publicSetLegacySettings = async (
  variables: PublicSetLegacySettingsVariables
): Promise<{ id?: string; success: boolean }> => {
  const graphClient = await getAsyncGraphClient({
    operation: 'PUBLIC_SET_LEGACY_SETTINGS',
    variables
  })
  try {
    const mutationResponse = await graphClient.mutate<
      PublicSetLegacySettings,
      PublicSetLegacySettingsVariables
    >({
      mutation: PUBLIC_SET_LEGACY_SETTINGS,
      variables
    })
    if (mutationResponse && mutationResponse.data) {
      return mutationResponse.data.publicSetLegacySettings
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }
  return { id: undefined, success: false }
}
