import Fuse from 'fuse.js'

import { MAX_SELECT_OPTIONS } from '../config'
import { IColumnMeta, ScalarDictionary } from '../types/general.interface'
import { IField, IFieldSelect, ISettings } from '../types/settings.interface'
import { convertToLetters } from '../utils/functions'
import { CountTuple, FileParser, IndexTuple, TooManyUniquesError } from './file.parser'

interface IFlattenedFields {
  key: string
  value: string
}

export class MatchFinder {
  constructor(private parser: FileParser, private settings: ISettings) {}

  public async getRecommendedMatches(): Promise<IColumnMeta> {
    const headers = await this.parser.getHeaders()
    if (headers) {
      if (this.settings.autoMatch === false) {
        return headers.map((_header, columnIndex) => ({
          columnIndex,
          newName: undefined
        }))
      }

      const exactMatches = this.exactMatch(headers)
      const mappedHeaders = await this.fuzzyMatch(headers, exactMatches)
      const allMatches = [...exactMatches, ...mappedHeaders]

      return allMatches
        .map((v, i) => ({
          ...v,
          newName:
            allMatches.findIndex((m) => m.newName === v.newName) === i ? v.newName : undefined
        }))
        .sort((a, b) => (a.columnIndex || 0) - (b.columnIndex || 0))
    } else {
      return this.noHeaderMatch()
    }
  }

  /**
   * Get a list of potential option matches for a select type column
   */
  public getDefaultMatches(
    colIndex: number,
    field: IFieldSelect,
    overrides: ScalarDictionary = {}
  ): ScalarDictionary {
    const { options, matchStrategy = 'fuzzy' } = field
    let data: CountTuple[] = []
    try {
      data = this.parser.getUniqueColumnValues(colIndex, MAX_SELECT_OPTIONS)
    } catch (e) {
      if (e instanceof TooManyUniquesError) {
        data = []
      } else {
        throw e
      }
    }

    const searchOptions = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 255,
      minMatchCharLength: 1,
      keys: [
        {
          name: 'label',
          weight: 0.4
        },
        {
          name: 'value',
          weight: 0.6
        },
        {
          name: 'alternates',
          weight: 0.4
        }
      ]
    }
    const fuse = new Fuse(options, searchOptions)

    return data.reduce((acc: ScalarDictionary, [realValue]): ScalarDictionary => {
      if (overrides[realValue] !== undefined) {
        acc[realValue] = overrides[realValue]
        return acc
      }
      const v = (realValue || '').trim().toLowerCase()
      const guess = options.find((opt) => {
        if (
          (typeof opt.value === 'string' && opt.value.toLowerCase() === v) ||
          opt.alternates?.map((i) => i.toLowerCase()).includes(v) ||
          opt.value === v
        ) {
          return true
        }
        return opt.label.trim().toLowerCase() === v
      })
      if (guess) {
        acc[realValue] = guess.value
      } else if (realValue && typeof realValue === 'string' && matchStrategy === 'fuzzy') {
        const searchResults = fuse.search(realValue.replace(/\W+/g, ' '))
        if (searchResults.length) {
          acc[realValue] = searchResults[0].item.value
        }
      }
      return acc
    }, {})
  }

  private async noHeaderMatch(): Promise<IColumnMeta> {
    const [columns] = await this.parser.getFirstRow()

    return columns.map((_val, i) => {
      const suggestion = convertToLetters(i + 1)
      return {
        columnIndex: i,
        oldHeaderName: suggestion,
        newName: suggestion,
        suggestedName: suggestion,
        isGenerated: true,
        duplicate: false,
        validators: [],
        description: undefined,
        type: undefined,
        options: undefined,
        matchState: 'unmatched'
      }
    })
  }

  private normalizeValue(value: string): string {
    return value.replace(/\W+/g, ' ').trim().toLowerCase()
  }

  private exactMatch(headers: IndexTuple[]): IColumnMeta {
    // Flatten out labels and keys to match against
    const fieldsToMatchAgainst = this.settings.fields.reduce((acc: IFlattenedFields[], field) => {
      const alternates =
        field.alternates?.map((alt) => ({
          value: this.normalizeValue(alt),
          key: field.key
        })) || []
      return [
        ...acc,
        { value: this.normalizeValue(field.key), key: field.key },
        { value: this.normalizeValue(field.label), key: field.key },
        ...alternates
      ]
    }, [])

    const exactMatches: IColumnMeta = []
    headers.forEach(([header, i]) => {
      const cleanedHeader = this.normalizeValue(header)
      const match = fieldsToMatchAgainst.find((field) => {
        return cleanedHeader === field.value
      })
      if (match) {
        exactMatches.push({
          columnIndex: i,
          newName: match.key
        })
      }
    })
    return exactMatches
  }

  private async fuzzyMatch(
    headers: IndexTuple[],
    mappedHeaders: IColumnMeta
  ): Promise<IColumnMeta> {
    // Filter out fields that we have already matched
    const unmatchedFields: IField[] = []
    const unmatchedHeaders: string[] = []

    headers.forEach(([header, i]) => {
      const matchedField = mappedHeaders.find((mappedHeader) => mappedHeader.columnIndex === i)
      if (!matchedField) {
        unmatchedHeaders.push(header)
      }
    })

    this.settings.fields.forEach((field) => {
      const matchedField = mappedHeaders.find((header) => header.newName === field.key)
      if (!matchedField) {
        unmatchedFields.push(field)
      }
    })

    const matched: string[] = []
    const searchOptions = {
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      ignoreFieldNorm: true,
      ignoreLocation: true,
      keys: [
        {
          name: 'label',
          weight: 0.4
        },
        {
          name: 'key',
          weight: 0.2
        },
        {
          name: 'alternates',
          weight: 0.4
        }
      ]
    }
    const fuzzyMappedHeaders: IColumnMeta = []
    headers.forEach(([header, i]) => {
      if (unmatchedHeaders.includes(header)) {
        // reinitializing here so we don't research against what we've already matched
        const fuse = new Fuse(unmatchedFields, searchOptions)
        const searchResults = fuse.search(header.replace(/\W+/g, ' '))
        let suggestion: string | undefined

        if (searchResults.length && !matched.includes(searchResults[0].item.key)) {
          suggestion = searchResults[0].item.key
          matched.push(suggestion)
          const matchedFieldIndex = unmatchedFields.findIndex((field) => field.key === suggestion)
          const matchedHeaderIndex = unmatchedHeaders.findIndex(
            (unmatchedHeader) => unmatchedHeader === header
          )
          // remove fields so we don't search against it again
          unmatchedFields.splice(matchedFieldIndex, 1)
          unmatchedHeaders.splice(matchedHeaderIndex, 1)
        }
        fuzzyMappedHeaders.push({
          columnIndex: i,
          newName: suggestion
        })
      }
      return
    })
    return fuzzyMappedHeaders
  }
}
