import { gql } from 'apollo-boost'

export const PUBLIC_SET_LEGACY_SETTINGS = gql`
  mutation PublicSetLegacySettings($licenseKey: String!, $settings: PublicLegacySettingsDto!) {
    publicSetLegacySettings(licenseKey: $licenseKey, settings: $settings) {
      id
      success
    }
  }
`
