import React from 'react'

import { faCheckCircle, faExclamationTriangle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { defaultTheme } from '../styles'

export const StyledStep = styled.div`
  margin: 24px auto;
  text-align: left;
  white-space: pre-wrap;
`

const StyledIconWrapper = styled.span`
  display: inline-block;
  width: 24px;
  margin-right: 8px;
  text-align: center;
`

const CircleIcon = styled.span`
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 100%;
  background: ${defaultTheme.secondaryTextColor};
  vertical-align: -1px;
`

const ErrorIcon = () => (
  <StyledIconWrapper>
    <FontAwesomeIcon icon={faExclamationTriangle} className='warningColor' />
  </StyledIconWrapper>
)

export const LoadingIcon = () => (
  <StyledIconWrapper>
    <FontAwesomeIcon icon={faSpinner} spin />
  </StyledIconWrapper>
)

const PendingIcon = () => (
  <StyledIconWrapper>
    <CircleIcon />
  </StyledIconWrapper>
)

const SuccessIcon = () => (
  <StyledIconWrapper>
    <FontAwesomeIcon icon={faCheckCircle} className='successColor' />
  </StyledIconWrapper>
)

export enum EStepStatus {
  PENDING,
  ACTIVE,
  SUCCESS,
  ERROR
}

interface IStep {
  status: EStepStatus
  text: {
    default: string
    active?: string
    success?: string
    error?: string
  }
}

export const Step = ({ status, text }: IStep) => (
  <StyledStep data-testid='datasource-step'>
    <p>
      {status === EStepStatus.PENDING ? (
        <>
          <PendingIcon />
          {text.default}
        </>
      ) : null}
      {status === EStepStatus.ACTIVE ? (
        <>
          <LoadingIcon />
          {text.active || text.default}
        </>
      ) : null}
      {status === EStepStatus.SUCCESS ? (
        <>
          <SuccessIcon />
          {text.success || text.default}
        </>
      ) : null}
      {status === EStepStatus.ERROR ? (
        <>
          <ErrorIcon />
          {text.error || text.default}
        </>
      ) : null}
    </p>
  </StyledStep>
)
