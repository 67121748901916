import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { VIEW_FILTER } from '../lib/view'
import { ControlBar } from './control.bar'

const EncodingIndicator = styled.div`
  display: flex;
  justify-content: flex-start !important;
  > span {
    font-weight: 600;
    margin-right: 6px;
  }
`

const FilterLabel = styled.span`
  &.primaryTextColor {
    ${({ theme }) =>
      theme?.header?.root?.color &&
      css`
        color: ${theme.header.root.color};
      `}
  }
`

export const FilterBar: FC<{
  encoding?: string
  filters: VIEW_FILTER[]
  toggleFilter: (filter: VIEW_FILTER) => void
}> = ({ encoding, filters, toggleFilter }) => {
  const { t } = useTranslation()

  return (
    <ControlBar className='top short flex-between'>
      <div className='flex-start'>
        <label
          htmlFor='toggle-problems'
          data-line={filters.includes(VIEW_FILTER.INVALID)}
          className='check-toggle'
        />
        <FilterLabel className='primaryTextColor'>{t('onlyShow')}</FilterLabel>
        <input
          id='toggle-problems'
          type='checkbox'
          name='toggle-problems'
          value='toggleProblems'
          checked={filters.includes(VIEW_FILTER.INVALID)}
          onChange={() => toggleFilter(VIEW_FILTER.INVALID)}
        />
        <label
          htmlFor='toggle-edits'
          data-line={filters.includes(VIEW_FILTER.MODIFIED)}
          className='check-toggle'
        />
        <FilterLabel className='primaryTextColor'>{t('modifications')}</FilterLabel>
        <input
          id='toggle-edits'
          type='checkbox'
          name='toggle-edits'
          value='toggleEdits'
          checked={filters.includes(VIEW_FILTER.MODIFIED)}
          onChange={() => toggleFilter(VIEW_FILTER.MODIFIED)}
        />
      </div>

      {encoding && (
        <EncodingIndicator>
          <span>{t('encoding')}: </span>
          {encoding}
        </EncodingIndicator>
      )}
    </ControlBar>
  )
}
