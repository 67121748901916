import React, { FC, useContext } from 'react'

import { debug } from 'loglevel'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { SettingsContext } from '../context/settings.context'
import { RawHTML } from '../fragments/html.raw'
import { useDialog } from '../fragments/modals'
import { FileParser } from '../lib/file.parser'
import { acceptedFileTypes } from '../utils/misc'
import { FileUploader } from './file.uploader'

const DropzoneParagraph = styled.div``
const DropzoneContent = styled.div``
const DropzoneRoot = styled.div`
  padding: 0 40px 40px;

  ${({ theme }) => theme?.dropzone?.root && css(theme.dropzone.root)}

  ${DropzoneContent} {
    margin: 0;

    ${({ theme }) => theme?.dropzone?.content && css(theme.dropzone.content)}
  }

  ${DropzoneParagraph} {
    ${({ theme }) => theme?.dropzone?.fileTypes && css(theme.dropzone.fileTypes)}
  }
`

export const Dropzone: FC<{
  isManaged: boolean
  fileDragging: boolean
  onFile: (parser: FileParser) => void
  onNonParseableFile: (file: File) => void
}> = ({ fileDragging, isManaged, onFile, onNonParseableFile }) => {
  const settings = useContext(SettingsContext)
  const { t } = useTranslation()
  const [showDialog] = useDialog()
  const accept = acceptedFileTypes(isManaged)
  const prettyAccept = accept.replace(/,/g, ', ')

  function handleFiles(files: File[]) {
    const file = files[0]
    if (window.FileReader) {
      if (file.name.toLowerCase().match(/\.(csv|tsv|txt)$/)) {
        const fileParser = new FileParser(file, settings)
        onFile(fileParser)
      } else {
        onNonParseableFile(file)
      }
    } else {
      showDialog('error', { message: t('errors.fileReader') })
    }
  }

  const onDrop: DropzoneOptions['onDrop'] = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      debug('Files received', acceptedFiles)
      handleFiles(acceptedFiles)
    } else {
      showDialog('error', {
        message: t('dropzone.incorrectFileType', { fileTypes: prettyAccept })
      })
    }
  }

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    multiple: false,
    accept
  })

  return (
    <>
      <div
        data-testid='dropzone--zone'
        {...getRootProps({
          className: fileDragging
            ? 'dropzone receive-file ' + (isDragActive ? 'is-dragging' : '')
            : 'dropzone'
        })}
      >
        {isDragActive && <div className={'drop-label'}>Drop anywhere</div>}
        <input {...getInputProps()} />
      </div>
      <DropzoneRoot>
        <DropzoneContent data-testid='dropzone--content' className='notice secondaryTextColor'>
          <FileUploader accept={accept.replace(/,/g, ', ')} onClick={() => open()} />
          <DropzoneParagraph className='notice-right paragraph secondaryTextColor'>
            <RawHTML
              text={t('fileTypes', { fileTypes: prettyAccept })}
              allowedTags={['a', 'br', 'div', 'img', 'p']}
            />
          </DropzoneParagraph>
        </DropzoneContent>
      </DropzoneRoot>
    </>
  )
}

export const DropzoneDemo = () => {
  const { t } = useTranslation()
  const accept = '.csv,.tsv,.xls,.xlsx,.xml'

  return (
    <DropzoneRoot>
      <DropzoneContent data-testid='dropzone--content' className='notice secondaryTextColor'>
        <FileUploader accept={accept.replace(/,/g, ', ')} onClick={() => false} />
        <DropzoneParagraph className='notice-right paragraph secondaryTextColor'>
          {t('fileTypes', { fileTypes: accept })}
        </DropzoneParagraph>
      </DropzoneContent>
    </DropzoneRoot>
  )
}
