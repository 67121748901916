import React, { FC, useContext } from 'react'

import pluralize from 'pluralize'
import { math } from 'polished'
import { useTranslation } from 'react-i18next'
import { useModal } from 'react-modal-hook'
import styled, { css } from 'styled-components'

import { EnvironmentContext } from '../context/environment.context'
import { SettingsContext } from '../context/settings.context'
import { STAGE } from '../controller'
import { defaultTheme } from '../styles'
import { emit } from '../utils/event.manager'
import { formatNumber } from '../utils/functions'
import { CloseButton } from './button'
import { DevBanner } from './dev.banner'
import { FooterBrand } from './footer.brand'
import { ConfirmModal } from './modals'
import { ProgressHeader } from './progress.header'

const Header = styled.h1`
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  margin: -4px 40px 0 0;
`

export const ControlBar = styled.div`
  padding: ${defaultTheme.spacing};
  display: flex;
  justify-content: space-between;
  position: relative;
  input[type='checkbox'] {
    position: absolute;
    transform: translate(-9999px, -9999px);
    visibility: hidden;
  }
  &.top:not(:first-of-type) {
    margin-top: -29px;
  }
  &.short {
    padding-bottom: ${math(`${defaultTheme.spacing} / 2`)};
  }
  &.top {
    ${({ theme }) => theme?.header?.root && css(theme.header.root)}

    ${Header} {
      ${({ theme }) => theme?.header?.title && css(theme.header.title)}
    }
  }
`

export const BottomWrapper = styled(ControlBar)`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: ${math(`${defaultTheme.spacing} * 0.8`)} ${defaultTheme.spacing};
`

const FooterWrapper = styled.div.attrs({ 'data-testid': 'footer' })`
  position: relative;

  ${BottomWrapper} {
    ${({ theme }) => theme?.footer?.root && css(theme.footer.root)}
  }
`

export const SecondaryHeader = styled.h2`
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin: ${defaultTheme.spacing} ${defaultTheme.spacing} ${math(`${defaultTheme.spacing} / 2`)}
    ${defaultTheme.spacing};
`

export const TopBar: FC<{ title?: string; count?: number; stage: STAGE }> = ({
  title,
  stage,
  count
}) => {
  const settings = useContext(SettingsContext)
  const { features } = useContext(EnvironmentContext)
  const { t } = useTranslation()

  const [confirmClose, hideCloseModal] = useModal(
    () => (
      <ConfirmModal
        msg={t('confirmClose')}
        onConfirm={() => {
          emit('do/close')
          hideCloseModal()
        }}
        onDismiss={hideCloseModal}
      />
    ),
    []
  )

  return (
    <>
      {(features.developmentMode || settings.devMode) && <DevBanner />}
      <CloseButton
        onClick={() => {
          if (stage !== STAGE.INITIAL && settings.confirmClose === true) {
            confirmClose()
            return
          }

          emit('do/close')
        }}
      />
      <ControlBar className='top'>
        <Header>
          {title ||
            settings.title ||
            (count
              ? t('header', {
                  number: formatNumber(count, { fractionDigits: 0 }),
                  thing: pluralize(settings.type, count)
                })
              : t('header2', {
                  thing: pluralize.plural(settings.type)
                }))}
        </Header>
        {[STAGE.MATCH, STAGE.AUDIT].includes(stage) && !settings.hideProgressBar && (
          <ProgressHeader stage={stage} />
        )}
      </ControlBar>
    </>
  )
}

export const Footer: FC = (props) => {
  return (
    <FooterWrapper>
      <BottomWrapper data-ref='footer'>{props.children}</BottomWrapper>
      <FooterBrand />
    </FooterWrapper>
  )
}
