import React, {
  FunctionComponent,
  Suspense,
  useCallback,
  useContext,
  useRef,
  useState
} from 'react'

import difference from 'lodash/difference'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Dropzone } from '../components/dropzone'
import { Tooltip } from '../components/Tooltip'
import { MIN_ROWS } from '../config'
import { SettingsContext } from '../context/settings.context'
import { STAGE } from '../controller'
import { Button, SecondaryButton } from '../fragments/button'
import { Footer, SecondaryHeader, TopBar } from '../fragments/control.bar'
import { FilterBar } from '../fragments/filter.bar'
import { Wrapper } from '../fragments/layout'
import { DefaultModal } from '../fragments/modals'
import { Spinner } from '../fragments/spinner'
import { SubmitOptions } from '../fragments/submit.dialogs'
import { Batch } from '../lib/batch'
import { FileParser } from '../lib/file.parser'
import { VIEW_FILTER } from '../lib/view'
import { useListener } from '../utils/event.manager'

type InputViewProps = {
  stage: STAGE
  batch: Batch
  encoding?: string
  onFile: (parser: FileParser) => void
  onNonParseableFile: (file: File) => void
  onComplete: () => void | Promise<void>
  onCancel: () => void
}

const DataTable = React.lazy(() => import('../components/data.table'))

export const ManualInput = styled.div`
  ${({ theme }) => theme?.manualInput?.root && css(theme.manualInput.root)}

  ${SecondaryHeader} {
    margin-top: 0;
    padding-top: 40px;

    ${({ theme }) => theme?.manualInput?.title && css(theme.manualInput.title)}
  }
`

export const InputView: FunctionComponent<InputViewProps> = ({
  stage,
  batch,
  encoding,
  onComplete,
  onCancel,
  onFile,
  onNonParseableFile
}) => {
  const [isEmpty, setIsEmpty] = useState<boolean>(batch.isEmpty())
  const [rowsValidated, setRowsValidated] = useState(true)
  const [fileDragging, setFileDragging] = useState(false)
  const settings = useContext(SettingsContext)
  const { t } = useTranslation()
  const tableViewportRef = useRef<HTMLDivElement>(null)
  const [filters, setFilters] = useState<VIEW_FILTER[]>([])
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [actionDisabled, setActionDisabled] = useState(false)
  const toggleFilter = useCallback(
    (filter) => {
      if (filters.includes(filter)) {
        setFilters(difference(filters, [filter]))
      } else {
        setFilters([...filters, filter])
      }
    },
    [filters]
  )
  useListener('do/dialog:display', () => {
    setModalOpen(false)
  })

  const rowCount = batch?.view.getLength()

  if (batch.parser && !batch.parser.isReady()) {
    return <Spinner />
  }

  return (
    <Wrapper
      onDragEnter={(e) => {
        if (e.dataTransfer.items?.length > 0) {
          setFileDragging(true)
        }
      }}
      onDragLeave={(e) => {
        // tslint:disable-next-line:no-any
        if (!e.currentTarget.contains(e.relatedTarget as any)) {
          setFileDragging(false)
        }
      }}
      onDrop={(e) => {
        // tslint:disable-next-line:no-any
        if (!e.currentTarget.contains(e.relatedTarget as any)) {
          setFileDragging(false)
        }
      }}
      style={{ overflow: 'hidden' }}
    >
      <TopBar count={rowCount} stage={stage} />
      {!isEmpty && (
        <FilterBar
          encoding={encoding}
          filters={filters}
          toggleFilter={(filter) => toggleFilter(filter)}
        />
      )}
      {isEmpty && !settings.forceManualInput && (
        <Dropzone
          isManaged={batch.settings.managed || false}
          fileDragging={fileDragging}
          onFile={onFile}
          onNonParseableFile={onNonParseableFile}
        />
      )}
      <div ref={tableViewportRef} />
      {(!settings.disableManualInput || (settings.disableManualInput && stage === STAGE.AUDIT)) && (
        <ManualInput className='manual-input-table'>
          <Suspense fallback={<Spinner />}>
            {isEmpty && !settings.forceManualInput && (
              <SecondaryHeader>{t('manual')}</SecondaryHeader>
            )}
            {modalOpen && rowCount >= 10000 ? (
              <div style={{ height: 23 * MIN_ROWS + 4 + 25 }}>
                <Spinner />
              </div>
            ) : (
              <DataTable
                isManualUpload={batch.isManualUpload()}
                view={batch.view}
                filters={filters}
                viewPort={tableViewportRef}
                uploaded={!isEmpty}
                onValidate={setRowsValidated}
                onFirstInput={
                  stage === STAGE.INITIAL
                    ? async () => {
                        setIsEmpty(false)
                        await batch.init()
                      }
                    : undefined
                }
              />
            )}
          </Suspense>
        </ManualInput>
      )}

      <Footer>
        {!isEmpty && (
          <>
            {stage === STAGE.INITIAL ? (
              <SecondaryButton
                title={t('buttons.clear')}
                onClick={() => {
                  onCancel()
                }}
              />
            ) : batch.parser ? (
              <SecondaryButton
                title={t('buttons.back')}
                onClick={() => {
                  onCancel()
                }}
              />
            ) : (
              <span />
            )}
            <span data-for='tooltip--disabled-submit' data-tip='tooltip--disabled-submit'>
              <Button
                disabled={!rowsValidated}
                title={t('buttons.continue')}
                onClick={() => {
                  setModalOpen(true)
                }}
              />
            </span>
            <Tooltip
              disabled={rowsValidated}
              id='tooltip--disabled-submit'
              offset={{ top: 0, left: 0 }}
              content={
                <div>
                  We're still validating your records! <br />
                  You can submit once that's done.
                </div>
              }
            />
          </>
        )}
      </Footer>
      <DefaultModal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        className='flatfile-modal confirm borderColor'
        overlayClassName='flatfile-modal-overlay'
        contentLabel='Confirm Close'
      >
        {
          <SubmitOptions
            errorCount={batch.view.validator.countErrorRows()}
            rowCount={rowCount}
            allowInvalidSubmit={settings.allowInvalidSubmit}
            actionDisabled={actionDisabled}
            onAction={async (choice) => {
              if (['ignore', 'include'].includes(choice)) {
                setActionDisabled(true)
                await onComplete()
                setActionDisabled(false)
              } else {
                setModalOpen(false)
              }
            }}
          />
        }
      </DefaultModal>
    </Wrapper>
  )
}
InputView.displayName = 'InputView'
