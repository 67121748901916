import { gql } from 'apollo-boost'

export const PUBLIC_UPLOAD_DATA_SOURCE = gql`
  mutation PublicUploadDataSource(
    $licenseKey: String!
    $uploadId: UUID!
    $index: Int!
    $dateFormat: String
    $parseStyles: Boolean
  ) {
    publicUploadDataSource(
      licenseKey: $licenseKey
      uploadId: $uploadId
      index: $index
      dateFormat: $dateFormat
      parseStyles: $parseStyles
    ) {
      uploadId
      label
      index
      rowCount
      columnCount
      hasError
      errorMsg
      errorType
      dataset
      flatDataset
      styles
    }
  }
`
