import React, { useLayoutEffect, useRef, useState } from 'react'

import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { debug } from 'loglevel'
import { useTranslation } from 'react-i18next'
import { OptionTypeBase } from 'react-select'
import Creatable from 'react-select/creatable'
import tippy from 'tippy.js'

import { STEP } from '../controller'
import { Button } from '../fragments/button'
import { useSelectStyles } from '../hooks/useSelectStyles'
import { Batch } from '../lib/batch'
import { IRule } from '../lib/recipe'
import { convertToLetters } from '../utils/functions'

const SourceColumnTableHeader = ({
  columnName,
  ...props
}: {
  columnName?: string
  // tslint:disable-next-line:no-any
  [key: string]: any
}) => {
  const cellRef = useRef<HTMLTableCellElement>(null)
  useLayoutEffect(() => {
    if (cellRef.current && columnName) {
      if ('_tippy' in cellRef.current) {
        const cell = cellRef.current as { _tippy: { destroy(): void } }
        cell._tippy.destroy()
      }
      tippy(cellRef.current, {
        content: `<span class="tippy-content-override">${columnName.split('.').join(' ')}</span>`,
        placement: `top`,
        maxWidth: 800,
        arrow: true,
        distance: -3
      })
    }
  })

  return <th ref={cellRef} {...props} />
}

const ADD_CUSTOM_FIELD_IDENTIFIER = '__internal_add_custom_field__'

export const TopRow: React.FC<{
  batch: Batch
  rule: IRule
  onChange(): void
}> = ({ rule, batch, onChange }) => {
  // tslint:disable-next-line: no-any
  const creatableRef = useRef<any>(null)
  const { recipe } = batch
  const { allowCustom } = batch.settings
  const columnName = recipe.readableSourceName(rule, false)
  let options = recipe.getFieldsAsOptions()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const { cellRef, styles } = useSelectStyles({
    themePath: ['columnMatch.table.th', 'columnMatch.select'],
    customFieldIdentifier: allowCustom ? ADD_CUSTOM_FIELD_IDENTIFIER : undefined
  })

  if (allowCustom) {
    options = options.concat([
      {
        label: t('buttons.customField'),
        value: ADD_CUSTOM_FIELD_IDENTIFIER
      }
    ])
  }

  const ruleValue = options.find(({ value }) => value === rule.targetKey)

  // TODO: bring creatable options back
  return (
    <thead>
      <tr>
        <th>{convertToLetters(rule.sourceIndex + 1)}</th>
        <SourceColumnTableHeader columnName={columnName}>{columnName}</SourceColumnTableHeader>
        <th ref={cellRef} style={{ zIndex: isOpen ? 50 : 0 }}>
          <Creatable
            options={options}
            isClearable={true}
            ref={creatableRef}
            formatCreateLabel={(field) => (
              <Button classes={['invert', 'column-match-include-dropdown']}>
                <FontAwesomeIcon icon={faPlus} />
                {t('buttons.createField', { field })}
              </Button>
            )}
            formatOptionLabel={({ value, label }) => {
              if (value === ADD_CUSTOM_FIELD_IDENTIFIER && allowCustom) {
                return (
                  <Button
                    classes={['invert', 'column-match-include-dropdown']}
                    onClick={async (e) => {
                      e.stopPropagation()
                      e.preventDefault()

                      const customField = recipe.readableSourceName(rule)

                      if (!recipe.hasField(customField)) {
                        recipe.addCustomField(customField)
                      }

                      await recipe.modifyRule(rule.sourceIndex, customField, (newRule) =>
                        batch.verifyStepChange(STEP.MATCH_FIELD, newRule)
                      )

                      if (creatableRef.current) {
                        creatableRef.current.onMenuClose()
                      }

                      onChange()
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    {label}
                  </Button>
                )
              }

              return label
            }}
            styles={styles}
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            tabIndex={((rule.sourceIndex + 1) * 3).toString()}
            placeholder={t('lookupMatchingFields')}
            isValidNewOption={(inputValue) => {
              if (!batch.settings.allowCustom) {
                return false
              }

              if (inputValue && inputValue.length > 0) {
                return !recipe.hasField(inputValue)
              }

              return false
            }}
            onChange={async (selectValue?: OptionTypeBase | null) => {
              debug('Value Selected', selectValue)
              if (selectValue && 'length' in selectValue) {
                return
              }
              if (batch.settings.allowCustom && selectValue) {
                const key = selectValue.value
                if (!recipe.hasField(key)) {
                  recipe.addCustomField(selectValue.value)
                }
              }
              await recipe.modifyRule(rule.sourceIndex, selectValue?.value, (newRule) =>
                batch.verifyStepChange(STEP.MATCH_FIELD, newRule).then((result) => {
                  if (result === false) {
                    creatableRef.current.setState({ value: ruleValue })
                  }
                  return result
                })
              )
              onChange()
            }}
            value={ruleValue}
          />
        </th>
      </tr>
    </thead>
  )
}
TopRow.displayName = 'TopRow'
