import * as Sentry from '@sentry/react'
import mapSeries from 'async/mapSeries'
import { chunk } from 'lodash'
import { debug } from 'loglevel'

import { STAGE, STEP } from '../controller'
import { publicCreateBatch } from '../mutations/publicCreateBatch'
import { publicCreateUpload } from '../mutations/publicCreateUpload'
import { publicSubmitRows } from '../mutations/publicSubmitRows'
import { publicUpdateBatch } from '../mutations/publicUpdateBatch'
import { publicUpdateUploadStatus } from '../mutations/publicUpdateUploadStatus'
import { translateIResultRecordToPublicRowDto } from '../translations/translateIResultRecordToPublicRowDto'
import {
  IDictionary,
  IHeaderMatched,
  ILegacyMeta,
  IPrimitive,
  IRawHeader,
  Nullable
} from '../types/general.interface'
import { PublicUpdateBatchInput } from '../types/graphQL'
import { IBatchConfig, ISettings } from '../types/settings.interface'
import { emit, emitAndWait, hasListeners, IEvents } from '../utils/event.manager'
import { convertToLetters } from '../utils/functions'
import { License } from '../utils/license.manager'
import { IMetricStats } from '../utils/metric.stats'
import { stringifyDictionary } from '../utils/misc'
import { IProgressTracker } from '../utils/progress.tracker'
import { retryUntilSuccessful } from '../utils/retry'
import { FileParser } from './file.parser'
import { uploadToObjectStorage } from './object.storage'
import { IRule, Recipe } from './recipe'
import { Validator } from './validator'
import { IResultRecord, IResultRecordsTuple, View } from './view'

export class Batch {
  public id?: string
  public readonly parser?: FileParser
  public readonly view: View
  public readonly recipe: Recipe
  public readonly validator: Validator
  private writeAccessKey?: string

  private timestamps: {
    createdAt: Date
    submittedAt?: Date
    matchedAt?: Date
    handledAt?: Date
    failedAt?: Date
  } = { createdAt: new Date() }

  private failureReason?: string

  constructor(
    private readonly batchConfig: IBatchConfig,
    private readonly license: License,
    src: FileParser | View,
    public readonly settings: ISettings,
    public readonly statsInstance: IMetricStats,
    initialId?: string,
    initialWriteAccessKey?: string
  ) {
    // used purely for easy debugging access
    // window.FF_BATCH = this
    if (src instanceof FileParser) {
      this.recipe = new Recipe(this.settings, this.batchConfig)
      this.parser = src
      this.validator = new Validator(this.recipe, this.batchConfig)
      this.view = new View(src, this.validator, this.recipe)
    } else {
      this.view = src
      this.recipe = src.recipe
      this.validator = new Validator(this.recipe, this.batchConfig)
    }

    if (initialId) {
      this.id = initialId
      this.timestamps.createdAt = new Date()
    }

    if (initialWriteAccessKey) {
      this.writeAccessKey = initialWriteAccessKey
    }
  }

  public async init(): Promise<void> {
    if (this.id) {
      return
    }

    if (this.parser) {
      await this.parser.loadSampleData()
      await this.parser.reloadHeaderConfig()
      if (this.parser.headersSet) {
        await this.recipe.generateInitialRules(this.parser)
      }
    }

    await retryUntilSuccessful(async () => {
      await this.createOnServer()
    })
  }

  public async initLocal(): Promise<void> {
    const { parser } = this
    if (!parser) {
      throw new Error('cannot use initLocal without a parser')
    }
    await parser.loadSampleData()
    await parser.reloadHeaderConfig()

    if (parser.headersSet) {
      await this.recipe.generateInitialRules(parser)
    }
  }

  public async recordMilestoneMatched() {
    this.timestamps.matchedAt = new Date()

    const countRows = this.view.getLength()
    const countColumns = this.parser?.getHeaders()?.length || this.recipe.getWorkingRules().length
    const countColumnsMatched = this.recipe.getWorkingRules().length
    const headersMatched = this.castRulesToLegacyMatch() || []
    const headersRaw = this.castHeadersToLegacyHeaders()
    const headerHash = this.parser?.getHash()

    await this.update({
      matchedAfter: this.timestamps.matchedAt.getTime() - this.timestamps.createdAt.getTime(),
      headersMatched,
      headersRaw,
      headerHash,
      countRows,
      countColumns,
      countColumnsMatched
    })
  }

  public async recordManualUpload() {
    const countRows = this.view.getLength()
    const countColumns = this.parser?.getHeaders()?.length || this.recipe.getWorkingRules().length

    await this.update({
      countRows,
      countColumns
    })
  }

  public async recordMilestoneSubmitted(countRowsAccepted: number) {
    this.timestamps.submittedAt = new Date()

    // @todo ensure this reflects ignored rows, once implemented
    const countRowsInvalid = this.validator.countErrorRows()

    await this.update({
      submittedAfter: this.timestamps.submittedAt.getTime() - this.timestamps.createdAt.getTime(),
      countRowsInvalid,
      countRowsAccepted
    })
  }

  public async recordMilestoneHandled() {
    this.timestamps.handledAt = new Date()
    await this.update({
      handledAfter: this.timestamps.handledAt.getTime() - this.timestamps.createdAt.getTime()
    })
  }

  public async recordMilestoneFailed(failureReason?: string) {
    this.timestamps.failedAt = new Date()
    this.failureReason = failureReason
    await this.update({
      failureReason,
      failedAfter: this.timestamps.failedAt.getTime() - this.timestamps.createdAt.getTime()
    })
  }

  public async finalSubmit(progressBar?: IProgressTracker, activeStage?: STAGE) {
    // TODO: make sure this is honored in Batch
    const inChunks = false

    let richData = inChunks ? [] : await this.view.getAnnotatedOutput()

    // Only include edited rows in INITIAL stage
    if (activeStage === STAGE.INITIAL) {
      richData = richData.filter((record) => record.edited)
    }

    const acceptedCount = this.settings.allowInvalidSubmit
      ? richData.length
      : richData.reduce((count: number, record) => count + (record.valid ? 1 : 0), 0)

    await this.recordMilestoneSubmitted(richData.length)

    if (this.settings.managed) {
      await this.submitRows(richData, progressBar)
    }
    // If manual set row/column counts
    if (!this.parser) {
      await this.recordManualUpload()
    }
    const meta = this.generateLegacyMeta(acceptedCount)
    debug('data:submit', richData)
    emit('data:submit', { results: richData, meta })

    // @todo move this into a custom event, i.e. do/handled
    // @todo add an event for failure as well
    await this.recordMilestoneHandled()
  }

  public getParser(): FileParser {
    if (!this.parser) {
      throw new Error('This batch is not not file based and should not be looking for a parser.')
    }
    return this.parser
  }

  public isEmpty(): boolean {
    return this.view.getLength() <= 0
  }

  public isManualUpload(): boolean {
    return !this.parser && !this.batchConfig?.source
  }

  public isParsed(): boolean {
    return !!this.parser
  }

  public async createOnServer() {
    const licenseKey = this.license.key

    if (!licenseKey) {
      throw new Error('License key missing')
    }
    const { endUser, legacySettingsId, developmentMode, fieldHooks, hasRecordHook } =
      this.batchConfig
    const devMode = developmentMode || this.settings.devMode ? true : false
    const { id, writeAccessKey, error } = await publicCreateBatch({
      devMode,
      endUser: endUser && stringifyDictionary(endUser),
      fileName: this.parser ? this.parser.fileName : undefined,
      importedFromUrl: document.referrer,
      legacySettingsId,
      legacyWebhookUrl: this.settings.webhookUrl,
      legacyHasFieldHooks: Boolean(fieldHooks?.length),
      legacyHasRecordHooks: Boolean(hasRecordHook),
      licenseKey,
      managed: this.settings.managed || false,
      manual: !this.parser
    })

    Sentry.setExtra('batchId', id)

    if (error) {
      Sentry.captureException(error)
    }

    this.id = id
    this.writeAccessKey = writeAccessKey
    this.timestamps.createdAt = new Date()

    if (this.settings.managed) {
      await this.uploadSourceFile()
    }

    return id
  }

  public async verifyStepChange(
    step: STEP,
    payload?: IEvents['step:change']['payload']
  ): Promise<boolean> {
    if (this.id && hasListeners('step:change') && this.batchConfig.stepHooks?.includes(step)) {
      if (!this.batchConfig.features?.stepHooks) {
        // tslint:disable-next-line: no-console
        console.info('[Flatfile] Contact Flatfile to get access to `registerStepHooks()`')
        return true
      }

      const result = await emitAndWait('step:change', {
        step,
        payload: payload ?? {
          batchId: this.id,
          fileName: this.parser?.fileName,
          fileSize: this.parser?.fileSize,
          fileType: this.parser?.fileType,

          count_rows: this.view.getLength(),
          count_columns: this.parser?.getHeaders()?.length || this.recipe.getWorkingRules().length,
          count_columns_matched: this.recipe.getWorkingRules().length,

          headers_raw: this.castHeadersToLegacyHeaders(),
          headers_matched: this.castRulesToLegacyMatch(),

          sample: this.parser?.sample
        }
      })

      return typeof result === 'boolean' ? result : true
    }

    return true
  }

  private async uploadSourceFile() {
    const { key: licenseKey } = this.license
    if (!this.parser || !licenseKey) {
      return
    }
    const file = this.parser.getFile() as File
    const { uploadId, objectUrl } = await publicCreateUpload({
      batchId: this.id,
      fileName: this.parser.fileName,
      fileSize: this.parser.fileSize,
      fileType: this.parser.fileType,
      licenseKey
    })

    if (!objectUrl || !uploadId) {
      return
    }

    const uploadResponse = await uploadToObjectStorage(objectUrl, file)

    if (!uploadResponse.ok) {
      await publicUpdateUploadStatus({
        licenseKey,
        status: 'failed',
        uploadId
      })
      return
    }

    await publicUpdateUploadStatus({
      licenseKey,
      status: 'uploaded',
      uploadId
    })
  }

  private async update(variables: PublicUpdateBatchInput) {
    const licenseKey = this.license.key

    if (!licenseKey) {
      throw new Error('License key missing')
    }

    if (!this.id || !this.writeAccessKey) {
      throw new Error('Cannot update a batch before calling init()')
    }

    const stats = this.statsInstance.getStatsAndReset()

    await publicUpdateBatch({
      batchId: this.id,
      licenseKey,
      writeAccessKey: this.writeAccessKey,
      stats,
      ...variables
    })
  }

  private generateLegacyMeta(countRowsAccepted: number): ILegacyMeta {
    if (!this.id) {
      throw new Error('Batch init() must be called before generateLegacyMeta')
    }

    const categoryFieldMap = this.recipe
      .getWorkingRules()
      .reduce((acc: IDictionary<IDictionary<Nullable<IPrimitive>>>, rule: IRule) => {
        if (rule.targetType === 'select' && rule.targetKey && rule.optionsMap) {
          acc[rule.targetKey] = rule.optionsMap
        }
        return acc
      }, {})

    const devMode = this.batchConfig.developmentMode || this.settings.devMode ? true : false

    return {
      batchID: this.id,
      // endUser?: ICustomerObject

      filename: this.parser?.fileName,
      managed: this.settings?.managed ?? false,
      filetype: 'csv',
      manual: !this.parser,
      devMode,

      count_rows: this.view.getLength(),
      count_rows_accepted: countRowsAccepted,
      count_columns: this.parser?.getHeaders()?.length || this.recipe.getWorkingRules().length,
      count_columns_matched: this.recipe.getWorkingRules().length,

      headers_raw: this.castHeadersToLegacyHeaders(),
      headers_matched: this.castRulesToLegacyMatch(),
      category_field_map: categoryFieldMap,

      failure_reason: this.failureReason,

      submitted_at: this.timestamps.submittedAt?.toISOString(),
      failed_at: this.timestamps.failedAt?.toISOString(),
      created_at: this.timestamps.createdAt.toISOString(),
      handled_at: this.timestamps.handledAt?.toISOString(),
      matched_at: this.timestamps.matchedAt?.toISOString(),

      stylesheet: this.view.styles
    }
  }

  /**
   * Used for legacy SDK integration
   * @deprecated
   */
  private castRulesToLegacyMatch(): IHeaderMatched[] {
    const rules = this.recipe.getWorkingRules()
    return rules.map((r) => ({
      index: r.sourceIndex,
      letter: convertToLetters(r.sourceIndex + 1),
      optionsMap: r.optionsMap,
      value: undefined,
      matched_key: r.targetKey as string
    }))
  }

  /**
   * Used for legacy SDK integration
   * @deprecated
   */
  private castHeadersToLegacyHeaders(): IRawHeader[] | undefined {
    if (!this.parser) {
      return undefined
    }
    const headers = this.parser.getHeaders()
    if (!headers) {
      return undefined
    }

    return headers.map(([v, i]) => ({
      index: i,
      value: v,
      letter: convertToLetters(i + 1)
    }))
  }

  private async submitRows(rows: IResultRecord[], progressBar?: IProgressTracker) {
    const {
      license: { key: licenseKey },
      id: batchId,
      writeAccessKey
    } = this

    if (!licenseKey) {
      throw new Error('License key missing')
    }

    if (!batchId || !writeAccessKey) {
      throw new Error('Cannot update a batch before calling init()')
    }

    const rowSubmitChunks = chunk(rows, 500).map((rowChunk, index) => [rowChunk, index])
    progressBar?.init(rowSubmitChunks.length)
    const requestStatuses: boolean[] = await mapSeries(
      rowSubmitChunks,
      async (
        [rowsChunk, rowsChunkIndex]: IResultRecordsTuple,
        // tslint:disable-next-line:no-any
        cb: (err?: null | string, data?: any) => void
      ) => {
        try {
          await retryUntilSuccessful(
            async () => {
              const successfulSubmit = await publicSubmitRows({
                licenseKey,
                batchId,
                writeAccessKey,
                rows: rowsChunk.map(translateIResultRecordToPublicRowDto),
                sequence: {
                  index: rowsChunkIndex,
                  length: rowSubmitChunks.length
                }
              })
              if (!successfulSubmit) {
                throw new Error(`submit rows was unsuccessful on chunk ${rowsChunkIndex}`)
              } else {
                cb(null, successfulSubmit)
              }
            },
            20,
            `Submit rows for batch ${batchId} chunk ${rowsChunkIndex}`
          )
          // tslint:disable-next-line:no-any
        } catch (e: any) {
          cb(e?.message ?? e ?? typeof e)
        }
        progressBar?.tick()
      }
    )

    progressBar?.complete()

    const rowSubmitCount = rowSubmitChunks.length
    const failedRowSubmitCount = requestStatuses.filter((status) => !status).length

    if (failedRowSubmitCount > 0) {
      throw new Error(`${failedRowSubmitCount}/${rowSubmitCount} public row submit requests failed`)
    }
  }

  public static async createManagedBatchOnServer(
    license: License,
    batchConfig: IBatchConfig,
    fileName: string,
    settings: ISettings
  ) {
    if (!license.key) {
      throw new Error('license.key must be present')
    }
    if (!settings.managed) {
      throw new Error('managed: true required for server side processing')
    }
    const { endUser, legacySettingsId } = batchConfig
    const devMode = batchConfig.developmentMode || settings.devMode ? true : false

    const { id, writeAccessKey, error } = await publicCreateBatch({
      endUser: endUser && stringifyDictionary(endUser),
      devMode,
      fileName,
      importedFromUrl: document.referrer,
      legacySettingsId,
      legacyWebhookUrl: settings.webhookUrl,
      licenseKey: license.key,
      managed: settings.managed || false,
      manual: false
    })

    Sentry.setExtra('batchId', id)

    if (error) {
      Sentry.captureException(error)
    }

    return { id, writeAccessKey, error }
  }
}
