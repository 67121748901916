import { getAsyncGraphClient } from '../lib/graph.client'
import { PUBLIC_CREATE_UPLOAD } from '../queries/PUBLIC_CREATE_UPLOAD'
import {
  PublicCreateUpload,
  PublicCreateUploadVariables
} from '../queries/types/PublicCreateUpload'

export const publicCreateUpload = async (
  variables: PublicCreateUploadVariables
): Promise<{ uploadId?: string; objectUrl?: string }> => {
  const graphClient = await getAsyncGraphClient({
    operation: 'PUBLIC_CREATE_UPLOAD',
    variables
  })
  try {
    const mutationResponse = await graphClient.mutate<
      PublicCreateUpload,
      PublicCreateUploadVariables
    >({
      mutation: PUBLIC_CREATE_UPLOAD,
      variables
    })
    if (mutationResponse && mutationResponse.data) {
      return mutationResponse.data.publicCreateUpload
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }
  return { uploadId: undefined, objectUrl: undefined }
}
