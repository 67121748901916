import { PublicRowDto } from '../../types/globalTypes'
import { IResultRecord } from '../lib/view'

export const translateIResultRecordToPublicRowDto = ({
  deleted,
  data: mapped,
  sequence,
  valid
}: IResultRecord): PublicRowDto => ({
  deleted,
  mapped,
  sequence,
  valid
})
