import React, { FC, ReactNode, useState } from 'react'

import { useTranslation } from 'react-i18next'
import Modal, { Props as BaseProps } from 'react-modal'
import { useModal } from 'react-modal-hook'
import styled, { css, useTheme } from 'styled-components'

import { ITheme } from '../theme'
import { Button } from './button'
import { Spinner } from './spinner'

export const useDialog = (): [
  (type: DialogType, props: Partial<DialogProps>) => void,
  () => void
] => {
  const { t } = useTranslation()

  const [state, setState] = useState<DialogProps>({
    message: undefined,
    onConfirm: undefined
  })

  const [showModal, hideModal] = useModal(() => {
    switch (state.type) {
      case 'success':
        return (
          <SuccessModal
            msg={state.message || t('dialogs.success')}
            onConfirm={() => {
              hideModal()
              state.onConfirm?.()
            }}
          />
        )
      case 'error':
        return (
          <ErrorModal
            msg={state.message || t('dialogs.error')}
            onDismiss={() => {
              hideModal()
              state.onConfirm?.()
            }}
          />
        )

      case 'confirm':
        return (
          <ConfirmModal
            msg={state.message}
            onConfirm={() => {
              hideModal()
              state.onConfirm?.()
            }}
            onDismiss={hideModal}
          />
        )
      case 'progress':
        return <ProgressModal msg={state.message || t('dialogs.loading')} />
      default:
        return <div />
    }
  }, [state])

  return [
    function openDialog(type, props) {
      setState({ type, message: props.message, onConfirm: props.onConfirm })
      showModal()
    },
    function closeDialog() {
      setState({})
      hideModal()
    }
  ]
}
type DialogType = 'success' | 'error' | 'confirm' | 'progress'
type DialogProps = {
  type?: DialogType
  message?: string
  onConfirm?: () => void
}

const StyledModal = styled(Modal)`
  ${({ theme }) =>
    theme?.global?.backgroundColor &&
    css`
      background-color: ${theme.global.backgroundColor};

      ul li {
        background-color: ${theme.global.backgroundColor};
      }
    `}

  ${({ theme }) =>
    theme?.global?.textColor &&
    css`
      color: ${theme.global.textColor};

      ul li {
        color: ${theme.global.textColor};
      }
    `}

  ${({ theme }) =>
    theme?.global?.primaryTextColor &&
    css`
      .primaryTextColor {
        color: ${theme.global.primaryTextColor};
      }
    `}

  ${({ theme }) =>
    theme?.global?.successColor &&
    css`
      .successColor {
        color: ${theme.global.successColor};
      }
    `}

  ${({ theme }) =>
    theme?.global?.warningColor &&
    css`
      .warningColor {
        color: ${theme.global.warningColor};
      }
    `}

  &.flatfile-modal {
    ${({ theme }) => theme?.dialog?.root && css(theme.dialog.root)}
  }

  .modal-header {
    ${({ theme }) => theme?.dialog?.content && css(theme.dialog.content)}
  }

  ul li {
    ${({ theme }) => theme?.dialog?.footer && css(theme.dialog.footer)}
  }
`

export const DefaultModal: FC<DefaultProps> = ({ msg, className, children, isOpen, ...props }) => {
  const theme = useTheme() as ITheme

  return (
    <StyledModal
      style={{
        overlay: theme?.dialog?.overlayColor
          ? {
              backgroundColor: theme.dialog.overlayColor
            }
          : {}
      }}
      className={`flatfile-modal borderColor ${className}`}
      overlayClassName='flatfile-modal-overlay'
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      isOpen={isOpen === undefined ? true : isOpen}
      {...props}
    >
      {msg && (
        <div className='modal-header'>
          <h4 className='primaryTextColor'>{msg}</h4>
        </div>
      )}
      {children}
    </StyledModal>
  )
}

const ProgressModal: FC<DefaultProps> = ({ msg, ...props }) => {
  return (
    <DefaultModal className='upload-progress' shouldCloseOnOverlayClick={false} {...props}>
      <Spinner text={msg} />
    </DefaultModal>
  )
}

const SuccessModal: FC<ConfirmProps & DefaultProps> = ({ confirmTitle, onConfirm, ...props }) => {
  const { t } = useTranslation()

  return (
    <DefaultModal
      className='upload-success'
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => onConfirm()}
      {...props}
    >
      <ul className='single'>
        <li>
          <Button
            id='upload-success-modal-acknowledge'
            title={confirmTitle || t('buttons.ok')}
            classes={['primary', 'final-success']}
            onClick={() => onConfirm()}
          />
        </li>
      </ul>
    </DefaultModal>
  )
}

export const ErrorModal: FC<DismissProps & DefaultProps> = ({
  dismissTitle,
  onDismiss,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <DefaultModal
      className='error'
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => onDismiss()}
      {...props}
    >
      <ul className='single'>
        <li>
          <Button
            id='error-acknowledge'
            title={dismissTitle || t('buttons.ok')}
            classes={['primary', 'final-error']}
            onClick={() => onDismiss()}
          />
        </li>
      </ul>
    </DefaultModal>
  )
}

export const ConfirmModal: FC<DismissProps & ConfirmProps & DefaultProps> = ({
  dismissTitle,
  confirmTitle,
  onConfirm,
  onDismiss,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <DefaultModal
      className='error'
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => onDismiss()}
      {...props}
    >
      <ul>
        <li>
          <Button
            id='confirm-deny'
            title={dismissTitle || t('buttons.no')}
            classes={['invert', 'no-button', 'dialog-confirm-no']}
            onClick={() => onDismiss()}
          />
        </li>
        <li>
          <Button
            id='confirm-acknowledge'
            title={confirmTitle || t('buttons.yes')}
            classes={['primary', 'yes-button', 'dialog-confirm-yes']}
            onClick={() => onConfirm()}
          />
        </li>
      </ul>
    </DefaultModal>
  )
}
ConfirmModal.displayName = 'ConfirmModal'

// tslint:disable-next-line:interface-name
interface DefaultProps extends Omit<BaseProps, 'isOpen'> {
  msg?: ReactNode
  isOpen?: boolean
}

type ConfirmProps = {
  confirmTitle?: string
  onConfirm: () => void
} & DefaultProps

type DismissProps = {
  dismissTitle?: string
  onDismiss: () => void
} & DefaultProps
