import React from 'react'

import cssesc from 'cssesc'
import { omit } from 'lodash'
import styled, { createGlobalStyle, css, CSSObject } from 'styled-components'

import { AutoMatchRule, ConfirmRule } from './components/confirm.map'
import { Wrapper } from './fragments/layout'
import { IIntegrationFont } from './types/settings.interface'

type IStyleCSS = Pick<
  CSSObject,
  | 'backgroundColor'
  | 'color'
  | 'padding'
  | 'margin'
  | 'fontSize'
  | 'fontWeight'
  | 'lineHeight'
  | 'border'
  | 'borderTop'
  | 'borderRight'
  | 'borderBottom'
  | 'borderLeft'
  | 'borderRadius'
  | 'textAlign'
  | 'boxShadow'
>

type IStyleCSSButton = IStyleCSS & Pick<CSSObject, ':focus' | ':hover' | ':active'>

interface IStyleCSSSvg {
  fill?: string
}

type IStyleTable = Pick<
  CSSObject,
  'backgroundColor' | 'color' | 'borderColor' | 'fontSize' | 'fontWeight' | 'textAlign'
>

interface IStyleCSSTableColumn extends IStyleTable {
  ':focus'?: IStyleTable
  ':hover'?: IStyleTable
}

interface IStyleCSSTableRow extends IStyleTable {
  ':focus'?: IStyleTable
  ':hover'?: IStyleTable
  ':invalid'?: IStyleTable
}

interface IStyleCSSTableRowIndex extends IStyleTable {
  ':focus'?: IStyleTable
  ':hover'?: IStyleTable
  ':empty'?: IStyleTable
}

type IStyleSelect = Pick<
  CSSObject,
  | 'backgroundColor'
  | 'color'
  | 'border'
  | 'borderTop'
  | 'borderRight'
  | 'borderBottom'
  | 'borderLeft'
  | 'borderRadius'
  | 'boxShadow'
>

interface IStyleCSSSelect extends IStyleSelect {
  ':focus'?: IStyleSelect
  ':active'?: IStyleSelect
  ':selected'?: IStyleSelect
}

export interface ITheme {
  global?: {
    backgroundColor?: CSSObject['backgroundColor']
    textColor?: CSSObject['color']

    primaryTextColor?: CSSObject['color']
    secondaryTextColor?: CSSObject['color']

    successColor?: CSSObject['color']
    warningColor?: CSSObject['color']

    borderRadius?: CSSObject['borderRadius']
    overlayColor?: CSSObject['backgroundColor']
    fontFamily?: CSSObject['fontFamily']
  }
  buttons?: {
    // global
    primary?: IStyleCSSButton
    secondary?: IStyleCSSButton
    success?: IStyleCSSButton
    tertiary?: IStyleCSSButton

    dropzoneUpload?: IStyleCSSButton

    headerMatchYes?: IStyleCSSButton
    headerMatchNo?: IStyleCSSButton

    columnMatchConfirm?: IStyleCSSButton
    columnMatchConfirmWithDupes?: IStyleCSSButton
    columnMatchIgnore?: IStyleCSSButton
    columnMatchInclude?: IStyleCSSButton
    columnMatchIncludeDropdown?: IStyleCSSButton
    columnMatchIncludeOptionDropdown?: IStyleCSSButton
    columnMatchEdit?: IStyleCSSButton

    dialogConfirmYes?: IStyleCSSButton
    dialogConfirmNo?: IStyleCSSButton

    dialogFinalYes?: IStyleCSSButton
    dialogFinalNo?: IStyleCSSButton

    dialogFinalSuccess?: IStyleCSSButton
    dialogFinalError?: IStyleCSSButton

    dataSourceCancel?: IStyleCSSButton
    dataSourceContinue?: IStyleCSSButton
  }
  header?: {
    root?: IStyleCSS
    title?: IStyleCSS
    closeButton?: IStyleCSSButton
  }
  footer?: {
    root?: IStyleCSS
  }
  progressBar?: {
    root?: IStyleCSS
    current?: IStyleCSS
    complete?: IStyleCSS
    incomplete?: IStyleCSS
    arrow?: IStyleCSSSvg
  }
  dropzone?: {
    root?: IStyleCSS
    content?: IStyleCSS
    fileTypes?: IStyleCSS
    accepted?: IStyleCSS
  }
  manualInput?: {
    root?: IStyleCSS
    title?: IStyleCSS
    table?: {
      th?: IStyleCSSTableColumn
      td?: IStyleCSSTableRow
      rowIndex?: IStyleCSSTableRowIndex
    }
  }
  headerMatch?: {
    root?: IStyleCSS
    content?: IStyleCSS
    info?: IStyleCSS
    icon?: IStyleCSSSvg
    table?: {
      th?: IStyleCSSTableColumn
      td?: IStyleCSSTableRow
    }
    select?: IStyleCSSSelect
  }
  columnMatch?: {
    root?: IStyleCSS
    content?: IStyleCSS
    rule?: IStyleCSS
    autoMatchRule?: {
      root?: IStyleCSS
      icon?: IStyleCSSSvg
      description?: IStyleCSS
      field?: IStyleCSS
    }
    table?: {
      th?: IStyleCSSTableColumn
      td?: IStyleCSSTableRow
    }
    select?: IStyleCSSSelect
  }
  dialog?: {
    root?: IStyleCSS
    content?: IStyleCSS
    footer?: IStyleCSS
    overlayColor?: string // overlay color
  }
  dataSource?: {
    root?: IStyleCSS
    title?: IStyleCSS
    subtitle?: IStyleCSS
    step?: IStyleCSS
    footer?: IStyleCSS

    select?: IStyleCSSSelect
  }
  loader?: {
    root?: IStyleCSS
    overlayColor?: string // overlay color
  }
  iterator?: {
    root?: IStyleCSS
    overlayColor?: string // overlay color
    barColor?: string // loader bar foreground color
    barBackground?: string // loader bar background color
  }
  __CUSTOM_FONTS__?: IIntegrationFont[] // custom fonts from integrations => fonts
  __FULL_SCREEN__?: boolean
}

export const createTheme = (
  theme: ITheme = {},
  fonts: IIntegrationFont[] = [],
  fullScreen = false
) => {
  if (fonts.length) {
    theme.__CUSTOM_FONTS__ = fonts
  }
  if (fullScreen) {
    theme.__FULL_SCREEN__ = true
  }
  return Object.assign({}, theme)
}

export const ThemeController: React.FC = ({ children }) => (
  <ThemeWrapper className='flatfile-root'>
    {children}
    <ThemeGlobalStyles />
  </ThemeWrapper>
)

const ThemeGlobalStyles = createGlobalStyle`
  ${({ theme }) =>
    theme?.global?.overlayColor &&
    css`
      body {
        background-color: ${theme.global.overlayColor};
      }
    `}
  ${({ theme }) =>
    theme?.__FULL_SCREEN__ &&
    `
    .flatfile-root{
      max-width: unset;
      box-shadow: none;
    }

    body {
      background-color: ${theme?.global?.backgroundColor || '#fff'};
    }
  `}
  ${({ theme }) =>
    theme?.global?.fontFamily &&
    css`
      body {
        font-family: ${theme.global.fontFamily}, Gilroy, 'Helvetica Neue', Arial, sans-serif;
      }
    `}
  ${({ theme }) =>
    theme?.__CUSTOM_FONTS__?.map((font) =>
      css`
        @font-face {
          font-family: ${cssesc(font.family, { wrap: true })};
          ${font.src?.length &&
          `src: ${font.src
            .map(
              ({ url, format }) =>
                `url(${cssesc(url, { wrap: true })}) format(${cssesc(format, { wrap: true })})`
            )
            .join(', ')};`}
          ${font.weight && `font-weight: ${cssesc(font.weight.toString())};`}
        ${font.style && `font-style: ${cssesc(font.style)};`}
        }
      `.join('')
    )}
`

const ThemeWrapper = styled.div`
  ${({ theme }) =>
    theme?.global?.backgroundColor &&
    css`
      background-color: ${theme.global.backgroundColor};
    `};
  ${({ theme }) =>
    theme?.global?.textColor &&
    css`
      color: ${theme.global.textColor};
    `};

  ${({ theme }) =>
    theme?.global?.primaryTextColor &&
    css`
      .progress-current,
      .handsontable thead th,
      .handsontable tbody th,
      .Select .Select-value-label,
      .primaryTextColor {
        color: ${theme.global.primaryTextColor};
      }
    `}

  ${({ theme }) =>
    theme?.global?.secondaryTextColor &&
    css`
      .secondaryTextColor {
        color: ${theme.global.secondaryTextColor};
      }

      .notice {
        border-color: ${theme.global.secondaryTextColor};
      }

      .notice-right {
        border-left-color: ${theme.global.secondaryTextColor};
      }
    `}

  ${({ theme }) =>
    theme?.global?.successColor &&
    css`
      .successColor,
      .fa-check-square:hover {
        color: ${theme.global.successColor};
      }

      .col-required:after,
      .check-toggle[data-line='true'],
      .check-toggle[data-line='true']:before {
        background-color: ${theme.global.successColor};
      }

      .check-toggle[data-line='true'],
      .check-toggle[data-line='true']:after {
        border-color: ${theme.global.successColor};
      }
    `}

  ${({ theme }) =>
    theme?.global?.warningColor &&
    css`
      .warningColor,
      .col-body .column-unmatched .fa,
      .fa-warning {
        color: ${theme.global.warningColor};
      }
    `}

  ${({ theme }) =>
    theme?.global?.borderRadius &&
    css`
      overflow: hidden;
      border-radius: ${theme.global.borderRadius}!important;

      .secondary-loader,
      .dropzone.receive-file {
        border-radius: ${theme.global.borderRadius};
      }
    `}

  ${({ theme }) =>
    theme?.header?.closeButton &&
    css`
    .flatfile-close-button{
      ${css(theme.header.closeButton)}}
    }
  `}

  ${({ theme }) =>
    theme?.manualInput?.table?.th &&
    css`
      div.handsontable thead th,
      div.handsontable thead th.ht__highlight,
      div.handsontable tbody th,
      div.handsontable tbody th.ht__highlight {
        ${importantCSS(omit(theme.manualInput.table.th, ':focus') as CSSObject)}
      }

      ${theme?.manualInput?.table?.th?.[':focus'] &&
      css`
        div.handsontable thead th.ht__highlight,
        div.handsontable tbody th.ht__highlight {
          ${importantCSS(theme.manualInput.table.th[':focus'])}
        }
      `}
    `}

  ${({ theme }) =>
    theme?.manualInput?.table?.td &&
    css`
      div.handsontable tbody td,
      div.handsontable tbody td.ht__highlight {
        ${importantCSS(omit(theme.manualInput.table.td, [':focus', ':invalid']) as CSSObject)}
      }

      ${theme?.manualInput?.table?.td?.[':focus'] &&
      css`
        div.handsontable tbody td.ht__highlight {
          ${importantCSS(theme.manualInput.table.td[':focus'])}
        }
      `}

      ${theme?.manualInput?.table?.td?.[':invalid'] &&
      css`
        div.handsontable tbody td.htInvalid {
          ${importantCSS(theme.manualInput.table.td[':invalid'])}
        }
      `}
    `}

  ${({ theme }) =>
    theme?.manualInput?.table?.rowIndex &&
    css`
      div.handsontable tbody th,
      div.handsontable tbody th.ht__highlight {
        ${importantCSS(omit(theme.manualInput.table.rowIndex, [':focus', ':empty']) as CSSObject)}
      }

      ${theme?.manualInput?.table?.rowIndex?.[':focus'] &&
      css`
        div.handsontable tbody th.ht__highlight {
          ${importantCSS(theme.manualInput.table.rowIndex[':focus'])}
        }
      `}

      ${theme?.manualInput?.table?.rowIndex?.[':empty'] &&
      css`
        div.handsontable tbody th.is-empty-row {
          ${importantCSS(theme.manualInput.table.rowIndex[':empty'])}

          & > div {
            opacity: 1;
          }
        }
      `}
    `}

  .iterator-loading {
    ${({ theme }) =>
      theme?.iterator?.overlayColor &&
      css`
        background-color: ${theme.iterator.overlayColor};
      `}

    ${({ theme }) =>
      theme?.iterator?.barBackground &&
      css`
        .loading-line {
          background-color: ${theme.iterator.barBackground}!important;
        }
      `}

    .loading-label {
      ${({ theme }) => theme?.iterator?.root && importantCSS(theme.iterator.root)}
    }
  }
`

export const StyledMatchWrapper = styled(Wrapper)`
  background-color: rgb(245, 248, 250);
  background-image: linear-gradient(to top left, rgb(245, 248, 250) 50%, rgb(255, 255, 255));
  border-radius: 6px;

  ${({ theme }) =>
    theme?.__FULL_SCREEN__ &&
    css`
      background: none;
    `}

  ${({ theme }) =>
    theme?.global?.backgroundColor &&
    css`
      background: none;

      .col-body,
      .col-body table td,
      .col-body table th,
      .col-body table thead th:last-of-type,
      .col-body table thead th:nth-of-type(2):before,
      .col-body table thead th:nth-of-type(2):after,
      .col-body .match-options-table-container,
      .col-body table.match-options-table tbody tr td:first-of-type,
      .col-body table.match-options-table tbody tr td:last-of-type,
      .col-body .suggested-fieldname {
        background-color: ${theme.global.backgroundColor};
      }
    `};

  ${({ theme }) =>
    theme?.global?.textColor &&
    css`
      .col-body aside ul li,
      .col-body aside ul li:first-of-type {
        color: ${theme.global.textColor};
      }
    `}

  ${({ theme }) => theme?.columnMatch?.root && css(theme.columnMatch.root)}
  ${({ theme }) =>
    theme?.columnMatch?.content &&
    css`
      .col-body {
        ${css(theme.columnMatch.content)}
      }
    `}

  ${ConfirmRule} {
    ${({ theme }) => theme?.columnMatch?.rule && css(theme.columnMatch.rule)}
  }

  ${AutoMatchRule} {
    ${({ theme }) =>
      theme?.columnMatch?.autoMatchRule &&
      css`
        ${theme?.columnMatch?.autoMatchRule?.root && css(theme.columnMatch.autoMatchRule.root)}

        .suggested-fieldname {
          ${theme?.columnMatch?.autoMatchRule?.field && css(theme.columnMatch.autoMatchRule.field)}
        }

        .field-description {
          ${theme?.columnMatch?.autoMatchRule?.description &&
          css(theme.columnMatch.autoMatchRule.description)}
        }

        ${theme?.columnMatch?.autoMatchRule?.icon?.fill &&
        css`
          svg {
            color: ${theme.columnMatch.autoMatchRule.icon.fill};
          }
        `}
      `}
  }

  ${({ theme }) =>
    theme?.columnMatch?.table?.th &&
    css`
      .column-edit-table .col-body.column-ignored .ignored-table,
      .col-body table th,
      .col-body table.match-options-table td {
        ${importantCSS(theme.columnMatch.table.th as CSSObject)}
      }

      ${theme.columnMatch.table.th.borderColor &&
      css`
        .col-body table thead th:nth-of-type(2):after,
        .col-body table.match-options-table tbody tr td:first-of-type:after {
          border-right-color: ${theme.columnMatch.table.th.borderColor};
          border-top-color: ${theme.columnMatch.table.th.borderColor};
        }
      `}

      ${theme.columnMatch.table.th.backgroundColor &&
      css`
        .col-body table thead th:nth-of-type(2):before,
        .col-body table thead th:nth-of-type(2):after {
          background-color: ${theme.columnMatch.table.th.backgroundColor};
        }
      `}
    `}

  ${({ theme }) =>
    theme?.columnMatch?.table?.td &&
    css`
      .col-body table:not(.match-options-table) td {
        ${importantCSS(theme.columnMatch.table.td as CSSObject)}
      }
    `}

  ${({ theme }) =>
    theme?.columnMatch?.select?.backgroundColor &&
    css`
      .col-body table thead th:nth-of-type(3),
      .col-body table thead th:nth-of-type(2):before {
        background-color: ${theme.columnMatch.select.backgroundColor}!important;
      }
    `}
`

export const importantCSS = (styles: CSSObject = {}, generateCSS = true) => {
  Object.keys(styles).forEach((key) => {
    if (typeof styles[key] === 'string') {
      styles[key] = (styles[key] as string).endsWith('!important')
        ? styles[key]
        : `${styles[key]}!important`
    } else {
      styles[key] = importantCSS(styles[key] as CSSObject, false) as CSSObject
    }
  })

  return generateCSS ? css(styles) : styles
}
