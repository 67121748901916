import { createGlobalStyle } from 'styled-components'

import GilroyBold from './GilroyBold.otf'
import GilroyMedium from './GilroyMedium.otf'
import GilroyRegular from './GilroyRegular.otf'
import GilroySemiBold from './GilroySemiBold.otf'

export const GilroyFont = createGlobalStyle`
  @font-face {
    font-family: 'Gilroy';
    font-weight: normal;
    src: url(${GilroyRegular}) format('opentype');
  }

  @font-face {
    font-family: 'Gilroy';
    font-weight: bold;
    src: url(${GilroyBold}) format('opentype');
  }

  @font-face {
    font-family: 'Gilroy';
    font-weight: 500;
    src: url(${GilroyMedium}) format('opentype');
  }

  @font-face {
    font-family: 'Gilroy';
    font-weight: 600;
    src: url(${GilroySemiBold}) format('opentype');
  }
`
