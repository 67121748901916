import * as Sentry from '@sentry/browser'
import { Severity } from '@sentry/browser'
import { NormalizedCacheObject } from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { ApolloLink, from, Operation } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { createHttpLink } from 'apollo-link-http'
import { FragmentDefinitionNode, OperationDefinitionNode } from 'graphql'
import { error } from 'loglevel'
import { MockApolloClient } from 'mock-apollo-client'

import { IBeforeFetchRequest, Nullable } from '../types/general.interface'
import { emit, emitAndWait, hasListeners } from '../utils/event.manager'

class GraphQLError extends Error {
  constructor(message: string, operationName: string) {
    super(`GraphQL Exception: "${operationName}": ${message}`)

    this.name = 'GraphQLError'
  }
}

let _graphClient: Nullable<ApolloClient<NormalizedCacheObject>>

export const mockGraphClient = (client: ApolloClient<NormalizedCacheObject> | MockApolloClient) => {
  _graphClient = client as ApolloClient<NormalizedCacheObject>
}

/**
 * Borrowed and enhanced from https://github.com/Haegin/apollo-sentry-link
 */
const operationInfo = (operation: Operation) => ({
  type: (operation.query.definitions.find((defn) => 'operation' in defn) as OperationDefinitionNode)
    .operation,
  name: operation.operationName,
  fragments: (
    operation.query.definitions.filter(
      (defn) => defn.kind === 'FragmentDefinition'
    ) as FragmentDefinitionNode[]
  )
    .map((defn) => defn.name.value)
    .join(', ')
})

const ApolloSentryLink = new ApolloLink((operation, forward) => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.addBreadcrumb({
      category: 'graphql',
      data: operationInfo(operation),
      level: Severity.Debug
    })
  }
  return forward(operation)
})

export const getAsyncGraphClient = async (payload: IBeforeFetchRequest) => {
  if (hasListeners('fetch:before')) {
    const { headers } = (await emitAndWait('fetch:before', payload)) || {}

    return getGraphClient(headers)
  }

  return getGraphClient()
}

export const getGraphClient = (withHeaders?: Record<string, string>) => {
  if (_graphClient && !withHeaders) {
    return _graphClient
  }

  const httpLink = createHttpLink({
    uri: window.FF_GRAPHQL_ENDPOINT
  })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...withHeaders
      }
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (String(message) !== message) {
          // tslint:disable-next-line:no-any
          message = (message as any).error || 'Unknown Error'
        }
        error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        emit('error:network', message)

        if (process.env.NODE_ENV === 'production') {
          Sentry.captureException(new GraphQLError(message, operation.operationName), {
            extra: {
              variables: JSON.stringify(operation.variables, null, 2)
            }
          })
        }
      })
    }
    if (networkError) {
      emit('error:network', networkError.message)
      error(`[Network error]: ${networkError}`)
    }
  })

  _graphClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([ApolloSentryLink, errorLink, authLink, httpLink]),
    name: 'portal'
  })

  return _graphClient
}
