import React, { Suspense, useEffect, useState } from 'react'

import { merge } from 'lodash'
import { I18nextProvider } from 'react-i18next'
import { ModalProvider } from 'react-modal-hook'
import styled, { ThemeProvider } from 'styled-components'

import { EnvironmentContext } from '../context/environment.context'
import { SettingsContext } from '../context/settings.context'
import i18n from '../i18n'
import { Batch } from '../lib/batch'
import { FileParser } from '../lib/file.parser'
import { RULE_STATUS } from '../lib/recipe'
import { GlobalStyles } from '../styles'
import { GilroyFont } from '../styles/fonts/GilroyFont'
import { createTheme, ThemeController } from '../theme'
import { ISettings } from '../types/settings.interface'
import { useListener } from '../utils/event.manager'
import { detach } from '../utils/functions'
import { validateSettings } from '../utils/settings.helpers'
import InitialViewDemo from './initial.demo'
import MatchViewDemo from './match.demo'
import ReviewViewDemo from './review.demo'

const DemoWrapper = styled.div`
  pointer-events: none;
  user-select: none;
`

const DEMO_SOURCE = [
  'first_name,last_name,email,phone',
  'John,Russell,john@mail.com,1234567890',
  'Elizabeth,Turner,elizabeth@mail.com,1234567890',
  'Jeffrey,King,jeffrey@mail.com,1234567890',
  'John,Russell,john@mail.com,1234567890',
  'Elizabeth,Turner,elizabeth@mail.com,1234567890',
  'Jeffrey,King,jeffrey@mail.com,1234567890'
].join('\n')

const DEMO_SETTINGS: ISettings = {
  type: 'DEMO',
  fields: [
    {
      label: 'First Name',
      key: 'first_name'
    },
    {
      label: 'Email',
      key: 'email'
    },
    {
      label: 'Phone',
      key: 'phone'
    }
  ],
  allowCustom: true
}

export enum DEMO_STAGE {
  INITIAL = 'INITIAL',
  MATCH = 'MATCH',
  REVIEW = 'REVIEW'
}

const createBatch = async () => {
  const file = new File([DEMO_SOURCE], 'file.csv', { type: 'text/csv;charset=utf-8;' })
  const parser = new FileParser(file, DEMO_SETTINGS)

  await parser.loadSampleData()
  parser.start = 0
  parser.hasHeader = true

  const batch = new Batch({ developmentMode: true }, { key: 'DEMO' }, parser, DEMO_SETTINGS, {
    add: () => false,
    setUserFocus: () => false,
    getStatsAndReset: () => ({})
  })

  batch.id = 'DEMO'

  await parser.loadSampleData()
  await batch.recipe.generateInitialRules(parser)

  setBatchRules(batch)

  batch.view.trimmedData
    .map((row) => batch.view.mapRowToRecord(row, true))
    .forEach((t) => {
      batch.view.validator.validateRow(t, true, {
        phone: { info: [{ message: 'Demo Error', level: 'error' }] }
      })
    })

  return batch
}

const setBatchRules = (batch: Batch | null, rules?: RULE_STATUS[]) => {
  rules = rules || new Array(4).fill(RULE_STATUS.ACCEPTED)
  rules.forEach((rule, i) => {
    batch?.view.recipe.modifyStatus(i, rule)
  })
}

function IndexDemo() {
  const [batch, setBatch] = useState<Batch | null>(null)
  const [theme, setTheme] = useState(createTheme())
  const [stage, setStage] = useState(DEMO_STAGE.INITIAL)

  useListener(
    'do/demo',
    ({ payload }) => {
      detach(async () => {
        if (
          payload.theme &&
          (await validateSettings({ ...DEMO_SETTINGS, theme: payload.theme }).valid)
        ) {
          setTheme(merge(payload.theme, { global: { overlayColor: 'white' } }))
        }
        if (
          payload.stage &&
          ['INITIAL', 'MATCH', 'REVIEW'].includes(payload.stage) &&
          payload.stage !== stage
        ) {
          if (payload.stage === DEMO_STAGE.MATCH) {
            setBatchRules(batch, [RULE_STATUS.PENDING, RULE_STATUS.PENDING, RULE_STATUS.IGNORED])
          }
          if (payload.stage === DEMO_STAGE.REVIEW) {
            setBatchRules(batch)
          }
          setStage(payload.stage)
        }
      })
    },
    [theme, stage]
  )

  useEffect(() => {
    detach(async () => {
      setBatch(await createBatch())
    })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <ThemeController>
        <GilroyFont />
        <GlobalStyles styles={{}} />
        <ModalProvider>
          <I18nextProvider i18n={i18n}>
            <EnvironmentContext.Provider
              value={{ license: { key: 'DEMO' }, features: {}, deactivated: false }}
            >
              <SettingsContext.Provider value={DEMO_SETTINGS}>
                <Suspense fallback='Loading...'>
                  <DemoWrapper className='active-stage borderColor'>
                    {batch && (
                      <>
                        {stage === DEMO_STAGE.INITIAL && <InitialViewDemo batch={batch} />}
                        {stage === DEMO_STAGE.MATCH && <MatchViewDemo batch={batch} />}
                        {stage === DEMO_STAGE.REVIEW && <ReviewViewDemo batch={batch} />}
                      </>
                    )}
                  </DemoWrapper>
                </Suspense>
              </SettingsContext.Provider>
            </EnvironmentContext.Provider>
          </I18nextProvider>
        </ModalProvider>
      </ThemeController>
    </ThemeProvider>
  )
}

export default IndexDemo
