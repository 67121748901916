export enum EMetricStatType {
  CLICK = 'CLICK',
  KEYDOWN = 'KEYDOWN'
}

export type IStat = {
  [key in EMetricStatType]: number
}

export interface IMetricStats {
  add(type: EMetricStatType): void
  setUserFocus(focus: string): void
  getStatsAndReset(): { [focus: string]: IStat }
}

export const createMetricStats = (): IMetricStats => {
  let focus: string
  let stats: { [focus: string]: IStat } = {
    GLOBAL: {
      CLICK: 0,
      KEYDOWN: 0
    }
  }

  function add(type: EMetricStatType): void {
    if (type === EMetricStatType.CLICK) {
      stats.GLOBAL.CLICK++
      if (focus && stats[focus]) {
        stats[focus].CLICK++
      }
    }
    if (type === EMetricStatType.KEYDOWN) {
      stats.GLOBAL.KEYDOWN++
      if (focus && stats[focus]) {
        stats[focus].KEYDOWN++
      }
    }
  }

  function getStatsAndReset(): { [p: string]: IStat } {
    const returnedStats = stats
    stats = {
      GLOBAL: {
        CLICK: 0,
        KEYDOWN: 0
      }
    }
    return returnedStats
  }

  function setUserFocus(userFocus: string): void {
    focus = userFocus
    if (!stats.hasOwnProperty(focus)) {
      stats[focus] = {
        CLICK: 0,
        KEYDOWN: 0
      }
    }
  }

  return {
    add,
    getStatsAndReset,
    setUserFocus
  }
}
