import styled, { css, CSSObject } from 'styled-components'

import { importantCSS } from '../theme'

export const MatchTable = styled.table`
  table-layout: fixed;
`
export const HeaderMatchTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
  border-width: 0;
  table-layout: fixed;
  width: 0;
  outline-width: 0;
  cursor: default;
  max-width: none;
  max-height: none;
  color: #333;

  tr {
    background: #fff;

    &.selectable {
      cursor: pointer;
    }
  }

  td:first-of-type {
    border-left: 1px solid #ccc;
  }

  th,
  td {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    height: 22px;
    empty-cells: show;
    line-height: 21px;
    padding: 0 4px 0 4px;
    background-color: #fff;
    vertical-align: top;
    overflow: hidden;
    outline-width: 0;
    background-clip: padding-box;
  }

  th {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    background-color: #eaeff5;
    border-color: #d0dbd8 !important;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
  }

  tr:first-child td {
    border-top: 1px solid #ccc;
  }

  th:first-child {
    border-left: 1px solid #ccc;
  }

  th:first-child {
    width: 28px;
  }

  tbody tr th:first-child {
    overflow: initial;

    div {
      position: relative;

      input[type='radio'] {
        appearance: none;

        & + label {
          position: absolute;
          left: -35px;
          top: 0;
          width: 30px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &::before {
            position: absolute;
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 1px solid #d0dbd8;
            background-color: #eaeff5;
          }

          &::after {
            position: absolute;
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background-color: #4d7aff;
            border-radius: 50%;
            opacity: 0;
          }
        }

        &:checked + label::after {
          opacity: 1;
        }
      }

      &.with-arrow {
        &:after,
        &:before {
          content: '';
          position: absolute;
          display: block;
        }

        &:before {
          width: 10px;
          height: 10px;
          top: 5px;
          left: -22px;
          border: solid
            ${({ theme }) =>
              theme?.headerMatch?.icon?.fill ?? theme?.global?.primaryTextColor ?? '#3f5c71'};
          border-width: 0 2px 2px 0;
          padding: 3px;
          transform: rotate(-45deg);
        }

        &:after {
          top: 5px;
          left: -32px;
          width: 20px;
          height: 2px;
          margin: 4px 0;
          background-color: ${({ theme }) =>
            theme?.headerMatch?.icon?.fill ?? theme?.global?.primaryTextColor ?? '#3f5c71'};
        }
      }
    }
  }

  ${({ theme }) =>
    theme?.headerMatch?.table &&
    css`
      ${theme.headerMatch.table.th &&
      css`
        ${theme.headerMatch.table.th.backgroundColor &&
        css`
          tr:first-child {
            background-color: ${theme.headerMatch.table.th.backgroundColor};
          }
        `}

        th {
          ${importantCSS(theme.headerMatch.table.th as CSSObject)}
        }
      `}

      ${theme.headerMatch.table.td &&
      css`
        ${theme.headerMatch.table.td.backgroundColor &&
        css`
          tr:not(:first-child) {
            background-color: ${theme.headerMatch.table.td.backgroundColor};
          }
        `}

        td {
          ${importantCSS(theme.headerMatch.table.td as CSSObject)}
        }
      `}
    `}
`
