export const hasOverflow = (el: HTMLElement, findDeepestChild: boolean): boolean => {
  let innerMostElement = el
  if (findDeepestChild) {
    while (innerMostElement.children?.length) {
      innerMostElement = innerMostElement.children[0] as HTMLElement
    }
  }
  return (
    innerMostElement &&
    (innerMostElement.scrollWidth > innerMostElement.offsetWidth ||
      innerMostElement.scrollHeight - innerMostElement.offsetHeight > 2)
  )
}
