import { gql } from 'apollo-boost'

export const PUBLIC_CREATE_UPLOAD = gql`
  mutation PublicCreateUpload(
    $licenseKey: String!
    $fileSize: Int!
    $batchId: UUID!
    $fileType: String!
    $fileName: String!
  ) {
    publicCreateUpload(
      licenseKey: $licenseKey
      fileSize: $fileSize
      batchId: $batchId
      fileType: $fileType
      fileName: $fileName
    ) {
      uploadId
      objectUrl
    }
  }
`
