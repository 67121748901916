import React, { useContext, useMemo, useRef, useState } from 'react'

import { ApolloProvider } from '@apollo/react-hooks'
import * as Sentry from '@sentry/react'
import i18next from 'i18next'
import { debug } from 'loglevel'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'
import 'whatwg-fetch'

import { LANGUAGE_WHITELIST } from './config'
import { BatchContext } from './context/batch.context'
import { EnvironmentContext } from './context/environment.context'
import { SettingsContext } from './context/settings.context'
import { Controller, STAGE } from './controller'
import { Footer, TopBar } from './fragments/control.bar'
import { DownIcon, InfoIcon, MobileNotAllowedIcon, NoEndUser } from './fragments/icons'
import { Wrapper } from './fragments/layout'
import { useDialog } from './fragments/modals'
import { ShowOnLarge, ShowOnSmall } from './fragments/responsive'
import { Batch } from './lib/batch'
import { getGraphClient } from './lib/graph.client'
import { publicSetLegacySettings } from './mutations/publicSetLegacySettings'
import { defaultTheme, GlobalStyles } from './styles'
import { createTheme, ITheme } from './theme'
import { IEnvironment } from './types/features.interface'
import { IDictionary } from './types/general.interface'
import { IBatchConfig, II18nOverrides, ISettings } from './types/settings.interface'
import { emit, eventManager, useListener, useRequest } from './utils/event.manager'
import { validateEndUser, validateSettings } from './utils/settings.helpers'

interface IApp {
  onTheme?(theme: ITheme): void
}
export const App = ({ onTheme }: IApp) => {
  const environment = useContext<IEnvironment>(EnvironmentContext)
  const legacySettingsId = useRef<string | undefined>()
  const [batch, setBatch] = useState<Batch | undefined>()
  const [batchConfig, setBatchConfig] = useState<IBatchConfig>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [translationsLoaded, setTranslationsLoaded] = useState<boolean>(false)
  const [settings, setSettings] = useState<ISettings>()
  const [showDialog, closeDialog] = useDialog()
  const [settingErrors, setSettingErrors] = useState<string | string[]>()
  const [frameHandshakeTimeoutError, setFrameHandshakeTimeoutError] = useState(false)

  const seti18nOverrides = (rawSettings: ISettings) => {
    const detectedLanguage = i18next.language
    if (!LANGUAGE_WHITELIST.includes(detectedLanguage)) {
      const baseLanguage = (detectedLanguage || '').substr(0, 2)
      if (LANGUAGE_WHITELIST.includes(baseLanguage)) {
        i18next.setDefaultNamespace(baseLanguage)
      }
    }
    const i18nOverrides = rawSettings.i18nOverrides || {}
    const overrideLanguages = Object.keys(rawSettings.i18nOverrides || {})

    const isNewOverrideFormat = (
      config: IDictionary<string> | II18nOverrides
    ): config is II18nOverrides => {
      return typeof config.overrides === 'object'
    }
    overrideLanguages.forEach((lang) => {
      if (lang !== detectedLanguage) {
        i18next.loadLanguages(lang).then()
      }
      const i18nLocaleOverrides = i18nOverrides[lang]
      if (isNewOverrideFormat(i18nLocaleOverrides)) {
        const { overrides, otherLocales } = i18nLocaleOverrides
        // @deprecate setLanguage in i18nOverrides
        if (i18nLocaleOverrides.setLanguage) {
          i18next.changeLanguage(i18nLocaleOverrides.setLanguage)
        }
        delete overrides.poweredBy
        if (typeof otherLocales === 'object') {
          otherLocales.forEach((altLanguage: string) => {
            i18next.addResourceBundle(altLanguage, 'translation', overrides, true, true)
          })
        }
        i18next.addResourceBundle(lang, 'translation', overrides, true, true)
      } else {
        delete i18nLocaleOverrides.poweredBy
        i18next.addResourceBundle(lang, 'translation', i18nOverrides[lang], true, true)
      }
    })
  }

  const settingsRequestExpired = useMemo(
    () =>
      setTimeout(() => {
        setFrameHandshakeTimeoutError(true)
      }, 5000),
    []
  )

  useRequest(
    'settings',
    async (rawSettings) => {
      clearTimeout(settingsRequestExpired)
      const { valid, errors } = validateSettings(rawSettings)
      if (valid) {
        seti18nOverrides(rawSettings)
        i18next.on('loaded', () => {
          if (!translationsLoaded) {
            seti18nOverrides(rawSettings)
            setTranslationsLoaded(true)
          }
        })
        if (!environment.features?.customFonts) {
          // only for enterprise customers
          delete rawSettings.integrations?.fonts
        }
        if (
          rawSettings.theme ||
          rawSettings.integrations?.fonts?.length ||
          rawSettings.fullScreen
        ) {
          /**
           * @deprecated
           * styleOverrides should be ignored if `theme` is specified
           */
          delete rawSettings.styleOverrides
          onTheme?.(
            createTheme(rawSettings.theme, rawSettings.integrations?.fonts, rawSettings.fullScreen)
          )
        }
        if (rawSettings.integrations?.gtmId && environment.features?.tagManager) {
          TagManager.initialize({
            gtmId: rawSettings.integrations.gtmId
          })
        }
        setSettings({ ...rawSettings })
        const licenseKey = environment.license.key
        if (licenseKey) {
          const result = await publicSetLegacySettings({ licenseKey, settings: rawSettings })
          if (result.id) {
            legacySettingsId.current = result.id
            setBatchConfig((latestBatchConfig) => ({
              ...latestBatchConfig,
              legacySettingsId: legacySettingsId.current
            }))
          }
        }
      } else if (errors) {
        setSettingErrors(errors)
      }
    },
    []
  )

  useListener(
    'do/open',
    (event) => {
      const { features } = environment
      setBatchConfig({
        ...event.payload,
        developmentMode: features.developmentMode,
        features,
        ...(legacySettingsId.current ? { legacySettingsId: legacySettingsId.current } : {})
      })
      setIsOpen(true)
      emit('open', true)
    },
    []
  )

  useListener(
    'do/close',
    () => {
      setIsOpen(false)
      setBatchConfig(undefined)
      setBatch(undefined)

      eventManager.removeAllListeners('record:change')
      eventManager.removeAllListeners('record:init')
      eventManager.removeAllListeners('record:init:batch')
      eventManager.removeAllListeners('field:init')

      emit('open', false)
    },
    []
  )

  useListener('do/dialog:display', ({ payload: { message, type } }) => {
    debug('SHOW DIALOG', message, type)
    switch (type) {
      case 'success':
        showDialog('success', {
          message,
          onConfirm() {
            emit('do/close')
          }
        })
        break
      case 'progress':
        showDialog('progress', { message })
        break
      case 'error':
        showDialog('error', {
          message
        })
        break
      default:
        closeDialog()
    }
  })

  useListener('do/setLanguage', ({ payload: { lang } }) => {
    if (!LANGUAGE_WHITELIST.includes(lang)) {
      // tslint:disable-next-line: no-console
      console.error(
        `Portal Error (setLanguage): "${lang}" is not a valid language.\nAvailable languages: ${LANGUAGE_WHITELIST.map(
          (v) => `"${v}"`
        ).join(', ')}`
      )
      return
    }

    i18next.changeLanguage(lang)
  })

  const { t } = useTranslation()

  if (batchConfig && !validateEndUser(batchConfig.endUser)) {
    // TODO: simplify to helper component
    return (
      <Wrapper>
        <TopBar title='Missing configuration' stage={STAGE.INITIAL} />
        <div style={{ textAlign: 'center', padding: '0 40px 40px 40px', fontSize: '18px' }}>
          <div style={{ padding: '40px 0' }}>
            <NoEndUser style={{ maxWidth: '105px', fill: defaultTheme.errorColor }} />
          </div>
          {t('errors.identifyUser')}
        </div>
        <Footer />
      </Wrapper>
    )
  }
  if (frameHandshakeTimeoutError) {
    return (
      <Wrapper>
        <TopBar title='Error' stage={STAGE.INITIAL} />
        <div style={{ textAlign: 'center', padding: '0 40px 40px 40px', fontSize: '18px' }}>
          <div style={{ padding: '40px 0' }}>
            <DownIcon style={{ maxWidth: '105px', fill: defaultTheme.errorColor }} />
          </div>
          Unable to connect to the parent frame
        </div>
        <Footer />
      </Wrapper>
    )
  }
  if (!environment.license.key) {
    // TODO: simplify to helper component
    return (
      <Wrapper>
        <TopBar title='Error' stage={STAGE.INITIAL} />
        <div style={{ textAlign: 'center', padding: '0 40px 40px 40px', fontSize: '18px' }}>
          <div style={{ padding: '40px 0' }}>
            <DownIcon style={{ maxWidth: '105px', fill: defaultTheme.errorColor }} />
          </div>
          {t('errors.configuration')}
        </div>
        <Footer />
      </Wrapper>
    )
  }
  if (environment.deactivated && settings) {
    return (
      <SettingsContext.Provider value={{ ...settings }}>
        <Wrapper>
          <TopBar stage={STAGE.INITIAL} />
          <div style={{ textAlign: 'center', padding: '0 40px 40px 40px', fontSize: '18px' }}>
            <div style={{ padding: '40px 0' }}>
              <InfoIcon style={{ maxWidth: '105px', fill: defaultTheme.errorColor }} />
            </div>
            {t('errors.featureDisabled')}
          </div>
          <Footer />
        </Wrapper>
      </SettingsContext.Provider>
    )
  }
  if (settings && isOpen && batchConfig) {
    const tags: { [key: string]: string } = {
      ['conf/hasWebhookUrl']:
        typeof settings.webhookUrl === 'string' && settings.webhookUrl.length > 0 ? 'true' : 'false'
    }

    Sentry.setUser({ id: environment.license.key })

    Object.keys(settings).forEach((key) => {
      if (typeof settings[key as keyof ISettings] === 'boolean') {
        tags[`conf/${key}`] = settings[key as keyof ISettings] ? 'true' : 'false'
      }
    })

    Sentry.setTags(tags)

    return (
      <ApolloProvider client={getGraphClient()}>
        <SettingsContext.Provider value={{ ...settings }}>
          <BatchContext.Provider value={{ batch, setBatch }}>
            <GlobalStyles styles={settings.styleOverrides || {}} />
            {settings.integrations?.adobeFontsWebProjectId && (
              <link
                rel='stylesheet'
                type='text/css'
                href={`https://use.typekit.net/${settings.integrations.adobeFontsWebProjectId}.css`}
              />
            )}
            {settings.integrations?.googleFonts && (
              <React.Fragment>
                <link rel='preconnect' href='https://fonts.gstatic.com' />
                <link
                  href={`https://fonts.googleapis.com/css2?family=${settings.integrations.googleFonts}&display=swap`}
                  rel='stylesheet'
                />
              </React.Fragment>
            )}
            <ShowOnSmall>
              <TopBar stage={STAGE.INITIAL} />
              <div style={{ textAlign: 'center', padding: '0 40px 40px 40px', fontSize: '18px' }}>
                <div style={{ padding: '40px 0' }}>
                  <MobileNotAllowedIcon style={{ width: '55px' }} />
                </div>
                {t('errors.smallDevice')}
              </div>
              <Footer />
            </ShowOnSmall>
            <ShowOnLarge>
              <Controller batchConfig={batchConfig} />
            </ShowOnLarge>
          </BatchContext.Provider>
        </SettingsContext.Provider>
      </ApolloProvider>
    )
  } else if (settingErrors && settingErrors.length) {
    // TODO: simplify to helper component
    return (
      <Wrapper>
        <TopBar title='⚠️ Your settings are invalid' stage={STAGE.INITIAL} />
        <div style={{ padding: '0 40px' }}>
          <pre style={{ fontFamily: 'monospace' }}>
            {Array.isArray(settingErrors) ? settingErrors.join('\n\n') : settingErrors}
          </pre>
        </div>
        <Footer />
      </Wrapper>
    )
  } else {
    return (
      <Wrapper className='presettings-default' style={{ padding: '100px' }}>
        <div className='loading-indicator'>
          <div className='spinner-new'>
            <div className='dot1' />
            <div className='dot2' />
          </div>
          <h1 className='sr-only'>{'Loading...'}</h1>
        </div>
      </Wrapper>
    )
  }
}
App.displayName = 'App'
