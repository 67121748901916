import React from 'react'

interface ILogoProps {
  fill?: string
}

export const WordMark: React.FC<ILogoProps> = ({}) => (
  <div>
    <svg
      className='flatfile-icon-svg'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 272.53 273.3'
      height='16'
    >
      <path
        className='flatfile-icon'
        d='M131,212.78a17.47,17.47,0,0,1-12.34,5.11H76.83a13.11,13.11,0,0,1-13.1-13.1V76.44a13.11,13.11,0,0,1,13.1-13.09h76.63a13.11,13.11,0,0,1,13.1,13.09V170a17.42,17.42,0,0,1-5.12,12.34l-15.22,15.22Z'
      />
      <path
        className='flatfile-icon'
        d='M186.85,130.62a17.44,17.44,0,0,1-5.12-12.34V76.44a13.11,13.11,0,0,1,13.09-13.09H323.17a13.11,13.11,0,0,1,13.1,13.09v76.64a13.09,13.09,0,0,1-13.1,13.09H229.63a17.41,17.41,0,0,1-12.34-5.11l-15.23-15.22Z'
      />
      <path
        className='flatfile-icon'
        d='M269,187.23a17.44,17.44,0,0,1,12.35-5.12h41.82a13.09,13.09,0,0,1,13.1,13.1V323.56a13.09,13.09,0,0,1-13.1,13.09H246.54a13.11,13.11,0,0,1-13.1-13.09V230a17.42,17.42,0,0,1,5.12-12.34l15.22-15.23Z'
      />
      <path
        className='flatfile-icon'
        d='M213.15,269.38a17.44,17.44,0,0,1,5.12,12.35v41.83a13.11,13.11,0,0,1-13.09,13.09H76.83a13.11,13.11,0,0,1-13.1-13.09V246.92a13.11,13.11,0,0,1,13.1-13.1h93.54a17.46,17.46,0,0,1,12.34,5.12l15.23,15.22Z'
      />
    </svg>
    <span className='flatfile-text'>Flatfile</span>
  </div>
)
WordMark.displayName = 'WordMark'
