import { getAsyncGraphClient } from '../lib/graph.client'
import { PUBLIC_SUBMIT_ROWS } from '../queries/PUBLIC_SUBMIT_ROWS'
import { PublicSubmitRows, PublicSubmitRowsVariables } from '../queries/types/PublicSubmitRows'

export const publicSubmitRows = async (variables: PublicSubmitRowsVariables): Promise<boolean> => {
  const graphClient = await getAsyncGraphClient({
    operation: 'PUBLIC_SUBMIT_ROWS',
    variables
  })
  const mutationResponse = await graphClient.mutate<PublicSubmitRows, PublicSubmitRowsVariables>({
    mutation: PUBLIC_SUBMIT_ROWS,
    variables
  })
  if (mutationResponse && mutationResponse.data) {
    return mutationResponse.data.publicSubmitRows.success
  }
  return false
}
