import React, { useState } from 'react'

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translation, useTranslation } from 'react-i18next'

import { MAX_SELECT_OPTIONS } from '../config'
import { MatchTable } from '../fragments/tables'
import { Batch } from '../lib/batch'
import { TooManyUniquesError } from '../lib/file.parser'
import { IRule, RULE_STATUS } from '../lib/recipe'
import { convertToLetters } from '../utils/functions'
import { ConfirmMap } from './confirm.map'
import { MatchOptionsTable } from './match.options.table'
import { TopRow } from './top.row'

type ColBodyProps = {
  batch: Batch
  rule: IRule
  onChange(): void
}

type ContentRowProps = {
  index?: number
  data?: string
}

export const ColBody: React.FC<ColBodyProps> = ({
  batch,
  rule: ruleInit,
  onChange: onChangeParent
}: ColBodyProps) => {
  const [rule, setRule] = useState(ruleInit)
  const { t } = useTranslation()
  const { sourceIndex, status } = rule
  const parser = batch.getParser()
  const recipe = batch.recipe
  const onChange = () => {
    setRule(recipe.refreshRule(rule))
    onChangeParent()
  }
  const field = recipe.getFieldSettings(sourceIndex)
  if (status === RULE_STATUS.PENDING) {
    const previewData = parser.previewColumnData(sourceIndex, 3)
    const contentrows = [0, 1, 2].map((i) => {
      const [rowPreview, rowIndex] = previewData[i] || [undefined, undefined]
      return <ContentRow index={rowIndex} data={rowPreview} key={`cr-${sourceIndex}-${i}`} />
    })

    let matchOptionsTable = null
    if (field && field.type === 'select') {
      try {
        const sourceValues = parser.getUniqueColumnValues(sourceIndex, MAX_SELECT_OPTIONS)
        matchOptionsTable = (
          <MatchOptionsTable
            batch={batch}
            sourceValues={sourceValues}
            rule={rule}
            field={field}
            onChange={onChange}
          />
        )
      } catch (e) {
        if (e instanceof TooManyUniquesError) {
          matchOptionsTable = (
            <div className='column' style={{ maxWidth: '20%' }}>
              <div className='match-options-table-container'>
                <FontAwesomeIcon icon={faExclamationTriangle} className='warningColor' />{' '}
                {t('errors.tooManySourceValues')}
              </div>
            </div>
          )
        } else {
          throw e
        }
      }
    }

    return (
      <div className='col-body primaryTextColor'>
        <div className='column'>
          <MatchTable className='match-table--pending'>
            <TopRow rule={rule} batch={batch} onChange={onChange} />
            <tbody>{contentrows}</tbody>
          </MatchTable>
        </div>
        {matchOptionsTable}
        <div className='column'>
          <ConfirmMap rule={rule} batch={batch} onChange={onChange} />
        </div>
      </div>
    )
  } else if (status === RULE_STATUS.IGNORED) {
    return (
      <div className='col-body column-collapsed column-ignored'>
        <div className='column'>
          <div className='ignored-table' data-testid='col-body__ignored-table'>
            <span>{convertToLetters(sourceIndex + 1)}</span>
            <span>{recipe.readableSourceName(rule)}</span>
          </div>
        </div>
        <div className='column'>
          <ConfirmMap rule={rule} batch={batch} onChange={onChange} />
        </div>
      </div>
    )
  } else {
    return (
      <div className='col-body column-collapsed'>
        <div className='column'>
          <table className='match-table--confirmed'>
            <TopRow rule={rule} batch={batch} onChange={onChange} />
          </table>
        </div>
        <div className='column'>
          <ConfirmMap rule={rule} batch={batch} onChange={onChange} />
        </div>
      </div>
    )
  }
}
ColBody.displayName = 'ColBody'

const ContentRow = ({ index, data }: ContentRowProps) => (
  <Translation ns='translation'>
    {(t) => (
      <tr>
        <th>{typeof index === 'number' ? index + 1 : '-'}</th>
        <td colSpan={2}>{data || <i>{t('noData2')}</i>}</td>
      </tr>
    )}
  </Translation>
)
ContentRow.displayName = 'ContentRow'
