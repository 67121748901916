import { getAsyncGraphClient } from '../lib/graph.client'
import { PUBLIC_UPLOAD_DATA_SOURCE } from '../queries/PUBLIC_UPLOAD_DATA_SOURCE'
import {
  PublicUploadDataSource,
  PublicUploadDataSourceVariables,
  PublicUploadDataSource_publicUploadDataSource
} from '../queries/types/PublicUploadDataSource'

export const publicUploadDataSource = async (
  variables: PublicUploadDataSourceVariables
): Promise<PublicUploadDataSource_publicUploadDataSource | undefined> => {
  const graphClient = await getAsyncGraphClient({
    operation: 'PUBLIC_UPLOAD_DATA_SOURCE',
    variables
  })
  try {
    const mutationResponse = await graphClient.mutate<
      PublicUploadDataSource,
      PublicUploadDataSourceVariables
    >({
      mutation: PUBLIC_UPLOAD_DATA_SOURCE,
      variables
    })
    if (mutationResponse && mutationResponse.data) {
      return mutationResponse.data.publicUploadDataSource
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }
  return undefined
}
