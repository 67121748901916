import React, { FC } from 'react'

import { createGlobalStyle, css } from 'styled-components'
import tinycolor from 'tinycolor2'

import { IStyleOverrides } from './types/settings.interface'

interface ICustomStylesProps {
  styles: IStyleOverrides
}

export const defaultTheme = {
  spacing: '40px',
  cloudyBlue: '#c6d1dd',
  blue: '#4a90e2',
  secondaryTextColor: '#667D8D',
  errorColor: '#d0011b', // $scarlet
  backgroundColor: '#EAEFF5',
  borderRadius: '4px',
  buttonHeight: '44px',
  containerBorder: 'none',
  containerBorderTop: 'none',
  containerBorderRight: 'none',
  containerBorderLeft: 'none',
  containerBorderBottom: 'none',
  containerBorderImage: 'none',
  primaryButtonColor: '#3B2FC9',
  primaryButtonFontSize: '1.6rem',
  primaryButtonFontColor: '#fff',
  uploadButtonBackground: null,
  uploadButtonPadding: null,
  secondaryButtonColor: '#8b99a4',
  secondaryButtonFontSize: '1.6rem',
  secondaryButtonFontColor: '#fff',
  primaryButtonBorderColor: null,
  secondaryButtonBorderColor: null,
  uploadButtonColor: null,
  uploadButtonFontSize: null,
  uploadButtonFontColor: null,
  uploadButtonBorderColor: null,
  noButtonColor: null,
  noButtonBorderColor: null,
  noButtonFontColor: null,
  yesButtonColor: null,
  yesButtonBorderColor: null,
  yesButtonFontColor: '#fff',
  yesButtonBackground: null,
  yesButtonPadding: null,
  finalButtonSuccessColor: null,
  finalButtonSuccessBorderColor: null,
  finalButtonSuccessFontColor: null,
  finalButtonErrorColor: null,
  finalButtonErrorBorderColor: null,
  finalButtonErrorFontColor: null,
  invertedButtonColor: '#667d8d',
  linkColor: '#c6d1dd', // $cloudy-blue
  linkAltColor: '#4a90e2', // $blue
  primaryTextColor: '#1D2830', // $gunmetal
  successColor: '#60b700', // $kermit-green
  warningColor: '#f6A623', // $orange
  borderColor: '#e8e8e8', // $snowman
  fontFamily: null
}

export type IButtonTheme = Partial<{
  [key in keyof typeof defaultTheme]: string | null | undefined
}>

interface IButtonStylings {
  className: string
  backgroundColor?: string | null
  color?: string | null
  border?: string | null
  background?: string | null
  padding?: string | null
  fontSize?: string | null
  hoverColor?: boolean
  hoverBackgroundColor?: boolean
  hoverLight?: boolean
}

export const generateButtonStylings = ({
  className,
  backgroundColor,
  color,
  border,
  background,
  padding,
  fontSize,
  hoverColor = false,
  hoverBackgroundColor = true,
  hoverLight = false
}: IButtonStylings) => {
  return css`
    ${className} {
      ${backgroundColor && `background-color: ${backgroundColor};`}
      ${color && `color: ${color};`}
      ${border && `border: 1px solid ${border};`}
      ${fontSize && `font-size: ${fontSize};height: auto;`}
      ${background && `background: ${background};`}
      ${padding && `padding: ${padding};`}
    }

    ${className}:focus, ${className}:hover {
      ${hoverBackgroundColor &&
      backgroundColor &&
      `background-color: ${tinycolor(backgroundColor)
        .darken(hoverLight ? 10 : 20)
        .toString()};`}
      ${hoverColor &&
      color &&
      `color: ${tinycolor(color)
        .darken(hoverLight ? 10 : 20)
        .toString()};`}
      ${border &&
      `border-color: ${tinycolor(border)
        .darken(hoverLight ? 10 : 20)
        .toString()};`}
    }

    ${className}:active {
      ${hoverBackgroundColor &&
      backgroundColor &&
      `background-color: ${tinycolor(backgroundColor)
        .darken(hoverLight ? 20 : 30)
        .toString()};`}
      ${hoverColor &&
      color &&
      `color: ${tinycolor(color)
        .darken(hoverLight ? 20 : 30)
        .toString()};`}
      ${border &&
      `border-color: ${tinycolor(border)
        .darken(hoverLight ? 20 : 30)
        .toString()};`}
    }
  `
}

export const getButtonStyles = (styles: IButtonTheme) => ({
  '.primary': {
    backgroundColor: styles.primaryButtonColor,
    color: styles.primaryButtonFontColor,
    border: styles.primaryButtonBorderColor || styles.primaryButtonColor,
    fontSize: styles.primaryButtonFontSize
  },
  '.secondary': {
    backgroundColor: styles.secondaryButtonColor,
    color: styles.secondaryButtonFontColor,
    border: styles.secondaryButtonBorderColor || styles.secondaryButtonColor,
    fontSize: styles.secondaryButtonFontSize
  },
  '.invert': {
    color: styles.invertedButtonColor,
    border: styles.invertedButtonColor,
    hoverColor: true
  },
  '.success': {
    backgroundColor: styles.yesButtonColor || styles.successColor,
    color: styles.yesButtonFontColor,
    border: styles.yesButtonBorderColor || styles.yesButtonColor || styles.successColor,
    hoverLight: true
  },
  '.upload-button': {
    backgroundColor: styles.uploadButtonColor,
    color: styles.uploadButtonFontColor,
    border: styles.uploadButtonBorderColor || styles.uploadButtonColor,
    fontSize: styles.uploadButtonFontSize,
    background: styles.uploadButtonBackground,
    padding: styles.uploadButtonPadding
  },
  '.yes-button': {
    backgroundColor: styles.yesButtonColor,
    color: styles.yesButtonFontColor,
    border: styles.yesButtonBorderColor || styles.yesButtonColor,
    background: styles.yesButtonBackground,
    padding: styles.yesButtonPadding
  },
  '.header-match-no': {
    backgroundColor: 'transparent',
    color: styles.successColor,
    border: styles.successColor,
    hoverColor: true
  },
  '.no-button:not(.invert)': {
    backgroundColor: styles.noButtonColor || styles.primaryTextColor,
    color: styles.noButtonFontColor,
    border: styles.noButtonBorderColor || styles.noButtonColor || styles.primaryTextColor
  },
  '.invert.no-button': {
    color: styles.noButtonColor,
    border: styles.noButtonBorderColor || styles.noButtonColor,
    hoverColor: true
  },
  '.final-success': {
    backgroundColor: styles.finalButtonSuccessColor,
    color: styles.finalButtonSuccessFontColor,
    border: styles.finalButtonSuccessBorderColor || styles.finalButtonSuccessColor
  },
  '.final-error': {
    backgroundColor: styles.finalButtonErrorColor,
    color: styles.finalButtonErrorFontColor,
    border: styles.finalButtonErrorBorderColor || styles.finalButtonErrorColor
  }
})

export const GlobalStyles: FC<ICustomStylesProps> = (props) => {
  const styles = { ...defaultTheme, ...props.styles }

  const buttons = getButtonStyles(styles)

  const CustomStyles = createGlobalStyle`
    
    .flatfile-root {
      border: ${styles.containerBorder};
      border-top: ${styles.containerBorderTop};
      border-left: ${styles.containerBorderLeft};
      border-right: ${styles.containerBorderRight};
      border-bottom: ${styles.containerBorderBottom};
      border-image: ${styles.containerBorderImage};
    }
  
    ${
      styles.fontFamily &&
      css`
        body {
          font-family: ${styles.fontFamily};
        }
      `
    }
    
    .loading-line {
      background-color: ${styles.primaryButtonColor} !important;
      box-shadow: 0 1px 2px #000;
      transition: all .25s ease;
    }

    .borderRadius, .button, .flatfile-root, .flatfile-modal, .secondary-loader {
      border-radius: ${styles.borderRadius};
    }

    ${Object.entries(buttons).map(([className, stylings]) =>
      generateButtonStylings({ className: `.button${className}`, ...stylings })
    )}

    .button {
      height: auto;
      transition: all .25s ease;
    }

    .button.gray {
      background-color: ${styles.backgroundColor};
      color: ${styles.secondaryTextColor};
    }

    .button.gray:focus, .button.gray:hover, .button.gray:active {
      background-color: ${tinycolor(styles.borderColor).darken(5).toString()};
    }

    .linkColor {
      color: ${styles.linkColor};
    }

    .linkColor:hover, .linkColor:active, .linkColor:focus {
      color: ${styles.linkAltColor};
    }

    .primaryTextColor {
      color: ${styles.primaryTextColor};
    }
    .handsontable thead th, .handsontable tbody th, .Select .Select-value-label {
      color: ${styles.primaryTextColor} !important;
    }

    .secondaryTextColor {
      color: ${styles.secondaryTextColor};
    }
    .handsontable .htDimmed {
      color: ${styles.secondaryTextColor} !important;
    }

    .errorColor {
      color: ${styles.errorColor};
    }

    .successColor {
      color: ${styles.successColor};
    }

    .warningColor {
      color: ${styles.warningColor};
    }

    .borderColor {
      border-color: ${styles.borderColor};
    }

    .fontFamily {
      font-family: ${styles.fontFamily};
    }

    .col-required:after {
      background-color: ${styles.successColor};
    }

    .check-toggle[data-line="true"] {
      border-color: ${styles.successColor};
      background-color: ${styles.successColor};
    }
    .check-toggle[data-line="true"]:after {
      border-color: ${styles.successColor};
    }
    .check-toggle[data-line="true"]:before {
      background-color: ${styles.successColor};
    }

    .fa-check-square:hover{
      color: ${styles.successColor};
    }

    .col-body .column-unmatched .fa, .fa-warning {
      color: ${styles.warningColor};
    }

    .progress-current, .primary-header, .secondary-header {
      color: ${styles.primaryTextColor};
    }

    .handsontable td.htInvalid {
      background-color: ${tinycolor.mix(styles.errorColor, 'white', 90).toString()} !important;
      border-color: ${tinycolor.mix(styles.errorColor, 'white', 80).toString()} !important;
      color: ${styles.errorColor} !important;
    }
    .col-body aside.duplicate {
      border-color: ${styles.errorColor};
    }
    .col-body .duplicate-warning, .col-body .column-ignored .fa, .col-body .column-locked .fa, .parse-error-tables .fa-trash:hover {
      color: ${styles.errorColor};
    }
    .header-match-banner{
      border-color: ${styles.successColor};
    }

    .handsontableInputHolder.ht_editor_visible {
      overflow: visible;
    }
  `
  return <CustomStyles />
}
