import React, { useCallback, useState } from 'react'

import { useModal } from 'react-modal-hook'

import { ErrorModal } from '../fragments/modals'

export const useErrorModal = () => {
  const [message, setMessage] = useState<string>('')
  const [showModal, hideModal] = useModal(
    () => <ErrorModal msg={message} onDismiss={hideModal} />,
    [message]
  )

  const showErrorModal = useCallback((msg) => {
    setMessage(msg)
    showModal()
  }, [])

  return [showErrorModal, hideModal]
}
