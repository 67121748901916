import React from 'react'

import ReactTooltip from 'react-tooltip'

interface ITooltip {
  id: string
  content: string | React.ReactNode
  offset?: { [key: string]: number }
  disabled?: boolean
}

export const Tooltip = ({
  id,
  content,
  offset = { top: 10, left: 10 },
  disabled = false
}: ITooltip) => {
  if (disabled) {
    return null
  }
  return (
    <ReactTooltip
      backgroundColor='#1D2830'
      id={id}
      place='top'
      type='dark'
      effect='solid'
      offset={{ top: offset.top, left: offset.left }}
      textColor='#EAEFF5'
    >
      {content}
    </ReactTooltip>
  )
}
