import React, { CSSProperties, useCallback, useContext } from 'react'

import { get, pick } from 'lodash'
import { Styles } from 'react-select'
import { ThemeContext } from 'styled-components'

const defaultStylesOverrides: {
  [P in keyof Styles]: CSSProperties
} = {
  control: {
    borderWidth: 0,
    boxShadow: 'none',
    borderRadius: 0,
    zIndex: 12
  },
  option: {
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  menu: {
    marginTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  }
}

interface ISelectStyles {
  themePath: string | string[]
  customFieldIdentifier?: string
  maxMenuHeight?: number
  defaultStyles?: {
    [P in keyof Styles]: CSSProperties
  }
}

export const useSelectStyles = ({
  customFieldIdentifier,
  maxMenuHeight = 300,
  defaultStyles = defaultStylesOverrides,
  themePath
}: ISelectStyles) => {
  const theme = useContext(ThemeContext)
  const cellRef = React.useRef<HTMLTableHeaderCellElement>(null)

  if (cellRef?.current) {
    const bottomOffset =
      document.body.clientHeight -
      cellRef.current!.getBoundingClientRect().bottom -
      44 -
      window.scrollY

    if (bottomOffset < maxMenuHeight) {
      maxMenuHeight = bottomOffset > 110 ? bottomOffset : 110
    }
  }

  const getElementStyles = useCallback(
    (props = [], focused = false, selected = false) => {
      const themeStyles = (Array.isArray(themePath) ? themePath : [themePath]).reduce(
        (acc, item) => {
          return Object.assign(acc, get(theme, item) || {})
        },
        {} as Record<string, string>
      )

      let elStyles = props.length ? pick(themeStyles, props) : themeStyles

      if (focused) {
        elStyles = Object.assign(elStyles, themeStyles[':focus'])
      }

      if (selected) {
        elStyles = Object.assign(elStyles, themeStyles[':selected'])
      }

      return elStyles
    },
    [theme, themePath]
  )

  const styles: Partial<Styles> = {
    control(provided) {
      return {
        ...provided,
        ...(defaultStyles.control || {}),
        backgroundColor: theme?.global?.backgroundColor || provided.backgroundColor,
        ...getElementStyles()
      }
    },
    option(provided, state) {
      if (state.isDisabled) {
        provided.backgroundColor = '#fff'
        provided.color = 'inherit'
        provided.opacity = 0.35
        provided.cursor = 'not-allowed'
      }

      // styles for `Create custom field "test"` button
      if (state.data?.__isNew__ === true) {
        return {
          ...provided,
          ...(defaultStyles.option || {}),
          ':active': {
            backgroundColor: 'transparent!important'
          },
          backgroundColor: theme?.global?.backgroundColor || 'white!important',
          ...getElementStyles(['backgroundColor', 'color'])
        }
      }

      // styles for `Include as custom field` button
      if (customFieldIdentifier && state.value === customFieldIdentifier) {
        if (!!state.selectProps?.inputValue) {
          return {
            display: 'none'
          }
        }

        return {
          ...provided,
          ...(defaultStyles.option || {}),
          ':active': {
            backgroundColor: 'transparent!important'
          },
          backgroundColor: theme?.global?.backgroundColor || 'white!important',
          ...getElementStyles(['backgroundColor', 'color'], state.isFocused, state.isSelected),
          pointerEvents: 'none',
          ...(maxMenuHeight > 200 // custom button is floating
            ? { position: 'sticky', bottom: 0 }
            : {})
        }
      }
      return {
        ...provided,
        ...(defaultStyles.option || {}),
        backgroundColor: theme?.global?.backgroundColor || provided.backgroundColor,
        color:
          (state.isSelected ? theme?.global?.primaryTextColor : theme?.global?.textColor) ||
          provided.color,
        ...getElementStyles(
          ['backgroundColor', 'color', ':active'],
          state.isFocused,
          state.isSelected
        )
      }
    },
    menu(provided) {
      return {
        ...provided,
        ...(defaultStyles.menu || {}),
        backgroundColor: theme?.global?.backgroundColor || provided.backgroundColor,
        ...getElementStyles(['backgroundColor'])
      }
    },
    menuList(provided) {
      return {
        ...provided,
        backgroundColor: theme?.global?.backgroundColor || provided.backgroundColor,
        ...getElementStyles(['backgroundColor']),
        maxHeight: maxMenuHeight,
        ...(maxMenuHeight > 200 ? { paddingBottom: 0 } : {}) // custom button is floating
      }
    },
    singleValue(provided) {
      return {
        ...provided,
        color: theme?.global?.primaryTextColor || provided.color,
        ...getElementStyles(['color'])
      }
    },
    dropdownIndicator(provided) {
      return {
        ...provided,
        color: theme?.global?.primaryTextColor || provided.color,
        ...getElementStyles(['color'])
      }
    },
    input(provided) {
      return {
        ...provided,
        color: theme?.global?.primaryTextColor || provided.color,
        ...getElementStyles(['color'])
      }
    }
  }

  return {
    cellRef,
    styles
  }
}
