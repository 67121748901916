import React, { RefObject, useEffect, useRef, useState } from 'react'

import styled from 'styled-components'

import { hasOverflow } from '../lib/hasOverflow'
import { Tooltip } from './Tooltip'

const StyledSpan = styled.span<{ maxWidth?: number }>`
  display: inline-block;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  overflow: hidden;
  text-overflow: ellipsis;
`

export const OverflowData = ({
  children,
  dataFor = '',
  isSpan = false,
  maxWidth = 150,
  offset
}: {
  children: React.ReactElement
  dataFor?: string
  dataTip?: string // deprecated
  isSpan?: boolean
  maxWidth?: number
  offset?: { [key: string]: number }
}) => {
  const dataRef = useRef<HTMLElement>()
  const [dataTip, setDataTip] = useState('')
  const [showTooltip, setShowTooltip] = useState(false)
  useEffect(() => {
    // @ts-ignore
    dataRef.current = hasOverflow(dataRef.current, !isSpan)
    setShowTooltip(dataRef.current as unknown as boolean)

    if (dataRef.current) {
      setDataTip(Math.random().toString(36))
    }
  }, [dataFor, dataRef])

  return isSpan ? (
    <StyledSpan
      {...(dataTip ? { 'data-tip': dataTip, 'data-for': dataTip } : {})}
      maxWidth={maxWidth}
      ref={dataRef as unknown as RefObject<HTMLSpanElement>}
    >
      {React.cloneElement(children, { ref: dataRef })}
      {showTooltip && <Tooltip id={dataTip} content={dataFor} offset={offset} />}
    </StyledSpan>
  ) : (
    <div
      {...(dataTip ? { 'data-tip': dataTip, 'data-for': dataTip } : {})}
      ref={dataRef as unknown as RefObject<HTMLDivElement>}
    >
      {React.cloneElement(children, { ref: dataRef })}
      {showTooltip && <Tooltip id={dataTip} content={dataFor} offset={offset} />}
    </div>
  )
}
