import { getAsyncGraphClient } from '../lib/graph.client'
import { PUBLIC_UPDATE_UPLOAD_STATUS } from '../queries/PUBLIC_UPDATE_UPLOAD_STATUS'
import {
  PublicUpdateUploadStatus,
  PublicUpdateUploadStatusVariables
} from '../queries/types/PublicUpdateUploadStatus'

export const publicUpdateUploadStatus = async (
  variables: PublicUpdateUploadStatusVariables
): Promise<{ success: boolean }> => {
  const graphClient = await getAsyncGraphClient({
    operation: 'PUBLIC_UPDATE_UPLOAD_STATUS',
    variables
  })
  try {
    const mutationResponse = await graphClient.mutate<
      PublicUpdateUploadStatus,
      PublicUpdateUploadStatusVariables
    >({
      mutation: PUBLIC_UPDATE_UPLOAD_STATUS,
      variables
    })
    if (mutationResponse && mutationResponse.data) {
      return mutationResponse.data.publicUpdateUploadStatus
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }
  return { success: false }
}
