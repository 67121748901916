import React from 'react'

import { useTranslation } from 'react-i18next'

import { ColBody } from '../components/col.body'
import { STAGE } from '../controller'
import { Button, SecondaryButton } from '../fragments/button'
import { Footer, TopBar } from '../fragments/control.bar'
import { Batch } from '../lib/batch'
import { StyledMatchWrapper } from '../theme'

const fallback = () => false

function MatchViewDemo({ batch }: { batch: Batch }) {
  const { t } = useTranslation()
  const rules = batch.view.recipe.getRules()

  return (
    <StyledMatchWrapper className='column-match-stage'>
      <TopBar stage={STAGE.MATCH} />
      <div className='column-edit-table'>
        {rules.map((rule) => (
          <ColBody key={`col-${rule.sourceIndex}`} rule={rule} batch={batch} onChange={fallback} />
        ))}
      </div>
      <Footer>
        <SecondaryButton title={t('buttons.back')} onClick={fallback} />
        <Button title={t('buttons.review')} onClick={fallback} />
      </Footer>
    </StyledMatchWrapper>
  )
}

export default MatchViewDemo
