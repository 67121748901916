import React, { Component } from 'react'

import LoadingBar from 'react-top-loading-bar'
import { withTheme } from 'styled-components'

import { ITheme } from '../theme'
import { IIteratorPayload } from '../utils/iterators'
import { average, prettyTime } from '../utils/misc'

class LoadingLine extends Component<{ theme: ITheme }> {
  public state = {
    loadingBarProgress: 0,
    projectedTime: 0
  }

  private iterators: { [key: number]: number } = {}
  private timers: { [key: number]: number } = {}

  private progressHistory: { progress: number; timestamp: number }[] = []
  private lastCheck = performance.now()
  private snapshotTime = 0
  public render() {
    return this.state.projectedTime > 0 ? (
      <div className={`iterator-loading large-loader`}>
        <LoadingBar
          progress={this.state.loadingBarProgress}
          className={`loading-line`}
          height={4}
          color={this.props.theme?.iterator?.barColor ?? 'rgba(255,255,255,0.5)'}
          onLoaderFinished={() => this.onLoaderFinished()}
        />

        <div className='loading-label-wrapper'>
          <span className={'loading-label'}>
            {prettyTime(Math.floor(this.state.projectedTime / 1000))} remaining
          </span>
        </div>
      </div>
    ) : (
      <div />
    )
  }

  public componentDidMount() {
    window.addEventListener('progress-tick', this.handleLoaderEvent as EventListener)
  }

  public componentWillUnmount() {
    window.removeEventListener('progress-tick', this.handleLoaderEvent as EventListener)
  }

  /**
   * This event is triggered during the run of any async iterator that lasts
   * longer than the allotted silent time
   * @param iteratorId
   * @param progress
   */
  private handleLoaderEvent = ({
    detail: { iteratorId, progress }
  }: CustomEvent<IIteratorPayload>) => {
    // update our iterator index
    this.iterators[iteratorId] = progress
    if (this.timers[iteratorId]) {
      clearTimeout(this.timers[iteratorId])
    }

    // collect the average of currently running iterators
    const avg = Math.floor(average(Object.values(this.iterators)) * 10000) / 100

    this.setState({
      loadingBarProgress: avg
    })
    this.updateProgressHistory(avg)

    // once completed, we stop tracking this iterator
    if (avg === 1) {
      this.iterators = {}
    }
    if (progress !== 1) {
      // this.timers[iteratorId] = this.getEscapeTimer(iteratorId)
    }
  }

  private onLoaderFinished() {
    this.progressHistory = []
    this.iterators = {}
    this.snapshotTime = 0
    this.setState({ loadingBarProgress: 0 })
  }

  private updateProgressHistory(progress: number) {
    this.progressHistory = [...this.progressHistory, { progress, timestamp: performance.now() }]
    const ph = this.progressHistory

    if (ph.length >= 10) {
      const latest = ph.slice(ph.length - 10, ph.length)
      this.progressHistory = latest
      const start = latest[0]
      const last = latest[9]
      if (performance.now() - this.lastCheck > 2000) {
        const elapsedPercentage = last.progress - start.progress
        if (elapsedPercentage > 0) {
          const elapsedTime = last.timestamp - start.timestamp
          this.snapshotTime = (100 / elapsedPercentage) * elapsedTime
          this.lastCheck = performance.now()
        }
      }
      const projectedTime = ((100 - last.progress) / 100) * this.snapshotTime
      this.setState({ projectedTime: Math.floor(projectedTime) })
    }
  }

  /**
   * This ensures that an exception in an iterator doesn't leave the progress
   * running forever
   *
   * @param iteratorId
   */
  // private getEscapeTimer(iteratorId: number): number {
  //   return (setTimeout(() => {
  //     this.handleLoaderEvent(
  //       new CustomEvent<IIteratorPayload>('progress-tick', {
  //         detail: { iteratorId, progress: 1 }
  //       })
  //     )
  //   }, ITERATOR_MAX_SILENCE) as unknown) as number
  // }
}

export default withTheme(LoadingLine)
