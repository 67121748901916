import React from 'react'

import { Translation, useTranslation } from 'react-i18next'

import { isRawHTML, sanitizeRawHtml } from '../utils/misc'
import { Button } from './button'
import { ErrorsDiscovered } from './icons'

interface ISubmitOptions {
  errorCount: number
  rowCount: number
  allowInvalidSubmit?: boolean
  actionDisabled?: boolean
  onAction: (action: string) => void
}
export const SubmitOptions = ({
  errorCount,
  rowCount,
  allowInvalidSubmit,
  actionDisabled,
  onAction
}: ISubmitOptions) => {
  const validCount = rowCount - errorCount

  if (errorCount > 0 && allowInvalidSubmit) {
    return (
      <AllowErrorsOptions {...{ errorCount, rowCount, validCount, onAction, actionDisabled }} />
    )
  } else if (errorCount > 0 || errorCount === rowCount) {
    return (
      <ErrorsPresentOptions {...{ errorCount, rowCount, validCount, onAction, actionDisabled }} />
    )
  } else {
    return (
      <ValidSubmitOptions {...{ errorCount, rowCount, validCount, onAction, actionDisabled }} />
    )
  }
}
const AllowErrorsOptions = ({
  onAction,
  actionDisabled,
  ...props
}: Omit<ISubmitOptions, 'allowInvalidSubmit'>) => (
  <Translation ns='translation'>
    {(t) => (
      <div className='primaryTextColor'>
        <ModalHeader title='errors.unresolvedFormat' options={props} />
        <ul className='secondaryTextColor'>
          <li>
            <p>{t('reviewFix')}</p>
            <Button
              id='final-close-cancel'
              title={t('buttons.back')}
              classes={['invert', 'dialog-final-no']}
              onClick={() => onAction('cancel')}
            />
          </li>
          <li>
            <p>{t('submitAnyway')}</p>
            <Button
              id='final-close-ignore'
              title={t('buttons.continue')}
              classes={['primary', 'dialog-final-yes']}
              onClick={() => onAction('ignore')}
              disabled={actionDisabled}
            />
          </li>
        </ul>
      </div>
    )}
  </Translation>
)
const ErrorsPresentOptions = ({
  onAction,
  actionDisabled,
  ...props
}: Omit<ISubmitOptions, 'allowInvalidSubmit'>) => (
  <Translation ns='translation'>
    {(t) => (
      <div className='primaryTextColor'>
        {props.errorCount === 1 ? (
          <ModalHeader title='errors.unresolvedFormatSingular' options={props} />
        ) : (
          <ModalHeader title='errors.unresolvedFormat' options={props} />
        )}
        <ul className='secondaryTextColor'>
          <li>
            <p style={{ marginTop: 0 }}>{t('reviewFix')}</p>
            <Button
              id='final-close-cancel'
              title={t('buttons.back')}
              classes={['invert', 'dialog-final-no']}
              onClick={() => onAction('cancel')}
            />
          </li>
          {props.rowCount !== props.errorCount && (
            <li>
              <p style={{ marginTop: 0 }}>
                {props.errorCount > 1
                  ? t('errors.discardRowsWithErrors', { errorCount: props.errorCount })
                  : t('errors.discardRowWithErrors')}
              </p>
              <Button
                id='final-close-ignore'
                title={t('buttons.continue')}
                classes={['primary', 'dialog-final-yes']}
                onClick={() => onAction('ignore')}
                disabled={actionDisabled}
              />
            </li>
          )}
        </ul>
      </div>
    )}
  </Translation>
)
const ValidSubmitOptions = ({
  onAction,
  actionDisabled,
  ...props
}: Omit<ISubmitOptions, 'allowInvalidSubmit'>) => (
  <Translation ns='translation'>
    {(t) => (
      <div className='primaryTextColor'>
        <ModalHeader title='readySubmit' options={props} />
        <ul>
          <li>
            <Button
              id='final-close-cancel'
              title={t('buttons.no')}
              classes={['invert', 'dialog-final-no']}
              onClick={() => onAction('cancel')}
            />
          </li>
          <li>
            <Button
              id='final-close-include'
              title={t('buttons.yes')}
              classes={['primary', 'dialog-final-yes']}
              onClick={() => onAction('include')}
              disabled={actionDisabled}
            />
          </li>
        </ul>
      </div>
    )}
  </Translation>
)

interface IModalHeader {
  title: string
  options?: object
}
const ModalHeader = ({ title, options }: IModalHeader) => {
  const { t } = useTranslation()
  const message = t(title, options)

  if (isRawHTML(message)) {
    return (
      <div
        className='modal-header raw-html'
        dangerouslySetInnerHTML={{ __html: sanitizeRawHtml(message) }}
      />
    )
  }

  return (
    <div className='modal-header'>
      <ErrorsDiscovered style={{ width: '75px', marginRight: '30px' }} />
      <h4>{message}</h4>
    </div>
  )
}
