import { IBeforeFetchRequest } from '../types/general.interface'
import { emitAndWait, hasListeners } from '../utils/event.manager'
import { getSignedUrlHeaders } from '../utils/functions'

async function getAdditionalHeaders(): Promise<Record<string, string>> {
  if (hasListeners('fetch:before')) {
    const payload: IBeforeFetchRequest = {
      operation: 'objectStorage:upload',
      variables: {}
    }
    const { headers = {} } = (await emitAndWait('fetch:before', payload)) || {}
    return headers
  }
  return {}
}

export async function uploadToObjectStorage(objectUrl: string, body: File) {
  const extraHeaders = await getAdditionalHeaders()

  return fetch(objectUrl, {
    body,
    headers: { ...getSignedUrlHeaders(objectUrl), ...extraHeaders },
    method: 'PUT'
  })
}
