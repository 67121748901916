import { getAsyncGraphClient } from '../lib/graph.client'
import { PUBLIC_UPDATE_BATCH } from '../queries/PUBLIC_UPDATE_BATCH'
import { PublicUpdateBatch, PublicUpdateBatchVariables } from '../queries/types/PublicUpdateBatch'

export const publicUpdateBatch = async (variables: PublicUpdateBatchVariables): Promise<void> => {
  const graphClient = await getAsyncGraphClient({
    operation: 'PUBLIC_UPDATE_BATCH',
    variables
  })
  await graphClient.mutate<PublicUpdateBatch, PublicUpdateBatchVariables>({
    mutation: PUBLIC_UPDATE_BATCH,
    variables
  })
}
