import React, { createRef, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useModal } from 'react-modal-hook'
import Select, { MenuPlacement } from 'react-select'
import styled, { css } from 'styled-components'

import { SettingsContext } from '../context/settings.context'
import { Button } from '../fragments/button'
import { BottomWrapper } from '../fragments/control.bar'
import { DefaultModal } from '../fragments/modals'
import { EStepStatus, Step, StyledStep } from '../fragments/step'
import { uploadToObjectStorage } from '../lib/object.storage'
import { publicCreateUpload } from '../mutations/publicCreateUpload'
import { publicUpdateUploadStatus } from '../mutations/publicUpdateUploadStatus'
import { publicUploadDataSource } from '../mutations/publicUploadDataSource'
import { publicUploadDataSources } from '../mutations/publicUploadDataSources'
import { PublicUploadDataSources_publicUploadDataSources } from '../queries/types/PublicUploadDataSources'
import { ICellStyles, IDictionary } from '../types/general.interface'
import { UploadDataSource } from '../types/UploadDataSource'
import { FlatFile } from '../utils/flat.file'
import { License } from '../utils/license.manager'
import { useSelectStyles } from './useSelectStyles'

interface IDataSourceSelectionStatus {
  id: number
  fileName?: string
  dataSources: UploadDataSource[]
  selectedDataSource?: UploadDataSource
  steps: StepState
  errMessage?: string
}

const StyledDataSourceSelection = styled.div`
  padding: 24px 24px 0;

  h1 {
    font-size: 24px;
    font-weight: 500;
    word-break: break-word;

    ${({ theme }) => theme?.dataSource?.title && css(theme.dataSource.title)}
  }

  h2 {
    font-size: 16px;
    font-weight: 500;

    ${({ theme }) => theme?.dataSource?.subtitle && css(theme.dataSource.subtitle)}
  }

  ${({ theme }) => theme?.dataSource?.root && css(theme.dataSource.root)}

  ${StyledStep} {
    ${({ theme }) => theme?.dataSource?.step && css(theme.dataSource.step)}
  }

  ${BottomWrapper} {
    padding-left: 0;
    padding-right: 0;
    align-items: center;

    ${({ theme }) => theme?.dataSource?.footer && css(theme.dataSource.footer)}
  }
`

const PrePara = styled.p`
  white-space: pre-wrap;
`

type StepState = {
  step1_updateUploadStatus: EStepStatus
  step2_uploadDataSources: EStepStatus
  step3_selectDataSource: EStepStatus
  step4_loadDataSource: EStepStatus
}

const { ACTIVE, ERROR, SUCCESS, PENDING } = EStepStatus

const DataSourceSelector = ({
  dataSources,
  language,
  onSelect,
  portalTarget,
  selectedDataSource
}: {
  dataSources: UploadDataSource[]
  language: DataSourceLanguage
  onSelect: (dataSource: UploadDataSource) => void
  portalTarget?: HTMLElement | null
  selectedDataSource?: UploadDataSource
}) => {
  const selectWrapperRef = useRef<HTMLDivElement>(null)
  const [bottomSpace, setBottomSpace] = useState(0)
  const { t } = useTranslation()
  const { styles } = useSelectStyles({
    themePath: 'dataSource.select', // path to styles
    defaultStyles: {} // reset all overrides to defaults
  })

  const errorCount = dataSources.filter((option) => option.hasError).length
  const errorMessages = dataSources
    .map((x, i) => (x.hasError ? `Sheet ${i + 1}: ${x.errorMsg}` : null))
    .filter((x) => x)
    .join('\n')

  useLayoutEffect(() => {
    if (selectWrapperRef.current) {
      const windowHeight = window.innerHeight
      const elementBottom =
        selectWrapperRef.current.offsetTop + selectWrapperRef.current.offsetHeight
      const paddingBottom = 20
      const distanceToBottom = windowHeight - elementBottom - paddingBottom
      setBottomSpace(distanceToBottom)
    }
  }, [selectWrapperRef.current?.offsetTop, window.innerHeight])
  const { maxHeight, menuPlacement }: { maxHeight: number; menuPlacement: MenuPlacement } =
    useMemo(() => {
      const min = 200
      const max = 350
      return {
        maxHeight: bottomSpace < min ? max : bottomSpace > max ? max : bottomSpace,
        menuPlacement: bottomSpace < min ? 'top' : 'bottom'
      }
    }, [bottomSpace])
  return (
    <>
      <h2>{t('excel.stepThreeDefault', { dataSource: language.dataSources })}</h2>
      {errorCount ? (
        <>
          <p>
            {errorCount === 1
              ? t('excel.disabledSheet')
              : t('excel.multipleDisabledSheets', { errorCount, dataSource: language.dataSource })}
          </p>
          <PrePara>{errorMessages}</PrePara>
        </>
      ) : null}
      <div ref={selectWrapperRef}>
        <Select
          menuPosition='fixed'
          menuPlacement={menuPlacement}
          menuPortalTarget={portalTarget}
          options={dataSources}
          styles={{
            ...styles,
            menuList: () => ({
              maxHeight,
              overflow: 'scroll'
            })
          }}
          onChange={(value) => {
            if (value) {
              onSelect(value as UploadDataSource)
            }
          }}
          defaultValue={selectedDataSource}
          isOptionDisabled={(option) => option.hasError}
          isOptionSelected={(option) =>
            selectedDataSource ? option.index === selectedDataSource.index : false
          }
        />
      </div>
    </>
  )
}

let currentId = 0

const pollForUploadDataSources = async (
  {
    licenseKey,
    uploadId
  }: {
    licenseKey: string
    uploadId: string
  },
  waitDuration: number = 5000,
  maxAttempts: number = 500,
  maxPollRetries: number = 5,
  pollRetryDuration: number = 100
): Promise<PublicUploadDataSources_publicUploadDataSources[]> =>
  new Promise<PublicUploadDataSources_publicUploadDataSources[]>((resolve) => {
    let bufferedDataSourcesCount = 0
    let pollRetriesRemaining = maxPollRetries
    let attemptsRemaining = maxAttempts

    const pollAction = async () => {
      if (attemptsRemaining-- < 0) {
        resolve([])
        return
      }

      const dataSources = await publicUploadDataSources({
        licenseKey,
        uploadId
      })
      if (dataSources.length > 0) {
        if (pollRetriesRemaining <= 0) {
          resolve(dataSources.sort((a, b) => a.index - b.index))
          return
        }
        // Reset poll retrying logic if the number of data sources differs
        // from the buffered length (previous invocation). Otherwise, decrement.
        pollRetriesRemaining =
          bufferedDataSourcesCount === dataSources.length
            ? pollRetriesRemaining - 1
            : maxPollRetries

        bufferedDataSourcesCount = dataSources.length
        setTimeout(pollAction, pollRetryDuration)
        return
      }

      setTimeout(pollAction, waitDuration)
    }

    pollAction()
  })

const getEmptyDataSourceSelectionStatus = (id: number): IDataSourceSelectionStatus => ({
  id,
  dataSources: [],
  steps: {
    step1_updateUploadStatus: PENDING,
    step2_uploadDataSources: PENDING,
    step3_selectDataSource: PENDING,
    step4_loadDataSource: PENDING
  }
})

type DataSourceLanguage = {
  dataSource: string
  dataSources: string
}

interface IDataSourceResult {
  final: FlatFile | File
  styles?: IDictionary<ICellStyles> | undefined
}

export const useDataSourceSelectionFlow = () => {
  const { t } = useTranslation()
  const modalRef = createRef<HTMLDivElement>()
  const settings = useContext(SettingsContext)
  const resolveRef = useRef<(value: Promise<IDataSourceResult | void> | void) => void>()
  const resolveDataSourceRef = useRef<(dataSource?: UploadDataSource) => void>()
  const [dataSourceState, setDataSourceState] = useState<IDataSourceSelectionStatus>(
    getEmptyDataSourceSelectionStatus(0)
  )

  const isExcel = !!(dataSourceState.fileName && dataSourceState.fileName.match(/\.xlsx?$/i))
  const isXML = !!(dataSourceState.fileName && dataSourceState.fileName.match(/\.xml$/i))

  const language: DataSourceLanguage = {
    dataSource: t(isExcel ? 'excel.singleXls' : isXML ? 'excel.singleXml' : 'excel.singleDefault'),
    dataSources: t(isExcel ? 'excel.multiXls' : isXML ? 'excel.multiXml' : 'excel.multiDefault')
  }

  const [showModal, hideModal] = useModal(
    () => (
      <DefaultModal
        onRequestClose={() => {
          hideModal()
          resolveRef.current?.()
        }}
      >
        <StyledDataSourceSelection ref={modalRef}>
          <h1>{t('excel.importSheet', { dataSource: dataSourceState.fileName })}</h1>
          <Step
            status={dataSourceState.steps.step1_updateUploadStatus}
            text={{
              default: t('excel.stepOneDefault'),
              error: t('excel.stepOneError'),
              success: t('excel.stepOneSuccess')
            }}
          />
          <Step
            status={dataSourceState.steps.step2_uploadDataSources}
            text={{
              default: t('excel.stepTwoDefault', { dataSource: language.dataSources }),
              error: t('excel.stepTwoError', { dataSource: language.dataSources }),
              success: t('excel.stepTwoSuccess', { dataSource: language.dataSources })
            }}
          />
          {dataSourceState.steps.step3_selectDataSource === ACTIVE ? (
            <DataSourceSelector
              language={language}
              dataSources={dataSourceState.dataSources}
              onSelect={(selectedDataSource) => {
                setDataSourceState({ ...dataSourceState, selectedDataSource })
              }}
              selectedDataSource={dataSourceState.selectedDataSource}
              portalTarget={
                document.getElementsByClassName('flatfile-modal-overlay')[0] as HTMLElement
              }
            />
          ) : (
            <Step
              status={dataSourceState.steps.step3_selectDataSource}
              text={{
                default: t('excel.stepThreeDefault', { dataSource: language.dataSources }),
                error:
                  t('excel.stepThreeError', { dataSource: language.dataSources }) +
                  (dataSourceState.errMessage ? `\n\n${dataSourceState.errMessage}` : ''),
                success: t('excel.stepThreeSuccess', {
                  dataSource: language.dataSources,
                  dataSourceLabel: dataSourceState.selectedDataSource?.label
                })
              }}
            />
          )}
          {dataSourceState.steps.step4_loadDataSource === PENDING ? null : (
            <Step
              status={dataSourceState.steps.step4_loadDataSource}
              text={{
                default: t('excel.stepFourDefault', { dataSource: language.dataSource }),
                error: t('excel.stepFourError', { dataSource: language.dataSource })
              }}
            />
          )}
          <BottomWrapper>
            <Button
              title={t('buttons.cancel')}
              classes={['secondary', 'datasource-cancel']}
              onClick={() => {
                hideModal()
                resolveRef.current?.()
              }}
            />
            <Button
              disabled={!resolveDataSourceRef.current}
              classes={['primary', 'datasource-continue']}
              title={t('buttons.continue')}
              onClick={() => {
                resolveDataSourceRef.current?.(dataSourceState.selectedDataSource)
                resolveDataSourceRef.current = undefined
              }}
            />
          </BottomWrapper>
        </StyledDataSourceSelection>
      </DefaultModal>
    ),
    [dataSourceState]
  )

  return async (
    license: License,
    file: File,
    batchId: string
  ): Promise<IDataSourceResult | void> => {
    const licenseKey = license.key
    if (!licenseKey) {
      throw new Error('license.key must be present')
    }

    const currentDataSourceState = {
      ...getEmptyDataSourceSelectionStatus(++currentId),
      fileName: file.name
    }

    const updateStepStatus = (step: keyof StepState, status: EStepStatus, errMessage?: string) => {
      if (currentDataSourceState.id !== currentId) {
        return false
      }
      currentDataSourceState.steps[step] = status
      setDataSourceState({ ...currentDataSourceState, errMessage })
      return true
    }

    const updateDataSources = (updatedDataSources: UploadDataSource[]) => {
      if (currentDataSourceState.id !== currentId) {
        return false
      }
      currentDataSourceState.dataSources = updatedDataSources
      currentDataSourceState.selectedDataSource = updatedDataSources.filter((u) => !u.hasError)[0]
      setDataSourceState({ ...currentDataSourceState })
      return true
    }

    return new Promise(async (resolve) => {
      resolveRef.current = resolve

      if (!updateStepStatus('step1_updateUploadStatus', ACTIVE)) {
        return
      }

      showModal()

      const { uploadId, objectUrl } = await publicCreateUpload({
        batchId,
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        licenseKey
      })

      if (!objectUrl || !uploadId) {
        updateStepStatus('step1_updateUploadStatus', ERROR)
        return
      }

      const uploadResponse = await uploadToObjectStorage(objectUrl, file)

      if (!uploadResponse.ok) {
        await publicUpdateUploadStatus({
          licenseKey,
          status: 'failed',
          uploadId
        })
        updateStepStatus('step1_updateUploadStatus', ERROR)
        return
      }

      const updateUploadStatusResponse = await publicUpdateUploadStatus({
        licenseKey,
        status: 'uploaded',
        uploadId
      })

      if (!updateUploadStatusResponse.success) {
        updateStepStatus('step1_updateUploadStatus', ERROR)
        return
      }

      if (!updateStepStatus('step1_updateUploadStatus', SUCCESS)) {
        return
      }

      if (!updateStepStatus('step2_uploadDataSources', ACTIVE)) {
        return
      }

      const dataSources = await pollForUploadDataSources({ licenseKey, uploadId })

      if (dataSources.length === 0) {
        updateStepStatus('step2_uploadDataSources', ERROR)
        return
      }

      if (!updateStepStatus('step2_uploadDataSources', SUCCESS)) {
        return
      }
      if (dataSources.every((v) => v.hasError)) {
        updateStepStatus(
          'step3_selectDataSource',
          ERROR,
          dataSources
            .map((x, i) => (x.hasError ? `Sheet ${i + 1}: ${x.errorMsg}` : null))
            .filter((x) => x)
            .join('\n')
        )
        return
      }

      const asyncSelectDataSource = new Promise<UploadDataSource | undefined>((r) => {
        resolveDataSourceRef.current = r
      })

      if (!updateDataSources(dataSources)) {
        return
      }

      if (!updateStepStatus('step3_selectDataSource', ACTIVE)) {
        return
      }

      const selectedDataSource = await asyncSelectDataSource

      currentDataSourceState.selectedDataSource = selectedDataSource

      if (!selectedDataSource) {
        return
      }

      if (!updateStepStatus('step3_selectDataSource', SUCCESS)) {
        return
      }

      if (!updateStepStatus('step4_loadDataSource', ACTIVE)) {
        return
      }

      const dataSource = await publicUploadDataSource({
        licenseKey,
        uploadId,
        index: selectedDataSource.index,
        dateFormat: settings?.dateFormat ?? null,
        parseStyles: settings?.allowFormatting ?? false
      })

      if (!dataSource) {
        updateStepStatus('step4_loadDataSource', ERROR)
        return
      }

      const fileName = `${file.name}#${dataSource.label}`

      const { dataset, flatDataset, styles } = dataSource

      if (!dataset && !flatDataset) {
        updateStepStatus('step4_loadDataSource', ERROR)
        return
      }

      hideModal()
      return resolve({
        final: flatDataset
          ? new FlatFile(flatDataset, fileName)
          : new File([dataset as string], fileName, { type: 'text/csv' }),
        styles
      })
    })
  }
}
