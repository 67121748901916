import React, { ReactNode } from 'react'

export const Spinner = ({ text = 'Loading...' }: { text?: ReactNode }) => (
  <div style={{ padding: '50px' }}>
    <div className='loading-indicator'>
      <div className='spinner-new'>
        <div className='dot1' />
        <div className='dot2' />
      </div>
      <h1>{text}</h1>
    </div>
  </div>
)
