import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'airbnb-browser-shims'
import log from 'loglevel'
import prefix from 'loglevel-plugin-prefix'
import Penpal from 'penpal'
import { parse } from 'query-string'
import { IDataHookResponse } from 'types/general.interface'

import { LOG_LEVEL, SENTRY_CONFIG } from './config'
import { DEMO_STAGE } from './demo/index.demo'
import { ITheme } from './theme'
import { IBatchConfig, IFieldBase, IVirtualFieldOptions } from './types/settings.interface'
import { emit, eventManager, hasListeners, listen, listenAndReply } from './utils/event.manager'
import { registerInteractionEventListeners } from './utils/interaction-events'
import { EMetricStatType, IMetricStats } from './utils/metric.stats'

// Use the import let = require syntax on TS.
/**
 * Set the default logging level and formatting
 */
const logger = log.noConflict()
logger.setDefaultLevel(LOG_LEVEL)
prefix.reg(logger)
prefix.apply(logger, {
  timestampFormatter(date) {
    return (
      date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1') +
      '.' +
      date.getMilliseconds().toString().padStart(3, '0')
    )
  }
})

/**
 * Initialize Sentry
 */
if (SENTRY_CONFIG.enabled) {
  Sentry.init({
    dsn: SENTRY_CONFIG.dsn,
    debug: SENTRY_CONFIG.debug,
    environment: SENTRY_CONFIG.environment,
    release: process.env.COMMIT_REF || SENTRY_CONFIG.release,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['api.us.flatfile.io', 'api.flatfile.io']
      })
    ],
    tracesSampleRate: 0.1
  })
}

/**
 * Configure the mounting API domain. This allows for local testing
 * as well as sending data to on-prem instances.
 */
const urlParams = parse(location.search)

if (!window.FF_LICENSE_DOMAIN) {
  window.FF_LICENSE_DOMAIN =
    'domain' in urlParams ? (urlParams.domain as string) : 'https://api.us.flatfile.io'
}

/**
 * Load and verify the license key, configur testing fallbacks and load features
 * available here.
 * @todo load features from the new team.features cache
 */
const licenseKeyRegExp = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/

const [licenseKeyInPath] = location.pathname.match(licenseKeyRegExp) || [null]

if (!('key' in urlParams) && licenseKeyInPath) {
  urlParams.key = licenseKeyInPath
}

Penpal.debug = false

class ParentWindowError extends Error {}

const handleParentWindowException = (e: Error | string | object) => {
  if (typeof e === 'object' && e && 'name' in e && 'message' in e) {
    const err = new ParentWindowError(e.message)
    err.stack = e.stack
    err.name = `ParentWindow:${e.name}`
    Sentry.captureException(err)
  } else {
    Sentry.captureException({ parentWindowException: e })
  }
}

const handshake = Penpal.connectToParent({
  methods: {
    open(batchConfig: IBatchConfig) {
      if (batchConfig.hasVirtualRecordHook) {
        handshake.promise.then((parent) => {
          listenAndReply('virtual:record:init:batch', async ({ payload: { data } }) => {
            try {
              return await parent.bulkHookCallback(data, 'virtual')
            } catch (e) {
              handleParentWindowException(e)
            }
          })
        })
      } else {
        eventManager.removeAllListeners('virtual:record:init:batch')
      }
      if (batchConfig.hasRecordHook) {
        handshake.promise.then((parent) => {
          listenAndReply('record:change', async ({ payload: { data, sequence } }) => {
            try {
              return await parent.dataHookCallback(data, sequence, 'change')
            } catch (e) {
              handleParentWindowException(e)
            }
          })
          if (batchConfig.bulkInit) {
            listenAndReply('record:init:batch', async ({ payload: { data } }) => {
              try {
                return await parent.bulkHookCallback(data, 'init')
              } catch (e) {
                handleParentWindowException(e)
              }
            })
          } else {
            listenAndReply('record:init', async ({ payload: { data, sequence } }) => {
              try {
                return await parent.dataHookCallback(data, sequence, 'init')
              } catch (e) {
                handleParentWindowException(e)
              }
            })
          }
        })
      } else {
        eventManager.removeAllListeners('record:change')
        eventManager.removeAllListeners('record:init')
      }

      if (!hasListeners('field:init') && batchConfig.fieldHooks?.length) {
        handshake.promise.then((parent) => {
          listenAndReply('field:init', async ({ payload: { data, meta } }) => {
            try {
              return parent.fieldHookCallback(data, meta)
            } catch (e) {
              handleParentWindowException(e)
            }
          })
        })
      }

      if (batchConfig.hasInteractionEventCallback) {
        handshake.promise.then((parent) => {
          if (parent.interactionEventCallback) {
            registerInteractionEventListeners(parent.interactionEventCallback)
          }
        })
      }

      if (!hasListeners('step:change') && batchConfig.stepHooks?.length) {
        handshake.promise.then((parent) => {
          listenAndReply('step:change', async ({ payload: { step, payload } }) => {
            try {
              return parent.stepHookCallback(step, payload)
            } catch (e) {
              handleParentWindowException(e)
            }
          })
        })
      }

      emit('do/open', batchConfig)
    },
    close() {
      emit('do/close')
    },
    displayLoader(message?: string) {
      emit('do/dialog:display', { message, type: 'progress' })
    },
    displaySuccess(message?: string) {
      emit('do/dialog:display', { message, type: 'success' })
    },
    displayError(message?: string, corrections?: IDataHookResponse[]) {
      emit('do/dialog:display', { message, type: 'error' })

      if (corrections?.length) {
        emit('do/corrections', corrections)
      }
    },
    setLanguage(lang: string) {
      emit('do/setLanguage', { lang })
    },
    addVirtualField({ field, options }: { field: IFieldBase; options: IVirtualFieldOptions }) {
      emit('do/addVirtualField', { field, options })
    },
    nextChunk() {
      // return AppRef.current?.nextChunk()
    },
    setUser() {
      // return AppRef.current?.nextChunk()
    },
    DEMO__setConfig({ theme, stage }: { theme: ITheme; stage: DEMO_STAGE }) {
      emit('do/demo', { theme, stage })
    }
  }
})

handshake.promise.then((parent) => {
  listen('open', (e) => {
    if (!e.payload) {
      parent.close()
    }
  })
  listen('data:submit', ({ payload }) => {
    parent.results(payload)
  })
  listen('error:network', (err) => {
    if (parent.networkErrorCallback) {
      parent.networkErrorCallback(err)
    }
  })
  if (parent.beforeFetchCallback) {
    listenAndReply('fetch:before', async ({ payload }) => {
      try {
        return parent.beforeFetchCallback(payload)
      } catch (e) {
        handleParentWindowException(e)
      }
    })
  }
  listenAndReply('settings', () => {
    return parent.ready()
  })

  logger.debug('Penpal handshake achieved w/parent frame')
})

/**
 * Initialization stuff that should wait for pageload
 */
export const onPageLoad = (statsInstance: IMetricStats) => {
  logger.info('Page loaded')
  if (!window.FF_FEATURES) {
    Sentry.captureException(new Error('window.FF_FEATURES is ' + typeof window.FF_FEATURES))
  }

  window.addEventListener('click', () => {
    statsInstance.add(EMetricStatType.CLICK)
  })

  window.addEventListener(
    'keydown',
    () => {
      statsInstance.add(EMetricStatType.KEYDOWN)
    },
    true
  )
}
