import React from 'react'

import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Button } from '../fragments/button'
import { RawHTML } from '../fragments/html.raw'

const DropParagraph = styled.p`
  margin: 16px 0 0;
  text-align: left;
`
const DropContainer = styled.div`
  flex: 1;
  ${DropParagraph} {
    ${({ theme }) => theme?.dropzone?.accepted && css(theme.dropzone.accepted)}
  }
`

export const FileUploader: React.FC<{
  onClick: () => void
  accept: string
}> = ({ onClick, accept }) => {
  const { t } = useTranslation()

  return (
    <DropContainer>
      <Button onClick={onClick} classes={['primary', 'upload-button']}>
        {t('dropzone.button')}
      </Button>
      <DropParagraph className='secondaryTextColor'>
        <RawHTML
          text={t('dropzone.accepted', { types: accept })}
          allowedTags={['a', 'br', 'div', 'img', 'p']}
        />
      </DropParagraph>
    </DropContainer>
  )
}
FileUploader.displayName = 'FileUploader'
