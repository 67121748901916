import styled, { css } from 'styled-components'

export const Wrapper = styled.div.attrs(() => ({
  'data-ref': 'wrapper'
}))`
  ${({ theme }) =>
    theme?.global?.backgroundColor &&
    css`
      background-color: ${theme.global.backgroundColor};
    `};
  ${({ theme }) =>
    theme?.global?.textColor &&
    css`
      color: ${theme.global.textColor};
    `};
`
