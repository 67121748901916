import React from 'react'

import { Translation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'

const slideDown = keyframes`
 0% { transform: translateY(-100%) }
 100% { transform: translateY(0) }
`

const StyledBanner = styled.div`
  background-color: #f38c01;
  font-size: 12px;
  vertical-align: bottom;
  color: #fff;
  text-align: center;
  letter-spacing: 0.5px;
  display: table;
  width: auto;
  padding: 4px 16px 6px;
  margin: 0 auto;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  transform: translateY(-100%);
  animation: ${slideDown} 0.25s 0.35s ease-in both;
  position: relative;
  z-index: 1;

  span {
    vertical-align: sub;
  }

  svg {
    height: 12px;
    width: 12px;
    margin-right: 7px;
    margin-bottom: 2px;
  }
`

const BoldLabel = styled.span`
  font-weight: 500;
`

const TimidLabel = styled.span`
  font-weight: 300;
  margin-left: 6px;
`

export const DevBanner = () => {
  return (
    <Translation ns='translation'>
      {(t) => (
        <StyledBanner data-test='dev-banner'>
          <BoldLabel>{t('devMode')}</BoldLabel>
          <TimidLabel>{t(['devModeFineprint', 'devMode'])}</TimidLabel>
        </StyledBanner>
      )}
    </Translation>
  )
}
