import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { LANGUAGE_SUPPORTED, LANGUAGE_WHITELIST, RELATIVE_DEPLOY_URL } from './config'

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    preload: ['en'],
    nsSeparator: ':',
    whitelist: LANGUAGE_WHITELIST,
    keySeparator: '.',
    backend: {
      crossDomain: true,
      allowMultiLoading: false,
      // for all available options read the backend's repository readme file
      loadPath: (lngs: string[]) =>
        `${RELATIVE_DEPLOY_URL}/locales/${
          LANGUAGE_SUPPORTED.includes(lngs?.[0]) ? lngs[0] : 'en'
        }/{{ns}}.json`
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    detection: {
      order: ['navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag']
    }
  })

export default i18n
