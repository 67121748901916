import * as Sentry from '@sentry/react'

export const retryUntilSuccessful = async (
  func: () => Promise<void>,
  maxRetryCount = 20,
  errorContext = ''
): Promise<void> =>
  new Promise(async (resolve, reject) => {
    let isSuccessful = false
    let retryCount = 0
    const tryFunc = async () => {
      if (isSuccessful) {
        return
      }
      try {
        await func()
        isSuccessful = true
        resolve()
      } catch (e) {
        if (retryCount > maxRetryCount) {
          const retryError = new Error(`maximum retry count exceeded: ${errorContext}`)
          Sentry.captureException(retryError)
          reject(retryError)
          return
        }
        Sentry.setExtra('retryCount', retryCount++)
        Sentry.captureException(e)
        // tslint:disable-next-line:no-console
        console.error(e, 'will retry in 1 second')
        setTimeout(tryFunc, 1000)
      }
    }
    await tryFunc()
  })
