import React, { Dispatch, useCallback, useContext, useState } from 'react'

import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Tooltip } from '../components/Tooltip'
import { BatchContext, IBatchContextSet } from '../context/batch.context'
import { SettingsContext } from '../context/settings.context'
import { STAGE } from '../controller'
import { Button, SecondaryButton } from '../fragments/button'
import { Footer, TopBar } from '../fragments/control.bar'
import { EncodingDropdown } from '../fragments/encodingDropdown'
import { QuestionIcon } from '../fragments/icons'
import { Wrapper } from '../fragments/layout'
import { HeaderMatchTable } from '../fragments/tables'
import { useErrorModal } from '../hooks/useErrorModal'
import { ERROR_CODE } from '../lib/file.parser'
import { convertToLetters } from '../utils/functions'

const NUM_PREVIEW_ROWS = 20
const NUM_PREVIEW_COLS = 3

type ParseViewProps = {
  onComplete: () => void
  onCancel?: () => void
  setEncoding: Dispatch<string>
  encodingDetected: string
  encodingSetting?: string
}

const HeaderMatchBanner = styled.div`
  border: 2px solid;
  border-radius: 5px;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 500;

  h2 {
    font-size: inherit;
    font-weight: inherit;
  }

  & > div:first-child {
    margin-right: auto;
  }

  & > button:not(:last-child) {
    margin-right: 12px;
  }
`

const HeaderMatchInfo = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-weight: 500;
    font-size: 14px;
  }

  & > div:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  ${({ theme }) => theme?.headerMatch?.info && css(theme.headerMatch.info)}
`

const StyledWrapper = styled(Wrapper)`
  background-color: rgb(245, 248, 250);
  background-image: linear-gradient(to top left, rgb(245, 248, 250) 50%, rgb(255, 255, 255));
  border-radius: 4px;

  ${({ theme }) =>
    theme?.__FULL_SCREEN__ &&
    css`
      background: none;
    `}

  ${({ theme }) =>
    theme?.global?.backgroundColor &&
    css`
      background: none;
    `};

  .controlbar.bottom {
    background-color: transparent;
  }
`
const HeaderMatchTableWrapper = styled.div`
  height: 255px;
  overflow: auto;
  padding-left: 40px;
  margin-left: -40px;
`
const HeaderMatchRoot = styled.div`
  padding: 0 40px;

  h2 {
    margin: 0 auto 0 0;
  }

  ${({ theme }) => theme?.headerMatch?.root && css(theme.headerMatch.root)}

  ${HeaderMatchBanner} {
    ${({ theme }) =>
      theme?.global?.successColor &&
      css`
        border-color: ${theme.global.successColor};
      `}

    ${({ theme }) => theme?.headerMatch?.content && css(theme.headerMatch.content)}
  }
`

export const HeaderCheckView: React.FC<ParseViewProps> = ({
  onComplete,
  onCancel,
  encodingDetected,
  encodingSetting,
  setEncoding
}) => {
  const { t } = useTranslation()
  const settings = useContext(SettingsContext)
  const { batch } = useContext(BatchContext) as IBatchContextSet
  const parser = batch.getParser()

  const [start, setStart] = useState(parser.start)
  const [selectable, setSelectable] = useState(false)
  const [previewData, setPreviewData] = useState<undefined | string[][]>()
  const [showErrorModal] = useErrorModal()

  if (!previewData || !previewData.length) {
    setTimeout(() => {
      setPreviewData(parser.getPreviewData(NUM_PREVIEW_ROWS).map(([r]) => r))
    }, 0)
  }

  const handleComplete = useCallback(async () => {
    if (parser.headersSet) {
      const errors = await parser.checkLimits()
      if (typeof errors !== 'undefined') {
        showErrorModal(
          errors === ERROR_CODE.MAX_ROWS
            ? t('errors.maxRecords', { maxRecords: settings.maxRecords })
            : t('errors.noRecords')
        )
      } else {
        await batch.recipe.generateInitialRules(parser)

        // for testing purposes only
        const predictedStart = parser.detectHeaderStart()
        if (predictedStart !== parser.start) {
          Sentry.captureMessage(`detectHeaderStart failed`, {
            extra: {
              predicted: predictedStart,
              actual: parser.start,
              sample: parser.sample
                .slice(
                  Math.min(predictedStart, parser.start),
                  Math.min(Math.max(predictedStart, parser.start) + 2, parser.sample.length)
                )
                .reduce((acc, [data, rowIndex]) => {
                  acc[rowIndex] = data.join(',')
                  return acc
                }, {} as Record<string, string>)
            }
          })
        }

        const predictedHasHeader = parser.detectHasHeader()
        if (predictedHasHeader !== parser.hasHeader) {
          Sentry.captureMessage(`detectHasHeader failed`, {
            extra: {
              predicted: predictedHasHeader,
              actual: parser.hasHeader,
              sample: parser.sample
                .slice(parser.start, Math.min(parser.start + 4, parser.sample.length))
                .reduce((acc, [data, rowIndex]) => {
                  acc[rowIndex] = data.join(',')
                  return acc
                }, {} as Record<string, string>)
            }
          })
        }

        onComplete()
      }
    }

    setSelectable(false)
  }, [parser])

  return (
    <StyledWrapper className='header-match-stage'>
      <TopBar stage={STAGE.HEADER_CHECK} />
      <HeaderMatchRoot>
        {!selectable ? (
          <HeaderMatchBanner className='header-match-banner'>
            <h2>
              {t('headerSelection.question1')}
              <span
                className='tooltip--question-mark'
                data-for='tooltip--selecting-your-first-row'
                data-tip='tooltip--selecting-your-first-row'
              >
                <QuestionIcon />
              </span>
              <Tooltip
                id='tooltip--selecting-your-first-row'
                offset={{ top: 0, left: 0 }}
                content={
                  <div style={{ maxWidth: 300 }}>
                    <h3>{t('headerSelection.tooltip1Title')}</h3>
                    <p>{t('headerSelection.tooltip1Content')}</p>
                  </div>
                }
              />
            </h2>
            <Button
              title={t('buttons.yes')}
              classes={['success', 'yes-button', 'header-match-yes']}
              onClick={() => {
                parser.hasHeader = true
                handleComplete()
              }}
            />
            <Button
              title={t('buttons.selectAnotherRow')}
              classes={['header-match-no']}
              onClick={() => setSelectable(true)}
            />
            <Button
              title={t('buttons.noHeaderRow')}
              classes={['header-match-no']}
              onClick={() => {
                parser.hasHeader = false
                handleComplete()
              }}
            />
          </HeaderMatchBanner>
        ) : (
          <HeaderMatchBanner className='header-match-banner'>
            <h2>
              {t('headerSelection.question2')}
              <span
                className='tooltip--question-mark'
                data-for='tooltip--selecting-your-column-headers'
                data-tip='tooltip--selecting-your-column-headers'
              >
                <QuestionIcon />
              </span>
              <Tooltip
                id='tooltip--selecting-your-column-headers'
                offset={{ top: 0, left: 0 }}
                content={
                  <div style={{ maxWidth: 300 }}>
                    <h3>{t('headerSelection.tooltip2Title')}</h3>
                    <p>{t('headerSelection.tooltip2Content')}</p>
                  </div>
                }
              />
            </h2>
            <Button
              title={t('buttons.confirm')}
              classes={['success', 'yes-button', 'header-match-yes']}
              onClick={() => {
                parser.reset()
                parser.start = start
                handleComplete()
              }}
            />
          </HeaderMatchBanner>
        )}
        <HeaderMatchInfo>
          <h3>{parser.fileName}</h3>
          {settings.displayEncoding !== false && (
            <div>
              {t('headerSelection.encoding')}
              <span
                className='tooltip--question-mark'
                data-for='tooltip--encoding'
                data-tip='tooltip--encoding'
              >
                <QuestionIcon />
              </span>
              <Tooltip
                id='tooltip--encoding'
                offset={{ top: 0, left: 0 }}
                content={
                  <div style={{ maxWidth: 300 }}>
                    <h3>{t('headerSelection.encodingTooltipTitle')}</h3>
                    <p>{t('headerSelection.encodingTooltipContent')}</p>
                  </div>
                }
              />
              <EncodingDropdown
                batch={batch}
                encodingDetected={encodingDetected}
                encodingSetting={encodingSetting}
                numPreviewRows={NUM_PREVIEW_ROWS}
                setEncoding={setEncoding}
                setPreviewData={setPreviewData}
              />
            </div>
          )}
        </HeaderMatchInfo>
        <HeaderMatchTableWrapper>
          <HeaderMatchTable style={{ tableLayout: 'initial', width: '100%' }}>
            <thead>
              <tr>
                <th style={{ height: '25px' }}>
                  <div className='relative'>
                    <span>&nbsp;</span>
                  </div>
                </th>
                {previewData &&
                  Array.isArray(previewData[0]) &&
                  previewData[0].slice(0, NUM_PREVIEW_COLS).map((_v, i) => (
                    <th key={`header-${i}`}>
                      <div className='relative'>
                        <span>{convertToLetters(i + 1)}</span>
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {previewData &&
                previewData.map((row, i) => (
                  <tr
                    key={`row-${i}`}
                    className={selectable ? 'selectable' : ''}
                    onClick={() => setStart(i)}
                  >
                    <th>
                      <div className={!selectable && parser.start === i ? 'with-arrow' : ''}>
                        <span>{i + 1}</span>
                        {selectable && (
                          <React.Fragment>
                            <input
                              type='radio'
                              name='row'
                              checked={start === i}
                              onChange={() => setStart(i)}
                              id={`selectable-row-${i}`}
                            />
                            <label htmlFor={`selectable-row-${i}`} />
                          </React.Fragment>
                        )}
                      </div>
                    </th>
                    {row.slice(0, NUM_PREVIEW_COLS).map((v, ii) => (
                      <td key={`col-${i}-${ii}`}>{v}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </HeaderMatchTable>
        </HeaderMatchTableWrapper>
      </HeaderMatchRoot>
      <Footer>
        {onCancel && <SecondaryButton title={t('buttons.back')} onClick={() => onCancel()} />}
      </Footer>
    </StyledWrapper>
  )
}
HeaderCheckView.displayName = 'ParseView'
