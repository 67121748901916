import { gql } from 'apollo-boost'

export const PUBLIC_SUBMIT_ROWS = gql`
  mutation PublicSubmitRows(
    $batchId: UUID!
    $writeAccessKey: UUID!
    $licenseKey: UUID!
    $rows: [PublicRowDto!]!
    $sequence: SequenceDto!
  ) {
    publicSubmitRows(
      sequence: $sequence
      batchId: $batchId
      writeAccessKey: $writeAccessKey
      licenseKey: $licenseKey
      rows: $rows
    ) {
      success
    }
  }
`
