import { getAsyncGraphClient } from '../lib/graph.client'
import { PUBLIC_UPLOAD_DATA_SOURCES } from '../queries/PUBLIC_UPLOAD_DATA_SOURCES'
import {
  PublicUploadDataSources,
  PublicUploadDataSourcesVariables,
  PublicUploadDataSources_publicUploadDataSources
} from '../queries/types/PublicUploadDataSources'

export const publicUploadDataSources = async (
  variables: PublicUploadDataSourcesVariables
): Promise<PublicUploadDataSources_publicUploadDataSources[]> => {
  const graphClient = await getAsyncGraphClient({
    operation: 'PUBLIC_UPLOAD_DATA_SOURCES',
    variables
  })
  const mutationResponse = await graphClient.mutate<
    PublicUploadDataSources,
    PublicUploadDataSourcesVariables
  >({
    mutation: PUBLIC_UPLOAD_DATA_SOURCES,
    variables
  })
  if (mutationResponse && mutationResponse.data) {
    return mutationResponse.data.publicUploadDataSources
  }
  return []
}
