import { gql } from 'apollo-boost'

export const PUBLIC_UPDATE_BATCH = gql`
  mutation PublicUpdateBatch(
    $batchId: String!
    $writeAccessKey: String!
    $licenseKey: String!
    $headersMatched: [HeaderMatchedDto!]
    $headersRaw: [HeaderRawDto!]
    $failedAfter: Int
    $handledAfter: Int
    $matchedAfter: Int
    $submittedAfter: Int
    $failureReason: String
    $headerHash: String
    $countRows: Int
    $countRowsInvalid: Int
    $countRowsAccepted: Int
    $countColumns: Int
    $countColumnsMatched: Int
    $stats: JSONObject
  ) {
    publicUpdateBatch(
      batchId: $batchId
      writeAccessKey: $writeAccessKey
      licenseKey: $licenseKey

      headersMatched: $headersMatched
      headersRaw: $headersRaw

      failedAfter: $failedAfter
      handledAfter: $handledAfter
      matchedAfter: $matchedAfter
      submittedAfter: $submittedAfter

      failureReason: $failureReason
      headerHash: $headerHash

      countRows: $countRows
      countRowsInvalid: $countRowsInvalid
      countRowsAccepted: $countRowsAccepted
      countColumns: $countColumns
      countColumnsMatched: $countColumnsMatched

      stats: $stats
    ) {
      success
    }
  }
`
