import React from 'react'

import { STAGE } from '../controller'
import { Button } from './button'
import { Footer, TopBar } from './control.bar'

export const ErrorFallback = ({ error }: { error?: string }) => (
  <div className='scroll-block'>
    <TopBar title='Error' stage={STAGE.INITIAL} />
    <div className='error-container'>
      <div className='error-block'>{error || 'Unknown error'} </div>
    </div>
    <Footer>
      <Button title='Reload' onClick={() => window.location.reload()} />
    </Footer>
  </div>
)
