import { createContext } from 'react'

import { IEnvironment } from '../types/features.interface'

export const EnvironmentContext = createContext<IEnvironment>({
  license: {},
  deactivated: false,
  features: {
    injectCss: false,
    whiteLabel: false,
    prominentWhiteLabel: false,
    developmentMode: false
  }
})
