import React from 'react'

import { useTranslation } from 'react-i18next'

import { IMatchOptionsRow, MatchOptionsRow } from './match.options.row'

interface IMatchOptionsTable extends Omit<IMatchOptionsRow, 'sourceValue'> {
  sourceValues: IMatchOptionsRow['sourceValue'][]
}

export const MatchOptionsTable: React.FC<IMatchOptionsTable> = ({
  sourceValues,
  ...props
}: IMatchOptionsTable) => {
  const { t } = useTranslation()

  return (
    <div className='column'>
      <div className='match-options-table-container'>
        <div className='header'>
          <div className='column'>{t('valuesYour')}</div>
          <div className='column'>{t('valuesOur')}</div>
        </div>
        <table className='match-options-table'>
          <tbody>
            {sourceValues.map((sourceValue, i) => (
              <MatchOptionsRow {...props} key={i} sourceValue={sourceValue} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
MatchOptionsTable.displayName = 'MatchOptionsTable'
