import { createContext } from 'react'

import { Batch } from '../lib/batch'

export const BatchContext = createContext<IBatchContext>({
  batch: undefined,
  setBatch: (_b) => {
    throw new Error('Batch Context not yet initialized')
  }
})

type IBatchContext = {
  batch: Batch | undefined
  setBatch: (b: Batch | undefined) => void
}

export type IBatchContextSet = {
  batch: Batch
  setBatch: (b: Batch | undefined) => void
}
