import React, { Suspense, useEffect, useState } from 'react'
import { render } from 'react-dom'

import * as Sentry from '@sentry/react'
import { withProfiler } from '@sentry/react'
import { parse } from 'query-string'
import { I18nextProvider } from 'react-i18next'
import { ModalProvider } from 'react-modal-hook'
import { ThemeProvider } from 'styled-components'

import { App } from './app'
import { EnvironmentContext } from './context/environment.context'
import { MetricStatsContext } from './context/metric.stats.context'
import Demo from './demo/index.demo'
import LoadingLine from './fragments/loading'
import { onPageLoad } from './global.init'
import i18n from './i18n'
import { GilroyFont } from './styles/fonts/GilroyFont'
import { ThemeController } from './theme'
import { LicenseProvider } from './utils/license.manager'
import { createMetricStats } from './utils/metric.stats'

import './styles/index.scss'

let hitCounter = 0
const hit = (e: KeyboardEvent) => {
  if (e.ctrlKey && e.altKey && e.code === 'Delete') {
    hitCounter++
    if (hitCounter % 5 === 0) {
      Sentry.captureException(new Error('Portal made a boo boo'))
    }
  }
}

document.addEventListener('keydown', hit)

const statsInstance = createMetricStats()

const Init = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => onPageLoad(statsInstance), [])

  return <>{children}</>
}

const PortalComponent = withProfiler(
  () => {
    const [theme, setTheme] = useState({})

    return (
      <ThemeProvider theme={theme}>
        <ThemeController>
          <MetricStatsContext.Provider value={statsInstance}>
            <LoadingLine />
            <GilroyFont />
            <ModalProvider>
              <LicenseProvider>
                {(license, features, deactivated) => (
                  <Init>
                    <I18nextProvider i18n={i18n}>
                      <EnvironmentContext.Provider value={{ license, features, deactivated }}>
                        <Suspense fallback='Loading...'>
                          <App onTheme={setTheme} />
                        </Suspense>
                      </EnvironmentContext.Provider>
                    </I18nextProvider>
                  </Init>
                )}
              </LicenseProvider>
            </ModalProvider>
          </MetricStatsContext.Provider>
        </ThemeController>
      </ThemeProvider>
    )
  },
  { name: 'PortalComponent' }
)

if (parse(location.search).demo === '1') {
  window.FF_FEATURES = {}
  render(<Demo />, document.getElementById('kiosk-lite'))
} else {
  render(<PortalComponent />, document.getElementById('kiosk-lite'))
}
