import React, { FC, ReactNode, useEffect, useState } from 'react'

import * as Sentry from '@sentry/react'
import { parse } from 'query-string'

import { IFeatures } from '../types/features.interface'
import { emit } from './event.manager'
import { detach } from './functions'
import { storageAvailable } from './misc'

export const LicenseProvider: FC<{
  children: (license: License, features: IFeatures, deactivated: boolean) => ReactNode
}> = ({ children }) => {
  const [features, setFeatures] = useState<IFeatures>({
    injectCss: false,
    whiteLabel: false,
    developmentMode: false
  })
  const [license, setLicense] = useState<License>()
  const [deactivated, setDeactivated] = useState<boolean>(false)

  useEffect(() => {
    const urlParams = parse(location.search)

    const storedLicenseData = storageAvailable('localStorage')
      ? localStorage.getItem('FF_LICENSE_DATA')
      : ''

    window.FF_FEATURES = {
      developmentMode: false
    }
    window.FF_API_URL = window.FF_LICENSE_DOMAIN
    window.FF_GRAPHQL_ENDPOINT = `${window.FF_LICENSE_DOMAIN}/graphql`
    window.FF_LICENSE_KEY = urlParams.key as string

    const getLicenseData = async () => {
      if (storedLicenseData) {
        return JSON.parse(storedLicenseData)
      }

      if (!window.FF_LICENSE_KEY) {
        return {
          deactivated: false,
          features,
          license: { key: undefined, error: 'No license key provided' }
        }
      }

      const path = `${window.FF_API_URL}/license-data?key=${window.FF_LICENSE_KEY}`
      const response = await fetch(path)
      if (!response.ok) {
        throw response
      }
      return response.json()
    }

    detach(async () => {
      try {
        const data = await getLicenseData()
        if (data.features) {
          setFeatures(data.features)
        }
        if (data.deactivated) {
          setDeactivated(data.deactivated)
        }
        setLicense(data.license ?? {})
      } catch (err) {
        emit('error:network', err.message)
        Sentry.captureException(err)
        setLicense({ key: undefined, error: 'Error contacting server.' })
      }
    })
  }, [])

  if (!license) {
    return <div>Loading...</div>
  } else {
    if (!license.key) {
      const message = 'Could not initialize importer due to invalid license key.'
      // tslint:disable-next-line:no-console
      console.log(message)
      Sentry.captureMessage(message, Sentry.Severity.Warning)
    } else {
      Sentry.setTag('license_key', license.key || 'no-license')
    }

    const tags: { [key: string]: string } = {}

    Object.keys(features).forEach((key) => {
      tags[`feat/${key}`.substr(0, 32)] = features[key as keyof IFeatures] ? 'true' : 'false'
    })

    Sentry.setTags(tags)

    window.FF_FEATURES = features
    window.FF_LICENSE_KEY = license.key
    return <div>{children(license, features, deactivated)}</div>
  }
}

export type License = { key?: string; error?: string }
