import { useEffect, useState } from 'react'

/**
 * Return a progress tracker that can be used to display or update a progress UI element.
 */
export function makeProgressTracker(): IProgressTracker {
  let $progress = 0
  let $total = 0
  let $completed = false
  let increment = (_progress: number, _complete: boolean) => {
    // do nothing
  }

  const init = (total: number) => {
    $progress = 0
    $total = total
    $completed = false
    increment(0, false)
  }
  const tick = () => {
    $progress += 1
    if ($progress >= $total) {
      complete()
    } else {
      increment(($progress / $total) * 100, false)
    }
  }
  const abort = () => {
    complete()
  }
  const complete = () => {
    if (!$completed) {
      $completed = true
      increment(100, $completed)
    }
  }

  const register = (watcher: (progress: number, complete: boolean) => void) => {
    increment = watcher
  }

  return {
    init,
    tick,
    abort,
    register,
    complete
  }
}

export function useProgress(tracker: IProgressTracker): number | null {
  const [$progress, setProgress] = useState<number | null>(null)
  useEffect(() => {
    tracker.register((progress, complete) => {
      if (complete) {
        setProgress(null)
      } else {
        setProgress(progress)
      }
    })
  }, [])
  return $progress
}

export interface IProgressTracker {
  init: (total: number) => void
  register: (watcher: (progress: number, complete: boolean) => void) => void
  tick: () => void
  abort: () => void
  complete: () => void
}
