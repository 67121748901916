import { gql } from 'apollo-boost'

export const PUBLIC_UPLOAD_DATA_SOURCES = gql`
  query PublicUploadDataSources($licenseKey: String!, $uploadId: UUID!) {
    publicUploadDataSources(licenseKey: $licenseKey, uploadId: $uploadId) {
      uploadId
      label
      index
      rowCount
      columnCount
      hasError
      errorMsg
      errorType
    }
  }
`
