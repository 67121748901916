import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'

import styled, { css } from 'styled-components'

const StyledButton = styled.button<IHTMLButtonProps>`
  button&.button.primary {
    ${({ theme }) => theme?.buttons?.primary && css(theme.buttons.primary)}
  }
  button&.button.secondary {
    ${({ theme }) => theme?.buttons?.secondary && css(theme.buttons.secondary)}
  }
  button&.button.success {
    ${({ theme }) => theme?.buttons?.success && css(theme.buttons.success)}
  }
  button&.button.invert {
    ${({ theme }) => theme?.buttons?.tertiary && css(theme.buttons.tertiary)}
  }

  button&.button.upload-button {
    ${({ theme }) => theme?.buttons?.dropzoneUpload && css(theme.buttons.dropzoneUpload)}
  }
  button&.button.header-match-yes {
    ${({ theme }) => theme?.buttons?.headerMatchYes && css(theme.buttons.headerMatchYes)}
  }
  button&.button.header-match-no {
    ${({ theme }) => theme?.buttons?.headerMatchNo && css(theme.buttons.headerMatchNo)}
  }
  button&.button.column-match-confirm {
    ${({ theme }) => theme?.buttons?.columnMatchConfirm && css(theme.buttons.columnMatchConfirm)}
  }
  button&.button.column-match-confirm-with-dupes {
    ${({ theme }) =>
      theme?.buttons?.columnMatchConfirmWithDupes && css(theme.buttons.columnMatchConfirmWithDupes)}
  }
  button&.button.column-match-ignore {
    ${({ theme }) => theme?.buttons?.columnMatchIgnore && css(theme.buttons.columnMatchIgnore)}
  }
  button&.button.column-match-include {
    svg {
      width: 0.8rem !important;
      margin-right: 5px;
    }

    ${({ theme }) => theme?.buttons?.columnMatchInclude && css(theme.buttons.columnMatchInclude)}
  }
  button&.button.column-match-include-dropdown,
  button&.button.column-match-include-option-dropdown {
    font-size: 12px;
    height: initial !important;
    width: 100%;
    pointer-events: auto;

    svg {
      width: 0.8rem !important;
      margin-right: 5px;
    }
  }
  button&.button.column-match-include-dropdown {
    ${({ theme }) =>
      theme?.buttons?.columnMatchIncludeDropdown && css(theme.buttons.columnMatchIncludeDropdown)}
  }
  button&.button.column-match-include-option-dropdown {
    ${({ theme }) =>
      theme?.buttons?.columnMatchIncludeOptionDropdown &&
      css(theme.buttons.columnMatchIncludeOptionDropdown)}
  }
  button&.button.column-match-edit {
    ${({ theme }) => theme?.buttons?.columnMatchEdit && css(theme.buttons.columnMatchEdit)}
  }
  button&.button.dialog-confirm-yes {
    ${({ theme }) => theme?.buttons?.dialogConfirmYes && css(theme.buttons.dialogConfirmYes)}
  }
  button&.button.dialog-confirm-no {
    ${({ theme }) => theme?.buttons?.dialogConfirmNo && css(theme.buttons.dialogConfirmNo)}
  }
  button&.button.dialog-final-yes {
    ${({ theme }) => theme?.buttons?.dialogFinalYes && css(theme.buttons.dialogFinalYes)}
  }
  button&.button.dialog-final-no {
    ${({ theme }) => theme?.buttons?.dialogFinalNo && css(theme.buttons.dialogFinalNo)}
  }
  button&.button.final-success {
    ${({ theme }) => theme?.buttons?.dialogFinalSuccess && css(theme.buttons.dialogFinalSuccess)}
  }
  button&.button.final-error {
    ${({ theme }) => theme?.buttons?.dialogFinalError && css(theme.buttons.dialogFinalError)}
  }
  button&.button.datasource-cancel {
    ${({ theme }) => theme?.buttons?.dataSourceCancel && css(theme.buttons.dataSourceCancel)}
  }
  button&.button.datasource-continue {
    ${({ theme }) => theme?.buttons?.dataSourceContinue && css(theme.buttons.dataSourceContinue)}
  }
`

export const Button: React.FC<
  IHTMLButtonProps & {
    classes?: string[]
  }
> = ({ classes, title, ref, ...props }) => (
  <StyledButton className={'button ' + (classes || ['primary']).join(' ')} {...props}>
    {props.children || title}
  </StyledButton>
)
Button.displayName = 'Button'

export const SecondaryButton: React.FC<IHTMLButtonProps> = (props) => {
  return <Button {...props} classes={['secondary']} />
}

type IHTMLButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>
export const CloseButton: React.FC<IHTMLButtonProps> = (props: IHTMLButtonProps) => (
  <button className='flatfile-close-button' {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <path
        fill='currentColor'
        fillRule='nonzero'
        d='M94.3 0c-1.5 0-3 .6-4 1.7L50 42 9.8 1.6c-2.3-2.3-6-2.3-8 0-2.4 2.2-2.4 5.8 0 8l40 40.3L2 90.2c-2.4 2.3-2.4 6 0 8 1 1.2 2.5 1.8 4 1.8 1.4 0 3-.6 4-1.7L50 58l40.2 40.3c1 1 2.6 1.7 4 1.7 1.5 0 3-.6 4-1.7 2.4-2.2 2.4-5.8 0-8L58.3 50 98 9.8c2.4-2.3 2.4-6 0-8-1-1.2-2.5-1.8-4-1.8z'
      />
    </svg>
  </button>
)
CloseButton.displayName = 'CloseButton'
