import { gql } from 'apollo-boost'

export const PUBLIC_CREATE_BATCH = gql`
  mutation PublicCreateBatch(
    $endUser: EndUserDto
    $fileName: String
    $importedFromUrl: String!
    $legacySettingsId: UUID
    $legacyWebhookUrl: String
    $legacyHasFieldHooks: Boolean
    $legacyHasRecordHooks: Boolean
    $licenseKey: String!
    $managed: Boolean!
    $manual: Boolean!
    $devMode: Boolean!
  ) {
    publicCreateBatch(
      endUser: $endUser
      fileName: $fileName
      importedFromUrl: $importedFromUrl
      legacySettingsId: $legacySettingsId
      legacyWebhookUrl: $legacyWebhookUrl
      legacyHasFieldHooks: $legacyHasFieldHooks
      legacyHasRecordHooks: $legacyHasRecordHooks
      licenseKey: $licenseKey
      managed: $managed
      manual: $manual
      devMode: $devMode
    ) {
      id
      writeAccessKey
    }
  }
`
