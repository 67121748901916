import { getAsyncGraphClient } from '../lib/graph.client'
import { PUBLIC_CREATE_BATCH } from '../queries/PUBLIC_CREATE_BATCH'
import { PublicCreateBatch, PublicCreateBatchVariables } from '../queries/types/PublicCreateBatch'

export const publicCreateBatch = async (
  variables: PublicCreateBatchVariables
): Promise<{ id?: string; writeAccessKey?: string; error?: string | Error }> => {
  const graphClient = await getAsyncGraphClient({
    operation: 'PUBLIC_CREATE_BATCH',
    variables
  })

  try {
    const mutationResponse = await graphClient.mutate<
      PublicCreateBatch,
      PublicCreateBatchVariables
    >({
      mutation: PUBLIC_CREATE_BATCH,
      variables
    })
    if (mutationResponse && mutationResponse.data) {
      return mutationResponse.data.publicCreateBatch
    }
    /* istanbul ignore next */
    return { error: JSON.stringify(mutationResponse.errors) }
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error)
    return { error }
  }
}
