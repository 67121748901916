import React, { useContext } from 'react'

import { EnvironmentContext } from '../context/environment.context'
import { isRawHTML, sanitizeRawHtml } from '../utils/misc'

export function RawHTML({ text, allowedTags }: { text: string; allowedTags?: string[] }) {
  const { features } = useContext(EnvironmentContext)

  return (
    <>
      {features.HTMLOverride && isRawHTML(text) ? (
        <div
          className='raw-html'
          dangerouslySetInnerHTML={{ __html: sanitizeRawHtml(text, allowedTags, true) }}
        />
      ) : (
        text
      )}
    </>
  )
}
