import React, { FC, ReactElement } from 'react'

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { STAGE } from '../controller'
import { defaultTheme } from '../styles'

const ProgressList = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 1;
  margin: 0;
  padding: 0;

  ${({ theme }) => theme?.progressBar?.root && css(theme.progressBar.root)}
`

const ListItem = styled.li`
  position: relative;
  margin: 0 0 0 48px;
  font-size: 16px;
  font-weight: 500;

  &.progress-done .fa {
    padding-right: 10px;
  }
  &.progress-incomplete {
    color: ${defaultTheme.secondaryTextColor};
    font-weight: 400;
  }
  &:not(:first-of-type):before {
    content: '';
    position: absolute;
    left: -38px;
    top: 45%;
    transform: rotate(45deg) translateY(-50%);
    display: inline-block;
    height: 12px;
    width: 12px;
    border: 1px solid ${defaultTheme.cloudyBlue};
    border-left-width: 0;
    border-bottom-width: 0;

    ${({ theme }) =>
      theme?.progressBar?.arrow?.fill &&
      css`
        border-color: ${theme.progressBar.arrow.fill};
      `}
  }

  li&.progress-current {
    ${({ theme }) => theme?.progressBar?.current && css(theme.progressBar.current)}
  }
  li&.progress-done {
    ${({ theme }) => theme?.progressBar?.complete && css(theme.progressBar.complete)}
  }
  li&.progress-incomplete {
    ${({ theme }) => theme?.progressBar?.incomplete && css(theme.progressBar.incomplete)}
  }
`
const Item: FC<{ status?: 'done' | 'current' }> = ({ status, children }) => (
  <ListItem
    className={`progress-${status || 'incomplete'} ${status === 'done' ? 'successColor' : ''}`}
  >
    {status === 'done' && <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: '6px' }} />}
    {children}
  </ListItem>
)

export const ProgressHeader: React.FC<{
  stage: STAGE
}> = ({ stage }): ReactElement | null => {
  const { t } = useTranslation()
  if (![STAGE.MATCH, STAGE.AUDIT].includes(stage)) {
    return null
  }
  const matchStatus = stage === STAGE.MATCH ? 'current' : 'done'
  const auditStatus = stage === STAGE.AUDIT ? 'current' : undefined
  return (
    <ProgressList>
      <Item status='done'>{t('breadcrumbs.upload')}</Item>
      <Item status={matchStatus}>{t('breadcrumbs.match')}</Item>
      <Item status={auditStatus}>{t('breadcrumbs.repair')}</Item>
      <Item>{t('breadcrumbs.complete')}</Item>
    </ProgressList>
  )
}
ProgressHeader.displayName = 'ProgressHeader'
