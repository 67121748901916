import React from 'react'

import { useTranslation } from 'react-i18next'
import styled, { css, keyframes } from 'styled-components'

import { defaultTheme } from '../styles'
import { WordMark } from './word.mark'

const learnMore = keyframes`
  from {
    transform: translateY(5px);
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
`

const flatfileText = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const wholelogo = keyframes`
  0% {
    transform: rotate(-45deg) scale(0);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
`

const logo = keyframes`
  100% {
    transform: translate(-123px, -20px) rotate(0deg) scale(1);
  }
`

const BrandLink = styled.a<{ isAnimated?: boolean; isProminentWhiteLabel?: boolean }>`
  display: block;
  position: absolute;
  left: 50%;
  bottom: 50%;
  margin-bottom: -7px;
  margin-left: -200px;
  width: 400px;
  text-align: center;

  div,
  svg {
    display: inline-block;
    overflow: visible;
  }

  .flatfile-icon-svg {
      opacity: 1;

      ${({ isAnimated }) =>
        isAnimated &&
        css`
          opacity: 0;
          animation: ${wholelogo} linear forwards;
          animation-duration: 1s;
        `}

  }

  .flatfile-icon {
    fill: ${defaultTheme.primaryButtonColor};
    ${({ isProminentWhiteLabel }) =>
      isProminentWhiteLabel &&
      css`
        fill: #cad0dc;
      `}
    transform: translate(-123px, -20px) rotate(0deg) scale(1);

    ${({ isAnimated }) =>
      isAnimated &&
      css`
      animation: ${logo} ease-out forwards;
      animation-duration 0.75s;

      transform: translate(-500px, -500px) rotate(-180deg) scale(0) skew(20deg, 20deg);
      transform-origin: center;
      animation-delay: 2s;

      &:nth-of-type(2) {
        animation-delay: 2.1s;
        transform: translate(500px, -500px) rotate(-180deg) scale(0);
      }
      &:nth-of-type(3) {
        transform: translate(500px, 500px) rotate(-180deg) scale(0);
        animation-delay: 2.4s;
      }
      &:nth-of-type(4) {
        transform: translate(-500px, 500px) rotate(-180deg) scale(0);
        animation-delay: 2.8s;
      }

    `}

  }


    .flatfile-text, .powered-text {
      opacity: 1;
      font-weight: 400;
      color: #090b2b;
      font-size: 12px;
      ${({ isProminentWhiteLabel }) =>
        isProminentWhiteLabel &&
        css`
          color: #cad0dc;
        `}
      margin: 0 1px;

      ${({ isAnimated }) =>
        isAnimated &&
        css`
          opacity: 0;
          animation: ${flatfileText} 1s;
          animation-delay: 3s;
          animation-fill-mode: forwards;
        `}

      }
    }
    .flatfile-text {
      font-weight: 600;
      letter-spacing: 0.05px;
    }


`

const ProminentBrandLink = styled(BrandLink)<{
  isAnimated?: boolean
  isProminentWhiteLabel?: boolean
}>`
  font-family: Gilroy, sans-serif;

  &:hover,
  &:focus,
  &:active {
    .learnMore {
      background: #a1a8ff;
    }
  }
  .learnMore {
    color: ${defaultTheme.primaryButtonColor};
    background: #c4c9ff;
    display: inline-block;

    font-family: proxima-nova, sans-serif;
    font-size: 8px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.6px;

    border-radius: 2px;
    padding: 2px 5px 1px 5px;
    margin: 0 0 0px 5px;

    transition: all 0.2s ease-in;

    ${({ isAnimated }) =>
      isAnimated &&
      css`
        opacity: 0;
        animation: ${learnMore} ease-out;
        animation-duration: 0.35s;
        animation-delay: 3.45s;
        animation-fill-mode: forwards;
      `}
  }
`

export const FooterBrand: React.FC = () => {
  const { t } = useTranslation()

  if (window.FF_FEATURES.whiteLabel) {
    return null
  }

  const href = `https://flatfile.io/?utm_source=user_app&utm_medium=footer&utm_content=${window.FF_LICENSE_KEY}&utm_campaign=q4-2021-portal-v2-redesign`

  if (window.FF_FEATURES.prominentWhiteLabel) {
    return (
      <BrandLink
        isAnimated={true}
        isProminentWhiteLabel={true}
        href={href}
        target='_blank'
        title={t('poweredTitle')}
      >
        <WordMark />
        <span className='powered-text'>{t('flatfile')}.</span>
      </BrandLink>
    )
  }

  return (
    <ProminentBrandLink isAnimated={true} href={href} target='_blank' title={t('poweredTitle')}>
      <WordMark />
      <span className='powered-text'>{t('flatfile')}.</span>
      <span className='learnMore'>{t('poweredByLearnMore')}</span>
    </ProminentBrandLink>
  )
}
FooterBrand.displayName = 'FooterBrand'
