// Encodings supported by jschardet

export const encodings = [
  // Big5, GB2312/GB18030, EUC-TW, HZ-GB-2312, and ISO-2022-CN (Traditional and Simplified Chinese)
  { value: 'big5', label: 'Big5' },
  { value: 'gb2312', label: 'GB2312' },
  { value: 'gb18030', label: 'GB18030' },
  { value: 'euc-tw', label: 'EUC-TW' },
  { value: 'hz-gb-2312', label: 'HZ-GB-2312' },
  { value: 'iso-2022-cn', label: 'ISO-2022-CN' },

  // EUC-JP, SHIFT_JIS, and ISO-2022-JP (Japanese)
  { value: 'euc-jp', label: 'EUC-JP' },
  { value: 'shift_jis', label: 'SHIFT_JIS' },
  { value: 'iso-2022-JP', label: 'ISO-2022-JP' },

  // EUC-KR and ISO-2022-KR (Korean)
  { value: 'euc-kr', label: 'EUC-KR' },
  { value: 'iso-2022-KR', label: 'ISO-2022-KR' },

  // KOI8-R, MacCyrillic, IBM855, IBM866, ISO-8859-5, and windows-1251 (Russian)
  { value: 'koi8-r', label: 'KOI8-R' },
  { value: 'maccyrillic', label: 'MacCyrillic' },
  { value: 'ibm855', label: 'IBM855' },
  { value: 'ibm856', label: 'IBM866' },
  { value: 'iso-8859-5', label: 'ISO-8859-5' },
  { value: 'windows-1251', label: 'windows-1251' },

  // ISO-8859-2 and windows-1250 (Hungarian)
  { value: 'iso-8859-2', label: 'ISO-8859-2' },
  { value: 'windows-1250', label: 'windows-1250' },

  // ISO-8859-5 and windows-1251 (Bulgarian)
  { value: 'iso-8859-5', label: 'ISO-8859-5' },
  { value: 'windows-1251', label: 'windows-1251' },

  //  windows-1252)
  { value: 'windows-1252', label: 'windows-1252' },

  //  ISO-8859-7 and windows-1253 (Greek)
  { value: 'iso-8859-7', label: 'ISO-8859-7' },
  { value: 'windows-1253', label: 'windows-1253' },

  // ISO-8859-8 and windows-1255 (Visual and Logical Hebrew)
  { value: 'iso-8859-8', label: 'ISO-8859-8' },
  { value: 'windows-1255', label: 'windows-1255' },

  // TIS-620 (Thai)
  { value: 'tis-620', label: 'TIS-620' },

  // UTF-32 BE, LE, 3412-ordered, or 2143-ordered (with a BOM)
  { value: 'utf-32le', label: 'UTF-32LE' },
  { value: 'utf-32be', label: 'UTF-32BE' },
  { value: '2143-ordered', label: '2143-ordered' },
  { value: '3412-ordered', label: '3412-ordered' },

  // UTF-16 BE or LE (with a BOM)
  { value: 'utf-16le', label: 'UTF-16LE' },
  { value: 'utf-16be', label: 'UTF-16BE' },

  // UTF-8 (with or without a BOM)
  { value: 'utf-8', label: 'UTF-8' },

  // UTF-16
  { value: 'utf-16', label: 'UTF-16' },

  // ASCII
  { value: 'ascii', label: 'ASCII' }
]

export const sortedEncodings = encodings.sort((a, b) => {
  return a.value.localeCompare(b.value)
})

export const mostCommon = [
  { value: 'ascii', label: 'ASCII' },
  { value: 'utf-8', label: 'UTF-8' },
  { value: 'utf-16', label: 'UTF-16' },
  { value: 'windows-1252', label: 'windows-1252' }
]
