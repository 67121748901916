import { INTERACTION_EVENT_THROTTLE_TIME } from '../config'
import { IInteractionEvent, INTERACTION_EVENT } from '../types/general.interface'
import { listen } from './event.manager'

const EVENTS: INTERACTION_EVENT[] = ['mousemove', 'mousedown', 'keydown']

const listeners: { [P in INTERACTION_EVENT]?: () => void } = {}
const counter: IInteractionEvent = {}
let interval: NodeJS.Timeout

const resetCounter = () => {
  EVENTS.forEach((key) => {
    counter[key] = 0
  })
}

export const registerInteractionEventListeners = (cb: (data: IInteractionEvent) => void) => {
  resetCounter()

  EVENTS.forEach((key) => {
    const listener = () => {
      counter[key]! += 1
    }
    listeners[key] = listener
    document.body.addEventListener(key, listener)
  })

  Object.entries(listeners).forEach(([key, listener]) => {
    document.body.addEventListener(key as INTERACTION_EVENT, listener!)
  })

  interval = setInterval(() => {
    cb(Object.assign({}, counter))
    resetCounter()
  }, INTERACTION_EVENT_THROTTLE_TIME)

  listen('do/close', () => {
    Object.entries(listeners).forEach(([key, listener]) => {
      document.body.removeEventListener(key as INTERACTION_EVENT, listener!)
      delete listeners[key as INTERACTION_EVENT]
    })
    clearInterval(interval)
  })
}
