import React from 'react'

import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { defaultTheme } from '../styles'
import { LoadingIcon } from './step'

const StyledBackdrop = styled.div`
  background: rgba(255, 255, 255, 0.5);
  bottom: 0;
  left: 0;
  padding: 40px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 999;

  ${({ theme }) =>
    theme?.loader?.overlayColor &&
    css`
      background-color: ${theme.loader.overlayColor};
    `}
`

const StyledPopup = styled.div`
  position: fixed;
  transform: translateX(-50%);
  background: ${defaultTheme.backgroundColor};
  border-radius: ${defaultTheme.borderRadius};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  color: ${defaultTheme.primaryTextColor};
  display: inline-block;
  padding: 16px;

  ${({ theme }) => theme?.loader?.root && css(theme.loader.root)}
`

export const SecondaryLoader = () => {
  const { t } = useTranslation()

  return (
    <StyledBackdrop data-testid='secondary-loader' className='secondary-loader'>
      <StyledPopup>
        <LoadingIcon /> {t('processingPleaseWait')}
      </StyledPopup>
    </StyledBackdrop>
  )
}
