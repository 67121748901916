import React, { Dispatch, SetStateAction } from 'react'

import uniqBy from 'lodash/uniqBy'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'
import styled from 'styled-components'

import { useSelectStyles } from '../hooks/useSelectStyles'
import { Batch } from '../lib/batch'
import { defaultTheme } from '../styles'
import { encodings, mostCommon, sortedEncodings } from '../utils/encodings'

const LabelPill = styled.div<{ isSelected?: boolean }>`
  position: absolute;
  right: 10px;
  background: #eaeff5;
  font-size: 10px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  padding: 2px 6px;

  ${(props) => props.isSelected && `color: ${defaultTheme.primaryTextColor};`}
`

const StyledOption = styled.div`
  position: relative;
`

const DropdownWrapper = styled.div`
  margin-left: 16px;
  min-width: 240px;
`

export const EncodingDropdown: React.FC<{
  batch: Batch
  encodingDetected: string
  encodingSetting?: string
  numPreviewRows: number
  setEncoding: Dispatch<string>
  setPreviewData: Dispatch<SetStateAction<undefined | string[][]>>
}> = ({
  batch,
  encodingDetected,
  encodingSetting,
  numPreviewRows,
  setEncoding,
  setPreviewData
}) => {
  const { t } = useTranslation()
  const { styles } = useSelectStyles({
    themePath: 'headerMatch.select', // path to styles
    maxMenuHeight: 164
  })
  const encodingSettingOption = encodings.filter(
    (item) => item.value.toUpperCase() === encodingSetting?.toUpperCase()
  )
  const encodingDetectedOption = encodings.filter(
    (item) => item.value.toUpperCase() === encodingDetected?.toUpperCase()
  )

  const options = uniqBy(
    [...encodingDetectedOption, ...mostCommon, ...sortedEncodings],
    (e) => e.value
  )

  const defaultValue = encodingSettingOption[0] ?? encodingDetectedOption[0]

  const Option: typeof components.Option = (props) => {
    const { children, getStyles, innerRef, innerProps, isSelected } = props

    return (
      <StyledOption ref={innerRef} style={getStyles('option', props)} {...innerProps}>
        {children}
        {encodingDetectedOption &&
        encodingDetectedOption[0] &&
        encodingDetectedOption[0].label &&
        children === encodingDetectedOption[0].label ? (
          <LabelPill isSelected={isSelected}>{t('encodingDetected')}</LabelPill>
        ) : null}
      </StyledOption>
    )
  }

  if (!defaultValue) {
    return null
  }

  return (
    <DropdownWrapper className='primaryTextColor'>
      <Select
        options={options}
        components={{ Option }}
        defaultValue={defaultValue}
        styles={styles}
        placeholder='Choose a different encoding:'
        onChange={async (selectValue) => {
          if (selectValue && 'value' in selectValue) {
            setEncoding(selectValue.value)
            batch.getParser().setEncoding(selectValue.value)
            await batch.getParser().loadSampleData()
            const newPreviewData = await batch
              .getParser()
              .getPreviewData(numPreviewRows)
              .map(([r]) => r)
            setPreviewData(newPreviewData)
          }
        }}
      />
    </DropdownWrapper>
  )
}
